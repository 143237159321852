function checkMinhaVez(tce, user) {
  // retorna -1 se já assinei
  // retorna 0 se é minha vez de assinar
  // retorna 1 se ainda não é minha vez de assinar

  const { tipo, empresa } = user;
  const { status, contrato } = tce;

  if (status.tipo !== "aguardando") return -1;
  // teste para o administrador
  if (tipo === "administrador") {
    // o Integrador sempre vem na frente, logo nunca retorna 1
    return status.descricao === "Aguardando aceite do Integrador.App" ? 0 : -1;
  }

  // teste para funcionario
  if (tipo === "funcionario") {
    // sou o contratante e a escola do contratado
    if (
      empresa.id === contrato.contratado_escola.id &&
      empresa.id === contrato.contratante.id
    ) {
      if (
        [
          "Ativo (Aguardando aceite da escola)",
          "Ativo (Aguardando aceite da empresa concedente)"
        ].includes(status.descricao)
      ) {
        return 0;
      }
    }

    // sou o contratante (concedente)
    if (empresa.id === contrato.contratante.id) {
      if (status.descricao === "Aguardando aceite do Integrador.App") return 1;
      if (
        status.descricao === "Ativo (Aguardando aceite da empresa concedente)"
      )
        return 0;
      return -1;
    }

    // sou o escola do contratado
    // A escola do contratado é sempre a última, logo não retorna -1
    if (empresa.id === contrato.contratado_escola.id) {
      if (status.descricao === "Ativo (Aguardando aceite da escola)") return 0;
      return 1;
    }
  }

  if (tipo === "aluno") {
    // sou o aluno (contratado)
    if (empresa.id === contrato.contratado_escola.id) {
      if (status.descricao === "Ativo (Aguardando aceite do estudante)")
        return 0;
      if (
        [
          "Ativo (Aguardando aceite do Integrador.App)",
          "Ativo (Aguardando aceite da empresa concedente)"
        ].includes(status.descricao)
      )
        return 1;
      return -1;
    }
  }
}

export { checkMinhaVez };
