import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lastDayOfMonth, format } from "date-fns";

import TotaisPorPeriodoActions from "@/store/ducks/indicadores/totais-por-periodo";
import { Input } from "@/components/Form";

import * as S from "./styles";

export default function TotaisPorPeriodo({}) {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(
    state => state.indicadoresTotaisPorPeriodo
  );

  const [params, setParams] = useState({
    inicio: `${format(new Date(), "yyyy-MM")}-01`,
    fim: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
  });

  function handleChange(event) {
    const { name, value } = event.target;
    if (params[name] !== value) {
      setParams(state => ({ ...state, [name]: value }));
    }
  }

  function renderFilters() {
    return (
      <S.Filters>
        <Input
          type="date"
          name="inicio"
          label="Data de início"
          value={params.inicio}
          onChange={handleChange}
        />
        <Input
          type="date"
          name="fim"
          label="Data de fim"
          value={params.fim}
          onChange={handleChange}
        />
      </S.Filters>
    );
  }

  function renderBoxes() {
    if (!data) return <></>;

    const { contratosEfetivados, estudantes, escolas, empresas } = data;

    const boxes = [
      {
        title: "Contratos efetivados",
        value: contratosEfetivados,
        valueSmall: contratosEfetivados > 1 ? "contratos" : "contrato",
      },
      {
        title: "Alunos cadastrados",
        value: estudantes,
        valueSmall: estudantes > 1 ? "alunos" : "aluno",
      },
      {
        title: "Escolas cadastradas",
        value: escolas,
        valueSmall: escolas > 1 ? "escolas" : "escola",
      },
      {
        title: "Empresas cadastradas",
        value: empresas,
        valueSmall: empresas > 1 ? "empresas" : "empresa",
      },
    ];

    return (
      <S.Container>
        {boxes.map(box => {
          return (
            <S.Box key={box.title}>
              <S.BoxBody>
                <S.Value>{box.value}</S.Value>
                <S.ValueSmall>{box.valueSmall}</S.ValueSmall>
              </S.BoxBody>
              <S.BoxFooter>{box.title}</S.BoxFooter>
            </S.Box>
          );
        })}
      </S.Container>
    );
  }

  const fetchDataCB = useCallback(() => {
    dispatch(TotaisPorPeriodoActions.request(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchDataCB();
  }, [fetchDataCB]);

  useEffect(() => {
    return () => {
      dispatch(TotaisPorPeriodoActions.reset());
    };
  }, [dispatch]);

  return (
    <>
      {renderFilters()}
      {renderBoxes()}
    </>
  );
}
