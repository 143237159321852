import React, { useState } from "react";

const AlunoContext = React.createContext([{}, () => {}]);

const AlunoProvider = props => {
  const [state, setState] = useState({
    data: null,
    loading: false
  });
  return (
    <AlunoContext.Provider value={[state, setState]}>
      {props.children}
    </AlunoContext.Provider>
  )
}

export { AlunoContext, AlunoProvider };
