import { useContext } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { AvaliacaoContext } from "@/context/avaliacoes/avaliacao";
import api, { makeOptions } from "@/services/api";

const useAvaliacao = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(AvaliacaoContext);

  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function fetchAvaliacao(avaliacaoId) {
    _setLoading(true);
    const endpoint = `avaliacoes/${avaliacaoId}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  async function updateAvaliacao(avaliacaoId, data, onSuccess = false, onError =  false) {
    _setLoading(true);
    const endpoint = `avaliacoes/${avaliacaoId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
      if (onSuccess) onSuccess();
    } else {
      if (onError) onError();
    }
  }

  return {
    fetchAvaliacao,
    updateAvaliacao,
    avaliacao: state.data,
    loading: state.loading,
  };
};

export { useAvaliacao };
