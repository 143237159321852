import { useContext } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";

import { EntrevistaContext } from "@/context/entrevistas/entrevista";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import EventEmitter from "@/event-emitter";

const useEntrevista = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(EntrevistaContext);

  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function createEntrevista(data) {
    _setLoading(true);
    const endpoint = `entrevistas`;
    const { data: response } = await api.post(
      endpoint,
      data,
      makeOptions(user)
    );
    _setLoading(false);
    if (response.success) {
      toastify("Entrevista marcada com sucesso");
      EventEmitter.dispatch("entrevista-added");
      _setData(response.data);
    }
  }

  async function updateEntrevista(entrevistaId, data) {
    _setLoading(true);
    const endpoint = `entrevistas/${entrevistaId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      toastify("Entrevista alterada com sucesso");
      EventEmitter.dispatch("entrevista-updated", {
        id: entrevistaId,
        data: data.data,
        hora: data.hora
      });
      // _setData(response.data);
    }
  }

  return {
    createEntrevista,
    updateEntrevista,
    entrevista: state.data,
    loading: state.loading,
  };
};

export { useEntrevista };
