import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  consultaCnpjRequest: ["cnpj"],
  consultaCnpjSuccess: ["data"],
  consultaCnpjFailure: ["error"],
  consultaCnpjReset: [],
});

export const consultaCnpjTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

// Reducers
export const consultaCnpjRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const consultaCnpjSuccess = (state, action) => {
  const { data } = action;
  return update(state, {
    data: { $set: data },
    loading: { $set: false },
    error: { $set: null },
  });
};

export const consultaCnpjFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const consultaCnpjReset = state => {
  return INITIAL_STATE;
}

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CONSULTA_CNPJ_REQUEST]: consultaCnpjRequest,
  [Types.CONSULTA_CNPJ_SUCCESS]: consultaCnpjSuccess,
  [Types.CONSULTA_CNPJ_FAILURE]: consultaCnpjFailure,
  [Types.CONSULTA_CNPJ_RESET]: consultaCnpjReset,
});
