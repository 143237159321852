import { call, put, select, all } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import UpdateEmpresaActions from "@/store/ducks/empresas/update";
import ViewEmpresaActions from "@/store/ducks/empresas/view";
import AuthActions from "@/store/ducks/auth";

const getAuthData = state => state.auth.data;

export function* update(action) {
  try {
    const { id, data } = action;
    const authData = yield select(getAuthData);
    const { data: retorno } = yield call(api.put,
      `empresas/${id}`,
      data,
      makeOptions(authData)
    );

    if (retorno.success) {
      yield all([
        put(UpdateEmpresaActions.updateEmpresaSuccess(retorno.data)),
        put(ViewEmpresaActions.viewEmpresaSuccess(retorno.data)),
        put(AuthActions.authFetchMe()),
      ]);
      toastify("Empresa atualizada com sucesso");
    } else {
      yield put(UpdateEmpresaActions.updateEmpresaFailure(retorno.errors));
    }
  } catch (e) {
    yield put(UpdateEmpresaActions.updateEmpresaFailure({
      api_failure: "Não foi possível se comunicar com a API"
    }));
  }
}
