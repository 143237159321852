const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // para não ter problemas com cross-origin no CodeSandbox
    image.src = url;
  });

/**
 *
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object do react-easy-crop
 */
export default async function getCroppedImg(imageSrc, pixelCrop, width = 200, height = 200, mode = "base64", ) {

  // Gerando imagem de corte original
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // Reduzindo a imagem para o tamanho desejado
  const smallImage = await createImage(canvas.toDataURL());
  const smallCanvas = document.createElement('canvas');
  smallCanvas.width = width;
  smallCanvas.height = height;
  const smallCtx = smallCanvas.getContext('2d');
  smallCtx.drawImage(
    smallImage,
    0,
    0,
    width,
    height
  );

  if (mode==="base64") {
    return smallCanvas.toDataURL("image/jpeg");
  }

  if (mode==="blob") {
    return new Promise((resolve, reject) => {
      smallCanvas.toBlob(file => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  }
}
