import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import AuthActions from "@/store/ducks/auth";

import { Navbar, Footer } from "@/components/Blocks";
import { Card, Button } from "@/components/Common";
import { Form, Input } from "@/components/Form";
import { Page, Row, Col } from "@/components/Grid";
import { EntrarContainer, CardWrapper } from "./styles";

import Toastify from "@/services/toastify";

export default function RecuperarSenha({ history }) {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("Obrigatório"),
  });

  function handleSubmit(data) {
    dispatch(AuthActions.authGerarRecoverRequest(data, function() {
      history.push("/");
    }));
  }

  return (
    <Page>
      <Navbar type="login" />
      <EntrarContainer>
        <CardWrapper>
          <h1>Esqueci minha senha</h1>
          <p>Informe seu e-mail de usuário</p>
          <Card shadow>
            <Form schema={schema} onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Input type="mail" label="E-mail:" name="email" />
                </Col>
              </Row>

              <Row hascols gaps="16">
                <Col>
                  <Button
                    type="submit"
                    variant="magenta"
                    loading={auth.loading}
                    block
                  >
                    Enviar
                  </Button>
                </Col>
                <Col>
                  <Button to="/login" block>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </CardWrapper>
      </EntrarContainer>
      <Footer />
    </Page>
  );
}
