import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  createFranqueadoRequest: ["data", "history", "pathname"],
  createFranqueadoSuccess: ["data"],
  createFranqueadoFailure: ["error"],
  createFranqueadoReset: [],
});

export const createFranqueadoTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  loading: false,
  error: null,
};

// Reducers
export const createFranqueadoRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const createFranqueadoSuccess = state => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const createFranqueadoFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const createFranqueadoReset = state => {
  return INITIAL_STATE;
}

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_FRANQUEADO_REQUEST]: createFranqueadoRequest,
  [Types.CREATE_FRANQUEADO_SUCCESS]: createFranqueadoSuccess,
  [Types.CREATE_FRANQUEADO_FAILURE]: createFranqueadoFailure,
  [Types.CREATE_FRANQUEADO_RESET]: createFranqueadoReset,
});
