import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  background-color: #FFFFFF;
  margin: 0 auto;
  border-radius: 4px;
  /* o form interno me bugou legal
    setando uma altura máxima pra ele
   */
  form {
    max-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
  }
`;

export const ModalBody = styled.div`
  overflow-y: scroll;
  width: 100%;
  padding: 40px;
`;

export const ModalFooter = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #B9B7CC;
  > * {
    margin-left: 20px;
  }
`;

export const AvisoMensagem = styled.div`
  margin-bottom: 2rem;
`;
