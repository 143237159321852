import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { FiTrash2 } from "react-icons/fi";
import { Input, InputMoney } from "@/components/Form";
import { Row, Col } from "@/components/Grid";
import {
  ListContainer,
  ListItemContainer,
  ButtonDelete,
  ButtonAdd,
} from "./styles";

export default function Beneficios({ value, onChange }) {
  const [beneficios, setBeneficios] = useState(value || []);

  useEffect(() => {
    if (onChange) {
      onChange(beneficios);
    }
  }, [beneficios]);

  function handleChangeBeneficio(event, index) {
    const { value, name: _name } = event.target;
    const name = _name.replace(/[0-9]/, "").replace(/[^A-Za-z]/ig, "");

    const beneficio = beneficios[index];
    const newBeneficio = {
      ...beneficio,
      ...{[name]: value}
    }

    setBeneficios(state => {
      return update(state, {
        [index]: { $set: newBeneficio },
      });
    });
  }

  function handleAddBeneficio() {
    const beneficiosIncompletos = beneficios.filter(b => b.descricao === "");
    if (beneficiosIncompletos.length === 0) {
      setBeneficios(state => {
        return [...state, ...[{descricao: "", valor: 0}]];
      });
    }
  }

  function handleDeleteBeneficio(index) {
    setBeneficios(state => {
      return update(state, {
        $splice: [[index, 1]]
      });
    });
  }

  function renderList() {
    return beneficios.map((beneficio, index) => {
      return (
        <ListItemContainer key={index}>
          <Row hascols>
            <Col>
              <Input
                name="_descricao"
                placeholder="Nome do benefício"
                value={beneficio.descricao}
                onChange={e => handleChangeBeneficio(e, index)}
              />
            </Col>
            <Col xl="1/4">
              <InputMoney
                name="_valor"
                placeholder="Valor (opcional)"
                value={beneficio.valor}
                onChange={e => handleChangeBeneficio(e, index)}
              />
            </Col>
          </Row>
          <ButtonDelete onClick={(e) => handleDeleteBeneficio(index)}>
            <FiTrash2 />
          </ButtonDelete>
        </ListItemContainer>
      );
    });
  }

  return (
    <ListContainer>
      <h3>Benefícios da vaga:</h3>
      {renderList()}
      <ButtonAdd onClick={handleAddBeneficio}>+ Adicionar benefício</ButtonAdd>
    </ListContainer>
  );
}
