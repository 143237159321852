import styled from "styled-components";

export const BubbleContainer = styled.div.attrs({
  className: "bubble"
})`
  background-color: #DB4C81;
  color: #FFF;
  border-radius: 8px;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  padding: 0 4px;

  @media screen and (max-width: 799px) {
    display: inline;
    text-align: center;
  }
`;
