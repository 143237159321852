import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const PainelPrincipalTopo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const AvisoSupervisores = styled.div`
  font-size: 12px;
  max-width: 480px;
  a {
    color: #000;
    font-weight: 700;
  }
`;
