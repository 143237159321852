import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader, Button } from "@/components/Common";

import { TceProvider } from "@/context/tces/tce";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { useEmpresa } from "@/hooks/empresas";
import { useTce } from "@/hooks/tces";

import { Sidebar, SubMenu as SubmenuEscola } from "@/components/Escola";
import { SubMenu as SubmenuEmpresa } from "@/components/Empresa";

import TCEContent from "@/components/TCE";

import {
  PaineisContainer,
  Content,
  PainelPrincipal,
  MenuInterno,
  VoltarContainer,
} from "./styles";

function _TCE({ history, match }) {
  const { data: user } = useSelector(state => state.auth);
  const { fetchTce, tce } = useTce();
  const { fetchEmpresa, empresa } = useEmpresa();

  useEffect(() => {
    const { id: tceId } = match.params;
    fetchTce(tceId);
    fetchEmpresa(user.empresa.id);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      {(!tce || !empresa) && <FullPageLoader />}
      {tce && empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                {(empresa.escola && <SubmenuEscola type="empresa" />) || (
                  <SubmenuEmpresa type="empresa" />
                )}
              </MenuInterno>
              <PainelPrincipal>
                <VoltarContainer>
                  <Button
                    variant="jeans"
                    icon="arrow-left"
                    onClick={() => {
                      history.go(-1);
                    }}
                  >
                    {" "}
                    Voltar
                  </Button>
                </VoltarContainer>
                <TCEContent tce={tce} />
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function TCE({ history, match }) {
  return (
    <EmpresaProvider>
      <TceProvider>
        <_TCE history={history} match={match} />
      </TceProvider>
    </EmpresaProvider>
  );
}
