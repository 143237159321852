import React from "react";

import { StepperContainer, StepContainer } from "./styles";

function Stepper({ children, ...rest }) {
  return <StepperContainer>{children}</StepperContainer>;
}

function Step({ children, active, step, noBorder, ...ref }) {
  const _active = active !== undefined ? active : undefined;

  return (
    <StepContainer active={_active} noBorder={noBorder}>
      <div className="header">
        <div className="passo">Passo {step}</div>
      </div>
      {children}
    </StepContainer>
  );
}

export { Stepper, Step };
