import React, { useState, useEffect } from "react";
import { osName } from "react-device-detect";
import { FaDownload } from "react-icons/fa";

import { Button, Modal } from "@/components/Common";
import DocumentoTCE from "@/components/TCE/Documento";
import { moeda } from "@/formatters";
import api from "@/services/api";
import toastify from "@/services/toastify";

import ModalAceiteAssinante from "@/components/TCE/ModalAceiteAssinante";

import {
  Container,
  Topo,
  Controles,
  Sobre,
  SobreTitulo,
  SobreSection,
} from "./styles";

export default function TCE({ tce, tceId, codigo }) {
  const { contratado, contratante } = tce.contrato;
  const [loading, setLoading] = useState(false);
  const [showControlls, setShowControlls] = useState(false);
  const [showModalAceite, setShowModalAceite] = useState(false);

  const eu =
    tce.assinantes_escola.filter(assinante => {
      return codigo === assinante.codigo;
    })[0] || null;

  useEffect(() => {
    setShowControlls(state => {
      if (eu === null) return false;
      if (eu !== null && eu.data_assinatura === null && eu.deleted_at === null)
        return true;
    });
  }, [eu]);

  function renderAtividades() {
    const { atividades } = tce.contrato.vaga;
    return atividades.length ? (
      atividades.map((atividade, index) => {
        return <li key={index}>{atividade}</li>;
      })
    ) : (
      <li>Não definidas</li>
    );
  }

  function renderBeneficios() {
    const { beneficios } = tce.contrato.vaga;
    return beneficios.length ? (
      beneficios.map((beneficio, index) => {
        const beneficioValor =
          beneficio.valor > 0 ? (
            <span className="beneficio-valor"> {moeda(beneficio.valor)}</span>
          ) : (
            <></>
          );

        return (
          <li key={index}>
            {beneficio.descricao}
            {beneficioValor}
          </li>
        );
      })
    ) : (
      <li>Não definidos</li>
    );
  }

  function renderDownloadTCEButton() {
    const downloadUrl = `https://www.integrador.app/rest/files/tce-pdf/${btoa(
      tceId
    )}`;

    return (
      <Button to={downloadUrl} target="_blank" externalLink download="tce">
        <FaDownload size={13} /> Contrato
      </Button>
    );
  }

  async function recusarTce() {
    const endpoint = `assinante-tce/${tceId}/${codigo}`;
    setLoading(true);
    const { data: response } = await api.put(endpoint, {
      aceite: 0,
    });
    setLoading(false);
    if (response.success) {
      toastify("Contrato recusado com sucesso");
      setShowControlls(false);
    }
  }

  return (
    <Container>
      <Topo>
        <h3 className="nome">{contratado.nome}</h3>
        <h5 className="empresa">{contratante.nome_exibicao}</h5>
        <h5 className="status">{tce.status.descricao}</h5>
      </Topo>

      {!["android"].includes(osName.toLowerCase()) && (
        <DocumentoTCE tceId={tceId} height="600" />
      )}

      <Controles>
        {showControlls && (
          <>
            <Button
              variant="tangerine"
              onClick={() => setShowModalAceite(true)}
              loading={loading || undefined}
            >
              Li, concordo e aceito
            </Button>
            <Button onClick={recusarTce} loading={loading || undefined}>
              Recusar o contrato
            </Button>
          </>
        )}

        <div className="actions">{renderDownloadTCEButton()}</div>
      </Controles>

      <Sobre>
        <SobreTitulo>
          <h2>Sobre a vaga</h2>
        </SobreTitulo>
        <SobreSection>
          <h4>Descrição</h4>
          <p>{tce.contrato.vaga.descricao}</p>
        </SobreSection>
        <SobreSection>
          <h4>Carga horária semanal</h4>
          <p>{tce.contrato.vaga.carga_horaria_semanal.horas} horas</p>
        </SobreSection>
        <SobreSection>
          <h4>Atividades</h4>
          <ul>{renderAtividades()}</ul>
        </SobreSection>
        <SobreSection>
          <h4>Benefícios</h4>
          <ul>{renderBeneficios()}</ul>
        </SobreSection>
      </Sobre>
      <Modal open={showModalAceite}>
        <ModalAceiteAssinante
          tceId={tce.id}
          onClose={() => setShowModalAceite(false)}
          codigo={codigo}
        />
      </Modal>
    </Container>
  );
}
