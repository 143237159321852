import { call, put, select, all } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import Actions from "../../ducks/tces/add-coordenador";

const getAuthData = state => state.auth.data;

export function* tceAddCoordenador(action) {
  try {
    const { tceId, data: post, onSuccess } = action;
    const authData = yield select(getAuthData);
    const endpoint = `tces/coordenador/${tceId}`;
    const { data: response } = yield call(api.post, endpoint, post, makeOptions(authData));
    if (response.success) {
      toastify("Assinante adicionado com sucesso");
      if (onSuccess) onSuccess();
      yield put(Actions.success());
    } else {
      toastify("Erro ao tentar adicionar assinante", "error");
      yield put(Actions.failure(response.message));
    }
  } catch (error) {
    toastify("Erro ao tentar adicionar assinante", "error");
    yield put(Actions.failure("Erro ao tentar adicionar assinante"));
  }
}
