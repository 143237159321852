import React, { useState, useEffect } from "react";
import { Navbar, Footer } from "@/components/Blocks";
import { Page, Row, Col } from "@/components/Grid";
import { Input, SelectSearch, Select } from "@/components/Form";
import VagaCard from "@/components/Common/Card/VagaCard";
import Paginator, { Loading } from "@/components/Paginator";
import api from "@/services/api";

import { Hero, Main } from "./styles";

export default function Vagas({ history }) {
  const [vagas, setVagas] = useState([]);
  const [loadingVagas, setLoadingVagas] = useState(false);
  const [paginator, setPaginator] = useState(null);
  const [query, setQuery] = useState({ count: 12 });

  const [optionsNiveis, setOptionsNiveis] = useState([]);
  const [queryCursos, setQueryCursos] = useState({ count: 20 });
  const [curso, setCurso] = useState(undefined);

  async function fetchNiveis() {
    const { data: response } = await api.get("utils/niveis");
    if (response.success) {
      setOptionsNiveis(state => {
        return response.data.map(nivel => {
          return { id: nivel.id, title: nivel.nome };
        });
      });
    }
  }

  function handleFetchComplete(data) {
    setVagas(data.data);
    setPaginator(data.paginator);
  }

  function handleChange(event) {
    const { value, name } = event.target;
    setQuery(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  function handleCidadeChange(cidades) {
    if (!cidades.length) return;
    const cidade_id = cidades[0].id;
    setQuery(state => {
      return { ...state, cidade_id };
    });
  }

  function handleNivelChange(event) {
    const { value, name } = event.target;
    setCurso([{ id: null, title: "Todos" }]);
    setQueryCursos(state => {
      return { ...state, ...{ nivel: value } };
    });
  }

  function handleCursoChange(curso) {
    setCurso(curso);
    const selected = curso[0];
    if (selected) {
      setQuery(state => {
        return { ...state, ...{ curso_id: selected.id } };
      });
    }
  }

  function mapOptionsCidades(data) {
    return data.map(d => {
      return { id: d.id, title: d.nome };
    });
  }

  function mapOptionsCursos(data) {
    return data.map(d => {
      return { id: d.id, title: d.nome };
    });
  }

  function renderFilters() {
    return (
      <Row hascols>
        <Col>
          <Input
            label="Buscar vaga"
            placeholder="Digite o nome da vaga ou empresa"
            name="vaga_titulo_empresa"
            onChange={handleChange}
          />
        </Col>
        <Col>
          <SelectSearch
            name="cidade_id"
            label="Cidade"
            endpoint="cidades"
            searchParam="nome"
            mapOptions={mapOptionsCidades}
            onChange={handleCidadeChange}
            firstOption={{ id: null, title: "Todos" }}
          />
        </Col>
        <Col>
          <Select
            label="Grau de ensino"
            name="nivel_id"
            options={optionsNiveis}
            onChange={handleNivelChange}
          />
        </Col>
        <Col>
          <SelectSearch
            label="Curso"
            endpoint="cursos"
            searchParam="nome"
            query={queryCursos}
            mapOptions={mapOptionsCursos}
            onChange={handleCursoChange}
            name="curso_id"
            firstOption={{ id: null, title: "Todos" }}
            value={curso}
          />
        </Col>
      </Row>
    );
  }

  function renderVagas() {
    const listVagas = vagas.map(vaga => {
      return (
        <Col xl="1/4" lg="1/2" md="1" sm="1" key={vaga.id}>
          <VagaCard vaga={vaga} />
        </Col>
      );
    });

    return <Row hascols>{listVagas}</Row>;
  }

  useEffect(() => {
    fetchNiveis();
  }, []);

  return (
    <Page>
      <Navbar type="login" />
      <Hero>
        <h2>Vagas de estágio</h2>
      </Hero>
      <Main>
        {renderFilters()}
        <Loading loading={loadingVagas}>
          {renderVagas()}
          <Paginator
            endpoint="convidado-vagas"
            query={query}
            onFetchComplete={handleFetchComplete}
            onLoading={l => setLoadingVagas(l)}
          />
        </Loading>
      </Main>
      <Footer />
    </Page>
  );
}
