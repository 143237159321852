import React from "react";

import { Page, DashboardContainer } from "@/components/Grid";
import { AdminMenu } from "@/components/Admin";
import { Navbar } from "@/components/Blocks";
import { TotaisGerais, TotaisPorPeriodo } from "@/components/Admin/Indicadores";

export default function Totais({ history }) {


  return (
    <Page>
      <Navbar type="interna" />
      <AdminMenu />
      <DashboardContainer>
        <TotaisPorPeriodo />
        <TotaisGerais />
      </DashboardContainer>
    </Page>
  );
}
