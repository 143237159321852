import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const SocialButton = styled.a`
  background-color: transparent;
  font-size: 20px;
  margin: 0 4px;
`;

export const FacebookButton = styled(SocialButton)`
  color: ${props => (props.href ? "#3c5a99" : "#CCCCCC")};
`;
export const LinkedinButton = styled(SocialButton)`
  color: ${props => (props.href ? "#0077b5" : "#CCCCCC")};
`;
export const InstagramButton = styled(SocialButton)`
  color: ${props => (props.href ? "#833ab4" : "#CCCCCC")};
`;
export const TwitterButton = styled(SocialButton)`
  color: ${props => (props.href ? "#1da1f2" : "#CCCCCC")};
`;

