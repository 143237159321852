import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { Button } from "@/components/Common";
import {
  Form,
  Input,
  InputMoney,
  Select,
  SelectSearch,
} from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import { ModalContent, ModalBody, ModalFooter } from "./styles";
import { useAssinante } from "@/hooks/assinantes";

export default function ModalAddAssinante({ onClose }) {
  const { data: user } = useSelector(state => state.auth);
  const { createAssinante, loading } = useAssinante();
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [formData, setFormData] = useState({});
  const [queryCursos, setQueryCursos] = useState({ status: 'ativos' });

  const schema = Yup.object().shape({
    nome: Yup.string().required("Obrigatório"),
    sobrenome: Yup.string().required("Obrigatório"),
    email: Yup.string().email("E-mail inválido").required("Obrigatório"),
    cargo: Yup.string().required("Obrigatório"),
    cursos: Yup.string().required("Obrigatório"),
  });

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, ...{ [name]: value } };
    });
  }
  
  function handleCursoChange(array_cursos) {
    setFormData(state => {
      return { ...state, ...{ array_cursos } };
    });
  }

  function mapOptionsCursos(data) {
    const options = data.map(d => {
      return { id: d.id, title: `${d.nome} (${d.nivel.nome})` };
    });
  
    return options;
  }

  async function handleSubmit(data) {
    setLoadingCreate(true)
    const assinante = {
      ...data,
      empresa_id: user.empresa.id
    };
    await createAssinante(assinante);
    setLoadingCreate(false);
    setFormData({})
  }

  useEffect(() => {
    if (!loadingCreate) onClose();
  }, [loadingCreate]);

  return (
    <ModalContent>
      <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
        <ModalBody>
          <Row hascols>
            <Col>
              <Input
                label="Nome"
                name="nome"
                value={formData.nome || ''}
                onChange={handleOnChange}
              />
            </Col>
            <Col>
              <Input
                label="Sobrenome"
                name="sobrenome"
                value={formData.sobrenome || ''}
                onChange={handleOnChange}
              />
            </Col>
          </Row>
          <Row hascols>
            <Col>
              <Input
                label="Email"
                name="email"
                value={formData.email || ''}
                onChange={handleOnChange}
              />
            </Col>
            <Col>
              <Input
                label="Cargo"
                name="cargo"
                value={formData.cargo || ''}
                onChange={handleOnChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectSearch
                label="Cursos aceitos"
                endpoint={`empresas/${user.empresa.id}/cursos`}
                searchParam="nome"
                name="cursos"
                mapOptions={mapOptionsCursos}
                onChange={handleCursoChange}
                value={formData.array_cursos || []}
                query={queryCursos}
                multiple
                relative
                selectAll
              />
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button variant="cancel" onClick={onClose}>
            CANCELAR
          </Button>
          <Button
            type="submit"
            variant="tangerine"
            loading={loadingCreate || undefined}
          >
            CRIAR ASSINANTE
          </Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
