import styled from "styled-components";
import { Card as BaseCard } from "@/components/Common";

import arrowCircle from "@/assets/images/icons/arrow-circle.png";

export const Card = styled(BaseCard).attrs({
  shadow: true,
  flex: true,
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardBody = styled.div`
  text-transform: uppercase;
  .empresa,
  .local {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }
  .empresa {
    color: #4d65b3;
  }
  .area {
    font-size: 12px;
  }
  .funcao {
    color: #2e2d38;
    font-size: 19px;
    margin-top: 14px;
  }
  .remuneracao {
    margin-bottom: 14px;
  }
  .local {
    color: #9c9ba7;
  }
  ul.cursos {
    margin-top: 12px;
    font-size: 12px;
  }
`;

export const CardFooter = styled.div`
  margin-top: 30px;
  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #4d65b3;
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
    background-position: center left;
    background-repeat: no-repeat;
    transition: 300ms ease;

    svg {
      margin-right: 8px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;
