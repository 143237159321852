import styled from "styled-components";

export const VagaContainer = styled.button`
  width: 100%;
  height: 100%;
  min-height: 256px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  padding: 32px;
  background-color: #f5f5f5;
  text-align: left;
  transition: 300ms;

  &:hover {
    color: #f78764;
    div.candidato,
    div.entrevistas {
      transform: translateX(2px);
      svg {
        color: #f78764;
        transform: translateX(3px);
      }
    }
  }

  div.body {
    h4.tipo {
      color: ${({type}) => {
        return type === "fechadas" ? "#2B0C75" : "#f78764";
      }};
      letter-spacing: 1.5px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    h3.titulo {
      color: #2e2d38;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 12px;
    }
    h5.local {
      color: #9491b4;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  div.footer {
    display: flex;
    flex-direction: column;
  }
`;

export const Candidatos = styled.div.attrs({
  className: "candidato",
})`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${props => (props.candidatos ? "#279CD9" : "#271946")};
  ${props => props.candidatos && "text-transform: uppercase"};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  transition: 300ms;

  svg {
    margin-right: 8px;
    color: #271946;
    transition: 300ms;
  }
`;
export const Entrevistas = styled.div.attrs({
  className: "entrevistas",
})`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #279cd9;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  transition: 300ms;

  svg {
    margin-right: 8px;
    color: #271946;
    transition: 300ms;
  }
`;

export const Expira = styled.p`
  font-size: 13px;
  color: #8b8a95;
  font-weight: 400;
`;

export const TotalResult = styled.p`
  font-size: 16px;
  color: #605f6a;
  font-weight: 400;
  margin-bottom: 24px;
`;

export const BuscarVagas = styled.div`
  margin-bottom: 40px;
`;
