import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FiMapPin, FiBriefcase, FiMoreHorizontal } from "react-icons/fi";
import { Modal } from "@/components/Common";
import { useEmpresa } from "@/hooks/empresas";
import { ModalEditPerfil, ModalEditAvatar } from "@/components/Empresa";
import ModalContato from "@/components/Empresa/ModalContato";
import { SidebarContainer, Avatar } from "./styles";

export default function Sidebar() {
  const { data: user } = useSelector(state => state.auth);
  const { empresa } = useEmpresa();
  const [modalEditPerfilOpen, setModalEditPerfilOpen] = useState(false);
  const [modalEditAvatarOpen, setModalEditAvatarOpen] = useState(false);
  const [modalShowFaleConosco, setModalShowFaleConosco] = useState(false);

  function isFuncionario() {
    const isFuncionario = user.tipo === "funcionario";
    const belongsToEmpresa =
      user.empresa !== null && user.empresa.id === empresa.id;
    return (isFuncionario && belongsToEmpresa) || undefined;
  }

  return (
    <SidebarContainer>
      {empresa && (
        <>
          <Avatar
            editable={isFuncionario()}
            src={empresa.imagem}
            onClick={() => {
              if (isFuncionario()) {
                setModalEditAvatarOpen(true);
              }
            }}
          />
          <h2>{empresa.nome_exibicao}</h2>
          {isFuncionario() && (
            <div className="actions">
              <button
                type="button"
                className="editar"
                onClick={() => setModalEditPerfilOpen(true)}
              >
                Editar
              </button>
              <button
                type="button"
                className="editar"
                onClick={() => setModalShowFaleConosco(true)}
              >
                Fale Conosco
              </button>
            </div>
          )}
          <div className="info">
            <h5>
              <FiMapPin />
              {empresa.endereco.cidade.nome} /{" "}
              {empresa.endereco.cidade.estado.sigla}
            </h5>
            <h5>
              <FiBriefcase />
              {empresa.atuacao}
            </h5>
          </div>
          <div className="apresentacao">
            <p>{empresa.apresentacao}</p>
          </div>

          <Modal
            open={modalEditAvatarOpen}
            onClose={() => setModalEditAvatarOpen(false)}
          >
            <ModalEditAvatar onClose={() => setModalEditAvatarOpen(false)} />
          </Modal>

          <Modal
            open={modalEditPerfilOpen}
            onClose={() => setModalEditPerfilOpen(false)}
          >
            <ModalEditPerfil onClose={() => setModalEditPerfilOpen(false)} />
          </Modal>
          <Modal
            open={modalShowFaleConosco}
            onClose={() => setModalShowFaleConosco(false)}
          >
            <ModalContato OnClose={() => setModalShowFaleConosco(false)} />
          </Modal>
        </>
      )}
    </SidebarContainer>
  );
}
