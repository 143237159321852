import styled from "styled-components";

export const SectionOptions = styled.div`
  min-height: calc(100vh - 85px);
  padding-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div.card-opcao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    p.title {
      color: #f78764;
      font-size: 18px;
      text-transform: uppercase;
      margin: 17px 0;
      font-weight: bold;
    }
    p.description {
      margin-bottom: 30px;
    }
  }
`;
