import React from "react";
import { Route } from "react-router-dom";

import EmpresaVaga from "@/pages/Empresa/Vagas/Vaga";
import AlunoVaga from "@/pages/Aluno/Vagas/Vaga";
import GuestVaga from "@/pages/Guest/Vagas/Vaga";

export default function VagaRoute({ auth, ...rest }) {
  // idenficar se o usuario está logado
  const { authenticated, data } = auth;

  let Component;
  if (!authenticated) {
    Component = GuestVaga;
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  const { tipo, empresa } = data;

  switch (true) {
    case tipo === "aluno":
      Component = AlunoVaga;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && !empresa.is_escola:
      Component = EmpresaVaga;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && empresa.is_escola:
      // Component = EmpresaVaga;
      break;
    case ["administrador"].includes(tipo):
      Component = GuestVaga;
      break;
    case ["franqueado"].includes(tipo):
      Component = GuestVaga;
      break;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
