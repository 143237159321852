import styled from "styled-components";

export const Container = styled.div`
  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  margin: 30px 0px;
`;

export const Assinante = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #b9b7cc;

  .coluna {
    &-nome {
      flex: 0 0 250px;
      h4 {
        color: #271946;
        font-size: 16px;
        font-weight: 600;
      }
    }
    &-status {
      flex: 0 0 200px;
      color: ${({status}) => {
        if (status === "Aguardando") return "#000";
        if (status === "Reprovado") return "#FF987F";
        if (status === "Aprovado") return "#3FBCDB";
      }};
      font-weight: 600;
      h4 {
        color: #605f6a;
        font-size: 16px;
        font-weight: 600;
      }
    }
    &-actions {
      margin-left: auto;
    }
  }

`;
