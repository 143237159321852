import React, { useEffect, useRef, useState } from "react";
import { useField } from "@rocketseat/unform";
import MySelectSearch from "./implementation";

/**
 *
 * @param {string} endpoint - endpoint da api a ser acessado
 * @param {string} label - label a ser exibida
 * @param {function} mapOptions - função para mapear os resultados da api
 * @param {function} onChange - função que retorna o valor selecionado
 * @param {object} query - query a ser injetada na query enviada a api ex.: { count: 5, order: desc }
 * @param {string} searchParam - qual o parametro de busca ex.: nome
 */
export default function SelectSearch({
  name,
  endpoint,
  label,
  mapOptions,
  onChange,
  search,
  query,
  multiple,
  searchParam = "search",
  value,
  firstOption,
  relative, // útil para exibir dentro de modais
  selectAll,
  ...rest
}) {
  const { fieldName, registerField, error } = useField(name);
  const [_value, _setValue] = useState("");
  const ref = useRef();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "value",
    });
  }, [ref.current, fieldName, value]);

  useEffect(() => {
    _setValue(state => {
      if (value === undefined) return "";
      return value
        .filter(option => option.hasOwnProperty("title"))
        .map(option => {
          return option.id ? option.id : "";
        })
        .join(",");
    });
  }, [value]);

  return (
    <MySelectSearch
      endpoint={endpoint}
      label={label}
      mapOptions={mapOptions}
      onChange={onChange}
      query={query}
      search={search}
      searchParam={searchParam}
      multiple={multiple}
      value={value}
      firstOption={firstOption}
      relative={relative}
      selectAll={selectAll}
      {...rest}
    >
      <input
        type="hidden"
        id={fieldName}
        name={fieldName}
        ref={ref}
        value={_value}
      />
      {error && <span>{error}</span>}
    </MySelectSearch>
  );
}
