import React, { useState, useEffect } from "react";

import { PulseLoader } from "react-spinners";
import { FiTrash2 } from "react-icons/fi";
import { SupervisorContainer } from "./styles";

export default function Supervisor({ nome, email, cargo, telefone, onDelete, isDeleting }) {
  const [isAskingForDelete, setIsAskingForDelete] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false);

  const _handleAskForDelete = () => {
    setIsAskingForDelete(true);
  };

  const _handleDelete = () => {
    setIsAskingForDelete(false);
    // setIsDeleting(true);
    onDelete();
  };

  const _renderAction = () => {
    let Component;
    switch (true) {
      case isAskingForDelete:
        Component = (
          <div className="action">
            Tem certeza?{" "}
            <button className="action-delete" onClick={_handleDelete}>
              SIM
            </button>{" "}
            <button
              className="action-cancel"
              onClick={() => setIsAskingForDelete(false)}
            >
              NÃO
            </button>
          </div>
        );
        break;
      case isDeleting:
        Component = <PulseLoader size={8} color={"#f78764"} />;
        break;
      default:
        Component = (
          <button className="trash-can" onClick={_handleAskForDelete}>
            <FiTrash2 />
          </button>
        );
    }
    return Component;
  };

  return (
    <SupervisorContainer>
      <div title={nome} className="col col-nome">
        {nome}
        <div className="cargo">
          <small>{cargo}</small>
        </div>
      </div>
      <div title={email} className="col col-email">
        {email}
      </div>
      <div title={telefone} className="col col-telefone">
        {telefone}
      </div>
      <div className="col col-actions">{_renderAction()}</div>
    </SupervisorContainer>
  );
}
