import styled from "styled-components";

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  padding: 12px;

  .coluna {
    &-toggle {
      width: 60px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-nome {
      flex: 1 0 400px;
      h4 {
        font-size: 16px;
        font-weight: 600;
        color: #423b8d;
      }
    }
    &-nivel {
      h4 {
        font-size: 14px;
        font-weight: 400;
        color: #605f6a;
      }
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0 8px;
  width: 48px;
  height: 48px;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.9);
  }
`;

export const AtivoButton = styled(Button)``;
