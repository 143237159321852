import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FaChevronCircleRight, FaSignInAlt } from "react-icons/fa";

import AuthActions from "@/store/ducks/auth";
import { Input, InputMask } from "@/components/Form";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import { AdminMenu } from "@/components/Admin";
import { Navbar } from "@/components/Blocks";
import Paginator, { Loading } from "@/components/Paginator";
import PlanilhaAlunos from "@/components/Planilhas/Alunos";

import {
  Header,
  FiltroBasico,
  FilterButtonContainer,
  Totais,
  AlunoListItem,
  ActionButton,
} from "./styles";

export default function Alunos({ history, match }) {
  const dispatch = useDispatch();
  const [alunos, setAlunos] = useState([]);
  const [readyToPaginate, setReadyToPaginate] = useState(false);
  const [query, setQuery] = useState({});
  const [paginator, setPaginator] = useState(null);
  const [loadingAlunos, setLoadingAlunos] = useState(false);

  function handleOnFetchComplete(data) {
    setAlunos(data.data);
    setPaginator(data.paginator);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, [name]: value };
    });
  }

  function renderHeader() {
    let titulo;

    const status = match.params.hasOwnProperty("status")
      ? match.params.status
      : null;

    switch (true) {
      case status === "todos":
        titulo = "Todos os estudantes";
        break;
      case status === "comTceAtivo":
        titulo = "Estudantes com estágio ativo";
        break;
      case status === "semTceAtivo":
        titulo = "Estudantes sem estágio ativo";
        break;
      case status === "comAvaliacoesPendentes":
        titulo = "Estudantes com avaliações de contrato pendentes";
        break;
    }

    return (
      <Header>
        <h4>Estudantes</h4>
        <h2>{titulo}</h2>
        <div className="breadcrumbs">
          Estudantes {">"} <span>{titulo}</span>
        </div>
      </Header>
    );
  }

  function renderFiltroBasico() {
    const columnName = {
      lg: "2/3",
      md: 0.5,
      sm: 1,
    };

    const columnCpf = {
      lg: "1/3",
      md: 0.5,
      sm: 1,
    };

    const columnsConfig = {
      lg: "1/3",
      md: 0.5,
      sm: 1,
    };

    return (
      <FiltroBasico>

        <Row hascols>
          <Col {...columnName}>
            <Input
              label="Nome do estudante"
              name="nome"
              placeholder="Busque pelo nome"
              onChange={handleOnChange}
            />
          </Col>
          <Col {...columnCpf}>
            <InputMask
              type="cpf"
              label="CPF do estudante"
              name="cpf"
              placeholder="Busque pelo CPF"
              onChange={handleOnChange}
            />
          </Col>
        </Row>

        <Row hascols>
          <Col {...columnsConfig}>
            <Input
              label="Cidade"
              name="cidade"
              placeholder="Busque pela cidade"
              onChange={handleOnChange}
            />
          </Col>
          <Col {...columnsConfig}>
            <Input
              label="Nome do curso"
              name="curso_nome"
              placeholder="Busque pelo curso"
              onChange={handleOnChange}
            />
          </Col>
          <Col {...columnsConfig}>
            <FilterButtonContainer>
              <PlanilhaAlunos block params={query} />
            </FilterButtonContainer>
          </Col>
        </Row>

      </FiltroBasico>
    );
  }

  function renderTotais() {
    if (loadingAlunos) return <></>;
    if (paginator !== null) {
      const { total, pages } = paginator;
      if (total === 0) {
        return (
          <Totais>
            Sem estudantes para exibir dentro dos critérios de busca
          </Totais>
        );
      }
      return (
        <Totais>
          Exibindo {alunos.length} de {total} estudantes
        </Totais>
      );
    }
  }

  function handleVerPerfil(alunoId) {
    history.push(`/aluno/${alunoId}`);
  }

  function handleAssumirControle(alunoId) {
    const data = {
      entidade_tipo: "aluno",
      entidade_id: alunoId,
    };
    dispatch(AuthActions.authAssumirControleRequest(data, history));
  }

  function renderAlunos() {
    return alunos.map(aluno => {
      return (
        <AlunoListItem key={aluno.id}>
          <div className="coluna coluna-nome">
            <h4>{aluno.nome}</h4>
            {aluno.curso.titulo}
          </div>
          <div className="coluna coluna-escola">
            <h4>Escola</h4>
            {aluno.empresa.nome_exibicao}
          </div>
          <div className="coluna coluna-status">
            <h4>Status</h4>
            {aluno.estagio_status}
          </div>
          <div className="coluna coluna-actions">
            <ActionButton onClick={() => handleAssumirControle(aluno.id)}>
              <FaSignInAlt color="#271946" size={14} />{" "}
              <span>Assumir controle</span>
            </ActionButton>
            <ActionButton onClick={() => handleVerPerfil(aluno.id)}>
              <FaChevronCircleRight color="#271946" size={14} />{" "}
              <span>Ver perfil</span>
            </ActionButton>
          </div>
        </AlunoListItem>
      );
    });
  }

  const setQueryCB = useCallback(() => {
    const status = match.params.hasOwnProperty("status")
      ? match.params.status
      : null;
    setQuery(state => ({ ...state, ...(status && { filters: status }) }));
    setReadyToPaginate(true);
  }, [match.params, setQuery, setReadyToPaginate]);

  useEffect(() => {
    setQueryCB();
  }, [setQueryCB]);

  return (
    <Page>
      <Navbar type="interna" />
      <AdminMenu />
      <DashboardContainer>
        {renderHeader()}
        {renderFiltroBasico()}
        {renderTotais()}
        <Loading loading={loadingAlunos}>
          {renderAlunos()}
          {readyToPaginate && (
            <Paginator
              throttle={2500}
              endpoint="alunos"
              query={query}
              onFetchComplete={handleOnFetchComplete}
              onLoading={l => setLoadingAlunos(l)}
            />
          )}
        </Loading>
      </DashboardContainer>
    </Page>
  );
}
