import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  direction: row;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const TopoInfo = styled.div`
  margin-bottom: 48px;
  div.info {
    ul li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 35px;
      }
      svg {
        color: #279cd9;
        margin-right: 9px;
        font-size: 16px;
      }
    }
  }
`;

export const VagaControllerWrapper = styled.div`
  margin-bottom: 36px;
`;

export const VagaController = styled.div`
  display: flex;
  flex-direction: row;

  div.coluna {
    display: flex;
    flex-direction: row;
    .button-container:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const CantAddEntrevistaMessage = styled.div`
  margin: 16px 0;
`;

export const SectionContainer = styled.div.attrs({
  className: "section-container",
})`
  margin-bottom: 80px;
  div.titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    border-bottom: 1px solid #b9b7cc;
    padding-bottom: 18px;
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  div.dados-pessoais-box {
    h4 {
      color: #f78764;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
    }
  }
`;

export const Avatar = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 55px;
  background-color: #ffffff;
  overflow: hidden;
  background-image: ${() => ({ src }) => `url(${src})`};
  background-size: cover;
`;

export const EscolaAtual = styled.div.attrs({
  className: "escola-atual",
})`
  display: flex;
  flex-direction: row;
  div.info {
    display: flex;
    flex-direction: column;
    padding: 16px;
    justify-content: space-between;
    position: relative;

    div.info-titulo {
      h2 {
        color: #423b8d;
        font-size: 18px;
      }
    }

    div.info-datas {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      > div {
        padding-right: 28px;
        span {
          font-weight: bold;
        }
      }
    }
  }
`;

export const FormacoesAnteriores = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const FormacaoAnterior = styled.div`
  flex-basis: calc(100% / 3);
  margin-top: 32px;

  div.info {
    position: relative;
    h2 {
      color: #423b8d;
      font-size: 18px;
      font-weight: 600;
    }

    div.info-datas {
      margin-top: 16px;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      > div {
        padding-right: 28px;
        span {
          font-weight: bold;
        }
      }
    }
  }
`;

export const ExperienciasProfissionais = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ExperienciaProfissional = styled.div`
  flex-basis: calc(100% / 3);
  margin-top: 32px;
  position: relative;
  h2 {
    font-size: 18px;
    color: #423b8d;
    font-weight: 600;
  }
  p.cargo {
    font-size: 18px;
    margin-bottom: 8px;
  }
  div.datas {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 36px;
    > div {
      padding-right: 28px;
      span {
        font-weight: bold;
      }
    }
  }
  h4 {
    color: #f78764;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 2px;
  }
  p.descricao {
    font-size: 16px;
  }
`;

export const CursosComplementares = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CursoComplementar = styled.div`
  flex-basis: calc(100% / 3);
  margin-top: 32px;
  position: relative;
  h2 {
    font-size: 18px;
    color: #423b8d;
    font-weight: 600;
    margin-bottom: 8px;
  }
  div.datas {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 36px;
    > div {
      padding-right: 28px;
      span {
        font-weight: bold;
      }
    }
  }
  h4 {
    color: #f78764;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 2px;
  }
  p.descricao {
    font-size: 16px;
  }
`;


export const AtividadesComplementares = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AtividadeComplementar = styled.div`
  flex-basis: calc(100% / 3);
  margin-top: 32px;
  position: relative;
  h2 {
    font-size: 18px;
    color: #423b8d;
    font-weight: 600;
    margin-bottom: 8px;
  }
  div.datas {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 36px;
    > div {
      padding-right: 28px;
      span {
        font-weight: bold;
      }
    }
  }
  h4 {
    color: #f78764;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 2px;
  }
  p.descricao {
    font-size: 16px;
  }
`;

export const Idiomas = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Idioma = styled.div.attrs({
  className: "list-item"
})`
  font-size: 18px;
  width: calc(100% / 6);
  position: relative;
  h2 {
    color: #423B8D;
    font-size: 18px;
    font-weight: 600;
  }
`;
