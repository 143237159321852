import styled from "styled-components";

export const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const SectionHeader = styled.div`
  margin: 0 auto;
  max-width: 530px;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

  h3 {
    font-size: 32px;
    line-height: 36px;
    color: #4d65b3;
    margin-bottom: 24px;
  }

  h3.vagasTitle {
    font-size: 32px;
    line-height: 36px;
    color: #fbca01;
    margin-bottom: 24px;
  }

  p {
    font-size: 18px;
    color: grey;
  }

  p.vagasDescription {
    color: #fff;
  }

  @media screen and (max-width: 640px) {
    padding: 0 2rem;
  }
`;

export const SectionHero = styled.div`
  background-color: #ffffff;
`;

export const SectionVagas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  padding: 72px 0 88px 0;
  position: relative;

  div.vagas {
    max-width: 1100px;
    width: 100%;
    margin-bottom: 54px;
    @media screen and (max-width: 1200px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  img.firula {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

export const SectionFeatures = styled.div`
  background-color: #ffffff;
  padding: 72px 0;
  max-width: 100vw;
  overflow-x: hidden;

  div.features {
    max-width: 1100px;
    margin: 0 auto;
  }
`;

export const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p.titulo {
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-top: 30px;
    margin-bottom: 17px;
  }

  p.descricao {
    font-size: 18px;
    line-height: 24px;
    color: #605f6a;
  }

  @media screen and (max-width: 1100px) {
    padding: 0 2rem;
  }
`;

export const Divider = styled.hr`
  border: 1px solid #d5d5d5;
  max-width: 750px;
  margin: 54px auto;
`;

export const Empresas = styled.div`
  text-align: center;
  max-width: 820px;
  margin: 0 auto;

  h3 {
    font-size: 32px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 40px;
  }
`;

export const Empresa = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
