import React, { useState } from "react";

const SupervisoresContext = React.createContext([{}, () => {}]);

const SupervisoresProvider = props => {
  const [state, setState] = useState({
    data: [],
    loading: false,
  });

  return (
    <SupervisoresContext.Provider value={[state, setState]}>
      {props.children}
    </SupervisoresContext.Provider>
  );
};

export { SupervisoresContext, SupervisoresProvider };
