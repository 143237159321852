import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { Button } from "@/components/Common";
import {
  Form,
  Input,
  InputMoney,
  Select,
  SelectSearch,
} from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import { estados, disponibilidade as optionsDisponibilidade } from "@/data";
import { useVaga } from "@/hooks/vagas";

import api, { makeOptions } from "@/services/api";
import Atividades from "./Atividades";
import Beneficios from "./Beneficios";
import DiasSemana from "./DiasSemana";

import { ModalContent, ModalBody, ModalFooter, Erro } from "./styles";

export default function ModalEditVaga({ onClose }) {
  const { data: user } = useSelector(state => state.auth);
  const { updateVaga, loading, vaga } = useVaga();
  const [formData, setFormData] = useState(null);

  const [optionsSupervisores, setOptionsSupervisores] = useState([]);
  const [optionsCidades, setOptionsCidades] = useState([]);
  const [cidadesLoading, setCidadesLoading] = useState(false);
  const [cargaHorariaSemanal, setCargaHorariaSemanal] = useState(0);
  const [errorAtividades, setErrorAtividades] = useState(null);
  const [errorDias, setErrorDias] = useState(null);
  const [queryCursos, setQueryCursos] = useState(null);
  const [gambi, setGambi] = useState(0); // SIM. O PODER DA GAMBIARRA

  const optionsSexo = [
    { id: "A", title: "Ambos" },
    { id: "F", title: "Feminino" },
    { id: "M", title: "Masculino" },
  ];

  const optionsEstados = estados.map(e => {
    return { id: e.id, title: e.uf };
  });

  const optionsCargaHoraria = [
    { id: 60, title: "1 hora" },
    { id: 120, title: "2 horas" },
    { id: 180, title: "3 horas" },
    { id: 240, title: "4 horas" },
    { id: 300, title: "5 horas" },
    { id: 360, title: "6 horas" },
  ];

  function prepareFormData(data) {
    if (data) {
      const {
        remuneracao,
        auxilio_transporte,
        cursos,
        niveis,
        cidade,
        sexo: sexoData,
        // periodo: periodoData,
      } = data;

      const estado = cidade.estado.id;
      fetchCidades(estado);

      const array_cursos = cursos.map(c => {
        return { id: c.id, title: `${c.nome} (${c.nivel.nome})` };
      });

      const array_niveis = niveis.map(n => {
        return { id: n.id, title: n.nome };
      });

      // usando o default como A para agilizar. Na hora de enviar, transformo A em ""
      const sexo = sexoData || "A";
      // const periodo = periodoData || "Q";

      const _formData = {
        ...data,
        ...{ remuneracao: parseFloat(remuneracao) },
        ...{ auxilio_transporte: parseFloat(auxilio_transporte) },
        ...{ array_cursos, array_niveis, sexo, estado },
      };
      // setQueryCursos(state => {
      //   const niveisStr = array_niveis.map(n => n.id).join(",");
      //   return { niveis: niveisStr };
      // });
      setFormData(_formData);
    }
  }

  const schema = Yup.object().shape({
    titulo: Yup.string().required("Obrigatório"),
    supervisor_id: Yup.number()
      .typeError("Obrigatório")
      .required("Obrigatório"),
    remuneracao: Yup.number()
      .min(1, "A remuneração é obrigatória")
      .required("Obrigatório"),
    auxilio_transporte: Yup.number()
      .min(1, "Obrigatório")
      .required("Obrigatório"),
    cursos: Yup.string().required("Obrigatório"),
    niveis: Yup.string().required("Obrigatório"),
    periodo: Yup.string().required("Obrigatório"),
    sexo: Yup.mixed(),
    descricao: Yup.string().required("Obrigatório"),
    quantidade: Yup.number()
      .typeError("Quantidade inválida")
      .min(0, "Quantidade inválida")
      .required("Obrigatório"),
    pcd: Yup.number()
      .typeError("Obrigatório")
      .required("Obrigatório"),
    estado: Yup.string().required("Obrigatório"),
    cidade_id: Yup.string().required("Obrigatório"),
    carga_horaria: Yup.string().required("Obrigatório"),
    // atividades: Yup.mixed(),
    // beneficios: Yup.mixed(),
  });

  async function fetchSupervisores() {
    const empresaId = user.empresa.id;
    const endpoint = `supervisores/empresa/${empresaId}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    if (response.success) {
      setOptionsSupervisores(() => {
        return response.data.map(s => {
          const { id, nome } = s;
          return { id, title: nome };
        });
      });
    }
  }

  async function fetchCidades(estadoId) {
    if (estadoId === null) return;
    setCidadesLoading(true);
    const { data: retorno } = await api.get(`cidades/estado/${estadoId}`);
    if (retorno.success) {
      const cidades = retorno.data.map(c => {
        return { id: c.id, title: c.nome };
      });
      setOptionsCidades(cidades);
      setCidadesLoading(false);
    }
  }

  function calcCargaHorariaSemanal({ dias_semana, carga_horaria }) {
    const cargaHorariaDiariaMinutos = Number(carga_horaria);
    const diasSemanaQuant = dias_semana.length;
    const cargaHorariaSemanalMinutos =
      cargaHorariaDiariaMinutos * diasSemanaQuant;
    setCargaHorariaSemanal(cargaHorariaSemanalMinutos / 60);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  function handleEstadoChange(event) {
    event.persist();
    const { value } = event.target;
    setFormData(state => {
      return { ...state, ...{ cidade_id: "", estado: value } };
    });
    fetchCidades(value);
  }

  function handleNivelChange(array_niveis) {
    /**
     * fazendo com que a constante queryCursos só faça com que o seletor de cursos
     * seja limpo a partir da segunda vez que passarmos por esta função
     */
    if (gambi > 0) {
      setQueryCursos(() => {
        const niveisStr = array_niveis.map(n => n.id).join(",");
        return { niveis: niveisStr };
      });
    }

    setFormData(state => {
      return { ...state, ...{ array_niveis } };
    });

    setGambi(state => state + 1);
  }

  function mapOptionsNiveis(data) {
    return data.map(d => {
      return { id: d.id, title: d.nome };
    });
  }

  function handleCursoChange(array_cursos) {
    setFormData(state => {
      return { ...state, ...{ array_cursos } };
    });
  }

  function mapOptionsCursos(data) {
    return data.map(d => {
      return { id: d.id, title: `${d.nome} (${d.nivel.nome})` };
    });
  }

  function handleDiasSemanaChange(dias_semana) {
    setFormData(state => {
      return { ...state, ...{ dias_semana } };
    });
  }

  function handleAtividadesChange(atividades) {
    setFormData(state => {
      return { ...state, ...{ atividades } };
    });
  }

  function handleBeneficiosChange(beneficios) {
    setFormData(state => {
      return { ...state, ...{ beneficios } };
    });
  }

  function handleSubmit(data) {
    if (cargaHorariaSemanal > 30) return;

    // os parametros atividades e beneficios não são tratados pelo form
    const {
      id,
      atividades,
      beneficios,
      dias_semana,
      sexo: sexoData,
    } = formData;

    let atividadesValidas = [];
    for (let i = 0; i < atividades.length; i++) {
      if (atividades[i] !== "") {
        atividadesValidas.push(atividades[i]);
      }
    }

    let diasSemanaValidos = [];
    for (let i = 0; i < dias_semana.length; i++) {
      if (dias_semana[i] !== "") {
        diasSemanaValidos.push(dias_semana[i]);
      }
    }

    if (diasSemanaValidos.length < 1) {
      setErrorDias("Informe ao menos 1 dia da semana");
      console.log('Erro dias');
      return;
    } else {
      setErrorDias(null);
    }

    if (atividadesValidas.length < 3) {
      setErrorAtividades("Informe ao menos 3 atividades");
      return;
    } else {
      setErrorAtividades(null);
    }

    const sexo = ["F", "M"].includes(sexoData) ? sexoData : "";

    const vaga = {
      ...data,
      ...{ empresa_id: user.empresa.id },
      ...{ sexo },
      ...(dias_semana && { dias_semana: diasSemanaValidos }),
      ...(atividades && { atividades: atividadesValidas }),
      ...(beneficios && {
        beneficios: beneficios.filter(b => b.descricao !== ""),
      }),
    };

    updateVaga(id, vaga);
  }

  function renderAvisoCargaHorariaSemanal() {
    if (cargaHorariaSemanal > 30) {
      return <Erro>A carga horária semanal não pode exceder 30 horas</Erro>;
    }

    return <></>;
  }

  useEffect(() => {
    prepareFormData(vaga);
  }, [vaga]);

  useEffect(() => {
    if (!loading) onClose();
  }, [loading]);

  useEffect(() => {
    fetchSupervisores();
  }, []);

  useEffect(() => {
    if (formData) {
      const { dias_semana, carga_horaria } = formData;
      if (dias_semana && carga_horaria) {
        calcCargaHorariaSemanal({ dias_semana, carga_horaria });
      }
    }
  }, [formData]);

  return (
    <ModalContent>
      {formData && (
        <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
          <ModalBody>
            <Row>
              <Col>
                <Input
                  label="Título da vaga"
                  name="titulo"
                  value={formData.titulo}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectSearch
                  label="Grau de escolaridade desejado"
                  endpoint="utils/niveis"
                  searchParam="nome"
                  mapOptions={mapOptionsNiveis}
                  onChange={handleNivelChange}
                  name="niveis"
                  value={formData.array_niveis}
                  multiple
                  relative
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectSearch
                  label="Cursos aceitos"
                  endpoint="cursos"
                  searchParam="nome"
                  name="cursos"
                  mapOptions={mapOptionsCursos}
                  onChange={handleCursoChange}
                  value={formData.array_cursos}
                  query={queryCursos || undefined}
                  multiple
                  relative
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Select
                  label="Disponibilidade desejada"
                  options={optionsDisponibilidade}
                  name="periodo"
                />
              </Col>
              <Col>
                <Select
                  label="Carga horária diária"
                  options={optionsCargaHoraria}
                  name="carga_horaria"
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <DiasSemana
              value={formData.dias_semana || undefined}
              onChange={handleDiasSemanaChange}
              error={errorDias}
            />
            {renderAvisoCargaHorariaSemanal()}
            <Row>
              <Col>
                <Select
                  label="Sexo"
                  options={optionsSexo}
                  name="sexo"
                  value={formData.sexo || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  multiline
                  label="Descrição da vaga"
                  name="descricao"
                  value={formData.descricao}
                  onChange={handleOnChange}
                  maxLength={500}
                  showLength
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  type="number"
                  min="0"
                  label="Quantidade"
                  name="quantidade"
                  value={formData.quantidade}
                  onChange={handleOnChange}
                />
              </Col>
              <Col>
                <Select
                  label="Exclusivo para PCD"
                  name="pcd"
                  value={formData.pcd}
                  onChange={handleOnChange}
                  options={[
                    { id: "0", title: "Não" },
                    { id: "1", title: "Sim" },
                  ]}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <InputMoney
                  label="Bolsa auxílio mensal"
                  name="remuneracao"
                  value={formData.remuneracao}
                  onChange={handleOnChange}
                />
              </Col>
              <Col>
                <InputMoney
                  label="Auxílio transporte mensal"
                  name="auxilio_transporte"
                  value={formData.auxilio_transporte}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Select
                  label="UF"
                  name="estado"
                  onChange={handleEstadoChange}
                  options={optionsEstados}
                  value={formData.estado || ""}
                />
              </Col>
              <Col>
                <Select
                  label="Cidade"
                  name="cidade_id"
                  options={optionsCidades}
                  loading={cidadesLoading}
                  value={formData.cidade_id || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  label="Supervisor"
                  name="supervisor_id"
                  options={optionsSupervisores}
                  onChange={handleOnChange}
                  value={formData.supervisor_id}
                />
              </Col>
            </Row>

            <Atividades
              value={formData.atividades || undefined}
              onChange={handleAtividadesChange}
              error={errorAtividades}
            />
            <Beneficios
              value={formData.beneficios || undefined}
              onChange={handleBeneficiosChange}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              variant="tangerine"
              loading={loading || undefined}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </ModalFooter>
        </Form>
      )}
    </ModalContent>
  );
}
