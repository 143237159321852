import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useAluno } from "@/hooks/alunos";
import { format } from "date-fns";
import { FaPen, FaTrash } from "react-icons/fa";
import { Modal } from "@/components/Common";

import ModalEditCurso from "./ModalEditCurso";
import ModalAddCurso from "./ModalAddCurso";
import ModalDeleteCurso from "./ModalDeleteCurso";

import {
  CircleButton,
  Container,
  List,
  ListItem,
  MenuContainer,
  SmallCircleButton,
} from "./styles";

export default function CursosComplementares() {
  const { aluno } = useAluno();
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  function updateCurso(experiencia) {
    setDataToUpdate(experiencia);
    setModalEditOpen(true);
  }

  function handleDelete(id) {
    setIdToDelete(id);
    setModalDeleteOpen(true);
  }

  function renderLista() {
    return (
      <List>
        <>
          {aluno.cursos_complementares.map(c => {
            const dataInicio = format(new Date(c.data_inicio), "MM/yyyy");
            const dataTermino = c.data_termino
              ? format(new Date(c.data_termino), "MM/yyyy")
              : null;

            return (
              <ListItem key={c.id}>
                <h2>{c.curso_nome}</h2>
                <div className="datas">
                  <div>
                    <span>Início</span> {dataInicio}
                  </div>
                  {dataTermino && (
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  )}
                </div>
                {c.curso_descricao && (
                  <>
                    <h4>Descrição</h4>
                    <p className="descricao">{c.curso_descricao}</p>
                  </>
                )}
                <div className="actions">
                  <SmallCircleButton onClick={() => updateCurso(c)}>
                    <FaPen size={10} />
                  </SmallCircleButton>
                  <SmallCircleButton danger>
                    <FaTrash size={10} onClick={() => handleDelete(c.id)} />
                  </SmallCircleButton>
                </div>
              </ListItem>
            );
          })}
          <Modal
            open={modalDeleteOpen}
            onClose={() => setModalDeleteOpen(false)}
          >
            <ModalDeleteCurso
              id={idToDelete}
              onClose={() => setModalDeleteOpen(false)}
            />
          </Modal>
        </>
      </List>
    );
  }

  return (
    <Container>
      <div className="titulo">
        <h2>Cursos complementares: </h2>
        <CircleButton onClick={() => setModalAddOpen(true)}>
          <FiPlus />
        </CircleButton>
      </div>
      <div className="contente">{renderLista()}</div>
      <Modal open={modalEditOpen} onClose={() => setModalEditOpen(false)}>
        <ModalEditCurso
          data={dataToUpdate}
          onClose={() => setModalEditOpen(false)}
        />
      </Modal>
      <Modal open={modalAddOpen} onClose={() => setModalAddOpen(false)}>
        <ModalAddCurso onClose={() => setModalAddOpen(false)} />
      </Modal>
    </Container>
  );
}
