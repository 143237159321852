import React from "react";
import { PulseLoader } from "react-spinners";
import { FullPageLoaderContainer } from "./styles";

export default function FullPageLoader(props) {
  return (
    <FullPageLoaderContainer {...props}>
      <PulseLoader size={16} color="#b3b1b1" />
    </FullPageLoaderContainer>
  );
}
