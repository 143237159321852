import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { isAfter, isValid, parse } from "date-fns";
import * as Yup from "yup";
import { useAluno } from "@/hooks/alunos";
import {
  Form,
  Select,
  SelectSearch,
  DatePicker,
} from "@/components/Form";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import { ModalContent, ModalBody, ModalFooter } from "./styles";

export default function ModalEditFormacaoAtual({ onClose, data }) {
  const [optionsNiveis, setOptionsNiveis] = useState([]);
  const [queryCursos, setQueryCursos] = useState({ count: 20 });
  const [queryEscolas] = useState({ filters: "escola" });
  const [formData, setFormData] = useState({});
  const { data: user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const { aluno, fetchAluno } = useAluno();

  const schema = Yup.object().shape({
    nivel_id: Yup.number().typeError("Obrigatório"),
    curso_id: Yup.number().typeError("Obrigatório"),
    empresa_id: Yup.number()
      .typeError("Obrigatório")
      .required("Teste"),
    data_inicio: Yup.date()
      .typeError("Data inválida")
      .required("Obrigatório"),
    data_conclusao: Yup.date()
      .typeError("Data inválida")
      .test("data-conclusao-maior", "Data inválida", function(value) {
        return isValid(value) && isAfter(value, this.parent.data_inicio);
      })
      .required("Obrigatório"),
  });

  async function fetchNiveis() {
    const { data: response } = await api.get("utils/niveis");
    if (response.success) {
      setOptionsNiveis(() => {
        return response.data.map(nivel => {
          return { id: nivel.id, title: nivel.nome };
        });
      });
    }
  }

  async function updateFormacaoAtual(data) {
    setLoading(true);
    const endpoint = `alunos/${aluno.id}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    setLoading(false);
    if (response.success) {
      toastify("Formação salva com sucesso");
      fetchAluno(aluno.id);
      onClose();
    }
  }

  function handleSubmit(data, { resetForm }) {
    updateFormacaoAtual(data, resetForm);
  }

  function handleNivelChange(event) {
    const value = event.target.value;
    setFormData(state => {
      return { ...state, ...{ nivel_id: value } };
    });
    setQueryCursos(state => {
      return { ...state, ...{ nivel: value } };
    });
  }

  function handleEscolaChange(escola) {
    setFormData(state => {
      return { ...state, ...{ empresa_id: escola } };
    });
  }

  function mapOptionsEscolas(data) {
    return data.map(d => {
      return { id: d.id, title: d.nome_exibicao };
    });
  }

  function handleCursoChange(curso) {
    setFormData(state => {
      return { ...state, ...{ curso_id: curso } };
    });
  }

  function handleDateOnChange(name, value) {
    setFormData(state => ({
      ...state,
      [name]: value,
    }));
  }

  function mapOptionsCursos(data) {
    return data.map(d => {
      return { id: d.id, title: d.nome };
    });
  }

  const onDataChanged = useCallback(() => {
    if (!data || !data.hasOwnProperty("nivel_id")) return null;

    setFormData(state => ({
      ...state,
      ...data,
      data_inicio: parse(data.data_inicio, "yyyy-MM-dd", new Date()),
      data_conclusao: parse(data.data_conclusao, "yyyy-MM-dd", new Date()),
    }));
  }, [data, setFormData]);

  useEffect(() => {
    fetchNiveis();
  }, []);

  useEffect(() => {
    onDataChanged();
  }, [onDataChanged]);

  return (
    <ModalContent>
      {formData && (
        <Form onSubmit={handleSubmit} schema={schema} initialData={formData}>
          <ModalBody>
            <Row>
              <Col>
                <Select
                  label="Nível"
                  name="nivel_id"
                  options={optionsNiveis}
                  onChange={handleNivelChange}
                  value={formData.nivel_id}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <SelectSearch
                  label="Escola"
                  endpoint="empresas"
                  searchParam="nome"
                  query={queryEscolas}
                  mapOptions={mapOptionsEscolas}
                  onChange={handleEscolaChange}
                  name="empresa_id"
                  value={formData.empresa_id}
                />
              </Col>
              <Col>
                <SelectSearch
                  label="Curso"
                  endpoint="cursos"
                  searchParam="nome"
                  query={queryCursos}
                  mapOptions={mapOptionsCursos}
                  onChange={handleCursoChange}
                  name="curso_id"
                  value={formData.curso_id}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <DatePicker
                  type="mes"
                  label="Início"
                  name="data_inicio"
                  onChange={(value) => handleDateOnChange("data_inicio", value)}
                  value={formData.data_inicio}
                />
              </Col>
              <Col>
                <DatePicker
                  type="mes"
                  label="Previsão de conclusão"
                  name="data_conclusao"
                  onChange={(value) => handleDateOnChange("data_conclusao", value)}
                  value={formData.data_conclusao}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button variant="cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              variant="tangerine"
              loading={loading || undefined}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </ModalFooter>
        </Form>
      )}
    </ModalContent>
  );
}
