import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FaChevronCircleRight, FaSignInAlt } from "react-icons/fa";

import AuthActions from "@/store/ducks/auth";
import { Input } from "@/components/Form";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import { FranqueadoMenu } from "@/components/Franqueados";
import { Navbar } from "@/components/Blocks";
import Paginator, { Loading } from "@/components/Paginator";
import PlanilhaEmpresas from "@/components/Planilhas/Empresas";

import { telefone, cnpj, cpf } from "@/formatters";

import {
  Header,
  FiltroBasico,
  FilterButtonContainer,
  Totais,
  EmpresaListItem,
  ActionButton,
} from "./styles";

export default function Vagas({ history, match }) {
  const dispatch = useDispatch();
  const [empresas, setEmpresas] = useState([]);

  const [readyToPaginate, setReadyToPaginate] = useState(false);
  const [query, setQuery] = useState({ filters: "empresa,todas" });

  const [paginator, setPaginator] = useState(null);
  const [loadingEmpresas, setLoadingEmpresas] = useState(false);

  function handleOnFetchComplete(data) {
    setEmpresas(data.data);
    setPaginator(data.paginator);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, [name]: value };
    });
  }

  function renderHeader() {
    let titulo;

    const status = match.params.hasOwnProperty("status")
      ? match.params.status
      : null;

    switch (true) {
      case status === "comAvaliacoesPendentes":
        titulo = "Empresas com avaliações de contratos pendentes";
        break;
      default:
        titulo = "Todas as vagas";
    }

    return (
      <Header>
        <h4>Vagas</h4>
        <h2>{titulo}</h2>
        <div className="breadcrumbs">
          Vagas {">"} <span>{titulo}</span>
        </div>
      </Header>
    );
  }

  // function renderFiltroBasico() {
  //   const colNome = {
  //     lg: 0.7,
  //     md: 0.5,
  //     sm: 1,
  //   };

  //   const colButton = {
  //     lg: 0.3,
  //     md: 0.5,
  //     sm: 1,
  //   };

  //   return (
  //     <FiltroBasico>
  //       <Row hascols>
  //         <Col {...colNome}>
  //           <Input
  //             label="Nome da empresa"
  //             name="nome"
  //             placeholder="Busque pelo nome da empresa"
  //             onChange={handleOnChange}
  //           />
  //         </Col>
  //         <Col {...colButton}>
  //           <FilterButtonContainer>
  //             <PlanilhaEmpresas block params={query} />
  //           </FilterButtonContainer>
  //         </Col>
  //       </Row>
  //     </FiltroBasico>
  //   );
  // }

  function renderTotais() {
    if (loadingEmpresas) return <></>;
    if (paginator !== null) {
      const { total, pages } = paginator;
      if (total === 0) {
        return (
          <Totais>
            Sem vagas para exibir dentro dos critérios de busca
          </Totais>
        );
      }
      return (
        <Totais>
          Exibindo {empresas.length} de {total} empresas
        </Totais>
      );
    }
  }

  function handleAssumirControle(vagaId, empresaId) {
    const data = {
      entidade_tipo: "empresa",
      entidade_id: empresaId,
      vaga_id: vagaId,
    };
    dispatch(AuthActions.authAssumirControleRequest(data, history));
  }

  function handleVerPerfil(empresaId) {
    history.push(`/vaga/${empresaId}`);
  }

  function renderEmpresas() {
    return empresas.map(empresa => {
      return (
        <EmpresaListItem key={empresa.id}>
          <div className="coluna coluna-nome">
            <h4>{empresa.titulo}</h4>
            {/* {empresa.atuacao} */}
            <p>              
              {empresa.quantidade} Vagas
            </p>
          </div>
          <div className="coluna coluna-responsavel">
            <h4>Empresa</h4>
            {empresa.empresa}
          </div>
          <div className="coluna coluna-responsavel">
            <h4>Cidade</h4>
            {empresa.cidade}
          </div>
          <div className="coluna coluna-actions">
            <ActionButton onClick={() => handleAssumirControle(empresa.id, empresa.empresa_id)}>
              <FaSignInAlt color="#271946" size={14} />{" "}
              <span>Gerenciar Vaga</span>
            </ActionButton>
          </div>
        </EmpresaListItem>
      );
    });
  }

  const setQueryCB = useCallback(() => {
    const status = match.params.hasOwnProperty("status")
      ? `empresa,${match.params.status}`
      : "empresa,todas";
    setQuery(state => ({ ...state, ...(status && { filters: status }) }));
    setReadyToPaginate(true);
  }, [match.params, setQuery, setReadyToPaginate]);

  useEffect(() => {
    setQueryCB();
  }, [setQueryCB]);

  return (
    <Page>
      <Navbar type="interna" />
      <FranqueadoMenu />
      <DashboardContainer>
        {renderHeader()}
        {/* {renderFiltroBasico()} */}
        {renderTotais()}
        <Loading loading={loadingEmpresas}>
          {renderEmpresas()}
          {readyToPaginate && (
            <Paginator
              endpoint="franqueados/vagas"
              query={query}
              onFetchComplete={handleOnFetchComplete}
              onLoading={l => setLoadingEmpresas(l)}
            />
          )}
        </Loading>
      </DashboardContainer>
    </Page>
  );
}
