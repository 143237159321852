import React from "react";
import { Link } from "react-router-dom";
import { FaChevronCircleRight } from "react-icons/fa";
import { Card, CardBody, CardFooter } from "./styles";
import { moeda } from "@/formatters";

export default function VagaCard({ vaga }) {
  return (
    <Card>
      <CardBody>
        <p className="empresa">{vaga.empresa.nome_exibicao}</p>
        <p className="area">{vaga.empresa.atuacao}</p>
        <p className="funcao">
          Vaga Nº {vaga.id} - {vaga.titulo}
        </p>
        <p className="remuneracao">
          {moeda(Number(vaga.remuneracao))}
          {Number(vaga.pcd) === 1 && <span> (Exclusivo para PCD)</span>}
        </p>
        <p className="local">
          {vaga.cidade.nome}/{vaga.cidade.estado.sigla}
        </p>
        {vaga.cursos && vaga.cursos.length ? (
          <ul className="cursos">
            {vaga.cursos.map((curso, index) => {
              return (
                <li key={index}>
                  {curso.nome} ({curso.nivel.nome})
                </li>
              );
            })}
          </ul>
        ) : (
          <></>
        )}
      </CardBody>
      <CardFooter>
        <Link to={`/cadastro-estudante`}>
          <FaChevronCircleRight />
          Candidatar-se
        </Link>
        <span> <br /> </span>
        <Link to={`/vaga/${vaga.id}`}>
          <FaChevronCircleRight />
          Ver detalhes
        </Link>
      </CardFooter>
    </Card>
  );
}
