import styled from "styled-components";

export const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-top: 152px;
  margin-bottom: 32px;
  text-align: center;
`;

export const HeaderTitle = styled.h1`
  font-size: 58px;
  font-weight: bold;
  color: #000000;
`;

export const TermosContent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  padding: 2em;
`;

