import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "@/components/Common";
import DeleteAlunoActions from "@/store/ducks/alunos/delete";
import DeleteEmpresaActions from "@/store/ducks/empresas/delete";

import { Container, ModalContent, ModalBody } from "./styles";

function AdminPainel({ history, id, modo = "aluno" }) {

  const dispatch = useDispatch();
  const [showModalDelete, setShowModalDelete] = useState(false);

  const { loading: loadingDeleteAluno } = useSelector(state => state.deleteAluno);

  function handleDelete() {
    if (modo === "aluno") {
      dispatch(DeleteAlunoActions.deleteAlunoRequest(id, history));
    }
    if (["empresa", "escola"].includes(modo)) {
      dispatch(DeleteEmpresaActions.deleteEmpresaRequest(id, history, modo));
    }
  }

  return (
    <>
      <Container>
        <Button variant="red" onClick={() => setShowModalDelete(true)}>
          Remover
        </Button>
      </Container>
      <Modal open={showModalDelete} onClose={() => setShowModalDelete(false)}>
        <ModalContent>
          <ModalBody>
            <h1>Tem certeza de que deseja executar esta ação?</h1>
            <Button variant="magenta" block onClick={handleDelete} loading={loadingDeleteAluno || undefined}>
              Confirmar remoção
            </Button>
            <Button
              variant="cancel"
              block
              onClick={() => setShowModalDelete(false)}
            >
              Cancelar
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default withRouter(AdminPainel);
