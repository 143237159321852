import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import TceAddCoordenadorActions from "@/store/ducks/tces/add-coordenador";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";
import { Form, Select } from "@/components/Form";
import api, { makeOptions } from "@/services/api";

import { useTce } from "@/hooks/tces";

import { ModalContent, ModalFooter } from "./styles";

export default function ModalAddAssinante({ tceId, onClose, onComplete }) {
  const dispatch = useDispatch();
  const { data: user } = useSelector(state => state.auth);
  const [formData, setFormData] = useState({});
  const [optionsAssinantes, setOptionsAssinantes] = useState([]);
  const [assinantesLoading, setAssinantesLoading] = useState(false);
  const [assinanteId, setAssinanteId] = useState("");

  async function fetchCidades() {
    setAssinantesLoading(true);
    const { data: retorno } = await api.get(`/tces/482/assinantes-disponiveis`, makeOptions(user));
    if (retorno.success) {
      const cidades = retorno.data.map(c => {
        const nome = `${c.nome} ${c.sobrenome}`;
        return { id: c.id, title: nome };
      });
      setOptionsAssinantes(cidades);
      setAssinantesLoading(false);
    }
  }

  function handleAssinanteChange(event) {
    const value = event.target.value;
    setAssinanteId(value);
  }

  const loading = useSelector(state => state.tceAddCoordenador.loading);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("Obrigatório"),
  });

  async function handleSubmit(data) {
    function onSuccess() {
      if (onComplete) onComplete();
    }
    dispatch(TceAddCoordenadorActions.request(tceId, data, onSuccess));
  }

  useEffect(() => {
    fetchCidades()
  },[])

  return (
    <ModalContent>
      <h2>Selecione o assinante</h2>
      <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
        <Row hascols>
          <Col>
            <Select
              label="Assinante"
              name="assinante_id"
              options={optionsAssinantes}
              loading={assinantesLoading}
              value={assinanteId || ""}
              onChange={handleAssinanteChange}
            />
          </Col>
        </Row>
        <ModalFooter>
          <Button
            type="submit"
            block
            variant="tangerine"
            loading={loading || undefined}
          >
            Adicionar
          </Button>
          <Button
            type="button"
            block
            variant="cancel"
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
