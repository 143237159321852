import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { PulseLoader } from "react-spinners";
import { useThrottle } from "@/hooks";
import api, { makeOptions } from "@/services/api";

import {
  ButtonsContainer,
  Button,
  LoadingContainer,
  AnimationContainer,
} from "./styles";

function Paginator(
  {
    endpoint,
    onFetchComplete,
    onLoading,
    hidePaginator,
    query = {},
    throttle = 500,
  },
  ref
) {
  const { data: user } = useSelector(state => state.auth);
  const [paginator, setPaginator] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const tQuery = useThrottle(query, throttle);

  useImperativeHandle(ref, () => ({
    resetAndFetchData() {
      if (page !== 1) {
        setPage(1);
      } else {
        fetchData();
      }
    },
  }));

  function getQuery() {
    const mergedQuery = {
      ...(tQuery && tQuery),
      ...(page && { page }),
    };
    return queryString.stringify(mergedQuery);
  }

  async function fetchData() {
    setLoading(true);
    const url = `${endpoint}?${getQuery()}`;
    const { data: response } = await api.get(url, makeOptions(user));
    setLoading(false);
    if (response.success) {
      console.log(response.data);
      const { data, paginator } = response;
      setPaginator(paginator);
      if (onFetchComplete) {
        onFetchComplete({ data, paginator });
      }
    }
  }

  function renderButtons() {
    if (!paginator || hidePaginator) return <></>;

    const pages = parseInt(paginator.pages);
    const targetSize = 2; // quantos botões queremos a mais pra cadas lado
    const middle = Math.floor(targetSize / 2);
    const paginatorArray = [];
    if (pages > 1) {
      let inicio = page > middle ? page - middle : 1;
      inicio = inicio > pages - targetSize ? pages - targetSize : inicio;
      inicio = inicio < 1 ? 1 : inicio;
      let t = inicio + targetSize;
      t = pages < t ? pages : t;
      for (let i = inicio; i <= t; i++) paginatorArray.push(i);
    }

    return (
      <ButtonsContainer>
        {pages > 1 && (
          <>
            <Button onClick={() => setPage(1)}>Primeira</Button>
            <Button onClick={() => setPage(state => state - 1)}>Voltar</Button>
          </>
        )}
        {paginatorArray.map(i => {
          return (
            <Button
              className={page === i ? "active" : ""}
              key={i}
              onClick={() => setPage(i)}
            >
              {i}
            </Button>
          );
        })}
        {pages > 1 && (
          <>
            <Button onClick={() => setPage(state => state + 1)}>Avançar</Button>
            <Button onClick={() => setPage(paginator.pages)}>Última</Button>
          </>
        )}
      </ButtonsContainer>
    );
  }

  useEffect(() => {
    fetchData();
  }, [tQuery, page]);

  useEffect(() => {
    if (!query.hasOwnProperty("page")) {
      setPage(1);
    }
  }, [query]);

  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading]);

  return <>{renderButtons()}</>;
}

function Loading({ children, loading }) {
  return (
    <LoadingContainer>
      <>
        {loading && (
          <AnimationContainer>
            <PulseLoader color={"#FF987F"} size={12} />
          </AnimationContainer>
        )}
        {children}
      </>
    </LoadingContainer>
  );
}

export default forwardRef(Paginator);
export { Loading };
