import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

export const Logo = styled.img.attrs({
  className: "logo-image"
})`
  width: 185px;
  height: auto;
`;

export const Text = styled.div`
  margin-left: 5px;
  .main {
    color: ${props =>
      ["login", "interna"].includes(props.type) ? "#FFF" : "#000"};
    font-size: ${props =>
      ["home", "login"].includes(props.type) ? "22px" : "18px"};
    font-weight: bold;
    margin: 0;
    margin-bottom: -5px;
  }
  .sub {
    color: #989ea7;
    font-size: ${props =>
      ["home", "login"].includes(props.type) ? "12px" : "10px"};
    font-weight: bold;
    text-transform: uppercase;
  }
`;
