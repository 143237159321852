import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isAfter, isValid, parse } from "date-fns";
import * as Yup from "yup";
import { useAluno } from "@/hooks/alunos";
import { Form, Input } from "@/components/Form";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";

import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import { ModalContent, ModalBody, ModalFooter } from "./styles";

export default function ModalEditExperiencia({ onClose, data }) {
  const [formData, setFormData] = useState(null);
  const { data: user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const { aluno, fetchAluno } = useAluno();

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const schema = Yup.object().shape({
    empresa_nome: Yup.string().required("Obrigatório"),
    cargo: Yup.string().required("Obrigatório"),
    atividades: Yup.mixed(),
    data_inicio: Yup.date()
      .typeError("Data inválida")
      .required("Obrigatório"),
    data_termino: Yup.mixed()
      .test("data-conclusao-maior", "Data inválida", function(value) {
        if (value === "") return true;
        const termino = parse(`${value}`, "yyyy-MM-dd", new Date());
        return isValid(termino) && isAfter(termino, this.parent.data_inicio);
      })
      .notRequired(),
  });

  async function updateExperiencia(data) {
    setLoading(true);
    const endpoint = `alunos/curriculos/experiencias-profissionais/${formData.id}`;
    const alunoId = aluno.id;
    data.aluno_id = alunoId;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    setLoading(false);
    if (response.success) {
      toastify("Experiência profissional salva com sucesso");
      fetchAluno(alunoId);
      onClose();
    }
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  function handleSubmit(data, { resetForm }) {
    updateExperiencia(data, resetForm);
  }

  return (
    <ModalContent>
      {formData && (
        <Form onSubmit={handleSubmit} schema={schema} initialData={formData}>
          <ModalBody>
            <Row>
              <Col>
                <Input
                  label="Nome da empresa"
                  name="empresa_nome"
                  value={formData.empresa_nome || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  type="date"
                  max="2099-01-01"
                  label="Data de início"
                  name="data_inicio"
                  value={formData.data_inicio || ""}
                  onChange={handleOnChange}
                />
              </Col>
              <Col>
                <Input
                  type="date"
                  max="2099-01-01"
                  label="Data de término"
                  name="data_termino"
                  value={formData.data_termino || ""}
                  onChange={handleOnChange}
                />
                <small>Deixe em branco caso seja a ocupação atual</small>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Cargo"
                  name="cargo"
                  value={formData.cargo || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  multiline
                  showLength
                  maxLength={500}
                  label="Descrição das atividades"
                  name="atividades"
                  value={formData.atividades || ""}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button variant="cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              variant="tangerine"
              loading={loading || undefined}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </ModalFooter>
        </Form>
      )}
    </ModalContent>
  );
}
