import styled from "styled-components";

export const ModalContent = styled.div`
  text-align: center;
  max-width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
`;

export const ModalHeader = styled.div`
  height: 40px;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  h4 {
    font-size: 16px;
  }
  .actions {
    margin-left: auto;
    button {
      background: none;
      text-transform: uppercase;
      font-weight: 600;
      color: #423B8D;
    }
  }
`;
