import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import DeleteAlunoActions from "@/store/ducks/alunos/delete";

const getAuthData = state => state.auth.data;

export function* remove(action) {
  try {
    const { id, history } = action;
    const authData = yield select(getAuthData);
    const { data: response } = yield call(api.delete, `alunos/${id}`, makeOptions(authData));
    if (response.success) {
      history.push("/alunos/todos");
      toastify("Estudante removido com sucesso");
      yield put(DeleteAlunoActions.deleteAlunoSuccess());
    } else {
      toastify(response.message, "error");
      yield put(DeleteAlunoActions.deleteAlunoFailure(response.message));
    }
  } catch (error) {
    toastify("Erro ao tentar deletar estudante", "error");
    yield put(DeleteAlunoActions.deleteAlunoFailure("Erro ao tentar deletar estudante"));
  }
}
