import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function GuestRoute({ component: Component, auth, ...rest }) {

  function renderRoute() {
    const { authenticated, data } = auth;
    if (!authenticated) {
      return <Route {...rest} render={props => <Component {...props} />} />;
    } else {
      const pathname =
        data.tipo === "administrador" ? "/admin/indicadores/totais" : "/perfil";
      return (
        <Route {...rest} render={props => <Redirect to={{ pathname }} />} />
      );
    }
  }

  return (
    <>{renderRoute()}</>
  );
}
