import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  createSupervisorRequest: ["data", "resetForm"],
  createSupervisorSuccess: ["data"],
  createSupervisorFailure: ["error"],
  createSupervisorReset: [],
});

export const createSupervisorTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  loading: false,
  error: null,
};

// Reducers
export const createSupervisorRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const createSupervisorSuccess = state => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const createSupervisorFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const createSupervisorReset = state => {
  return INITIAL_STATE;
}

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_SUPERVISOR_REQUEST]: createSupervisorRequest,
  [Types.CREATE_SUPERVISOR_SUCCESS]: createSupervisorSuccess,
  [Types.CREATE_SUPERVISOR_FAILURE]: createSupervisorFailure,
  [Types.CREATE_SUPERVISOR_RESET]: createSupervisorReset,
});
