import React, { useState } from "react";
import { useAluno } from "@/hooks/alunos";
import { FaPen } from "react-icons/fa";
import { Modal } from "@/components/Common";
import ModalEditSobre from "./ModalEditSobre";
import { Container, CircleButton } from "./styles";

export default function Endereco() {
  const { aluno } = useAluno();
  const [modalEditOpen, setModalEditOpen] = useState(false);

  return (
    <Container>
      <h4>Sobre:</h4>
      <p>
        País de origem: {aluno.pais.nome}
        <br />
        Sexo: {aluno.sexo === "F" ? "FEMININO" : "MASCULINO"}
        <br />
        Portador de deficiência? <br />
        {aluno.pcd !== null ? `Código PCD: ${aluno.pcd}` : "NÃO"}
      </p>
      <div className="actions">
        <CircleButton onClick={() => setModalEditOpen(true)}>
          <FaPen size={10} />
        </CircleButton>
      </div>
      <Modal open={modalEditOpen} onClose={() => setModalEditOpen(false)}>
        <ModalEditSobre onClose={() => setModalEditOpen(false)} />
      </Modal>
    </Container>
  );
}
