import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  updateEmpresaRequest: ["id", "data"],
  updateEmpresaSuccess: ["data"],
  updateEmpresaFailure: ["error"],
  updateEmpresaReset: [],
});

export const updateEmpresaTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  loading: false,
  error: null,
};

// Reducers
export const updateEmpresaRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const updateEmpresaSuccess = (state, action) => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const updateEmpresaFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const updateEmpresaReset = state => {
  return INITIAL_STATE;
}

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_EMPRESA_REQUEST]: updateEmpresaRequest,
  [Types.UPDATE_EMPRESA_SUCCESS]: updateEmpresaSuccess,
  [Types.UPDATE_EMPRESA_FAILURE]: updateEmpresaFailure,
  [Types.UPDATE_EMPRESA_RESET]: updateEmpresaReset,
});
