import { useContext } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { TceContext } from "@/context/tces/tce";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

const useTce = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(TceContext);

  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function fetchTce(tceId) {
    _setLoading(true);
    const endpoint = `tces/${tceId}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  async function aceitarTce(tceId, data = {}, onComplete) {
    _setLoading(true);
    const endpoint = `tces/aceitar/${tceId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
      toastify("TCE aceito com sucesso");
      if (onComplete) onComplete();
    }
  }

  async function recusarTce(tceId, data = {}, onComplete) {
    _setLoading(true);
    const endpoint = `tces/recusar/${tceId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
      toastify("TCE recusado com sucesso");
      if (onComplete) onComplete();
    }
  }

  async function addCoordenador(tceId, data = {}, onComplete) {
    _setLoading(true);
    const endpoint = `tces/coordenador/${tceId}`;
    const { data: response } = await api.post(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
      if (onComplete) onComplete();
      toastify("Coodenador adicionado com sucesso");
    }
  }

  async function coordenadorReenviarEmail(tceId, data = {}) {
    _setLoading(true);
    const endpoint = `tces/coordenador-reenviar-email/${tceId}`;
    const { data: response } = await api.post(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      toastify("E-mail enviado com sucesso");
    }
  }

  async function assinanteReenviarEmail(tceId, data = {}) {
    _setLoading(true);
    const endpoint = `tces/assinante-reenviar-email/${tceId}`;
    const { data: response } = await api.post(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      toastify("E-mail enviado com sucesso");
    }
  }

  async function updateTce(tceId, data) {
    _setLoading(true);
    const endpoint = `tces/${tceId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  async function solicitarDesligamento(tceId, data = {}) {
    _setLoading(true);
    const endpoint = `tces/solicitar-desligamento/${tceId}`;
    const { data: response } = await api.post(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  async function reprovarDesligamento(solicitacaoId) {
    _setLoading(true);
    const endpoint = `tces/reprovar-desligamento/${solicitacaoId}`;
    const { data: response } = await api.put(endpoint, {}, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      toastify("Solicitação de desligamento reprovada com sucesso");
      _setData(response.data);
    }
  }

  async function aprovarDesligamento(solicitacaoId) {
    _setLoading(true);
    const endpoint = `tces/aprovar-desligamento/${solicitacaoId}`;
    const { data: response } = await api.put(endpoint, {}, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      toastify("Solicitação de desligamento aprovada com sucesso");
      _setData(response.data);
    }
  }

  return {
    fetchTce,
    updateTce,
    aceitarTce,
    recusarTce,
    addCoordenador,
    coordenadorReenviarEmail,
    assinanteReenviarEmail,
    solicitarDesligamento,
    reprovarDesligamento,
    aprovarDesligamento,
    tce: state.data,
    loading: state.loading,
  };
};

export { useTce };
