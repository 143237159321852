import styled from "styled-components";

export const ListContainer = styled.div`
  margin-top: 32px;
  border-top: 1px solid #b9b7cc;
  h3 {
    font-size: 18px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  div.grid-col {
    margin-bottom: 0;
  }
`;

export const ButtonDelete = styled.button.attrs({
  type: "button",
})`
  border: none;
  background-color: transparent;
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  transition: 300ms ease;
  color: #f78764;
  &:hover {
    opacity: 0.7;
  }
`;

export const ButtonAdd = styled.button.attrs({
  type: "button",
})`
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 18px;
  color: #2b0c75;
`;
