import styled from "styled-components";

import bgImage from "@/assets/images/vagas-hero.png";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media screen and (min-width: 1400px) {
    height: 750px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  @media screen and (min-width: 1400px) {
    padding: 0 96px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;

  @media screen and (max-width: 1399px) {
    width: 100%;
    padding: 96px 40px;
  }
`;

export const Block = styled.div`
  @media screen and (max-width: 1399px) {
    width: 100%;
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: 58px;
  line-height: 60px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 24px;
  @media screen and (max-width: 1399px) {
    margin-top: 96px;
  }
`;

export const Intro = styled.p`
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 24px;
`;

export const Links = styled.p`
  margin-top: 88px;
  font-size: 16px;
  line-height: 24px;
  a {
    color: #605f6a;
    font-weight: 600;
  }
`;

export const HeroSideImage = styled.img`
  position: absolute;
  top: 0;
  right: -35%;
  z-index: 98;
  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

export const HeroMobileImage = styled.div`
  width: 100%;
  height: 300px;
  display: none;
  background-image: url(${bgImage});
  background-position: center center;
  background-size: cover;
  @media screen and (max-width: 1399px) {
    display: block;
  }
`;
