import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader, Button } from "@/components/Common";

import { TceProvider } from "@/context/tces/tce";
import { AlunoProvider } from "@/context/alunos/aluno";
import { useAluno } from "@/hooks/alunos";
import { useTce } from "@/hooks/tces";

import { Sidebar, SubMenu } from "@/components/Aluno";

import TCEContent from "@/components/TCE";

import {
  PaineisContainer,
  Content,
  PainelPrincipal,
  MenuInterno,
  VoltarContainer,
} from "./styles";

function _TCE({ history, match }) {
  const { data: user } = useSelector(state => state.auth);
  const { fetchTce, loading: loadingTce, tce } = useTce();
  const { fetchAluno, aluno } = useAluno();

  useEffect(() => {
    const { id: tceId } = match.params;
    fetchTce(tceId);
    fetchAluno(user.perfil_id);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      {(!tce || !aluno) && <FullPageLoader />}
      {tce && aluno && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                <SubMenu />
              </MenuInterno>
              <PainelPrincipal>
                <VoltarContainer>
                  <Button
                    variant="jeans"
                    icon="arrow-left"
                    onClick={() => {
                      history.go(-1);
                    }}
                  >
                    {" "}
                    Voltar
                  </Button>
                </VoltarContainer>
                <TCEContent tce={tce} />
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function TCE({ history, match }) {
  return (
    <AlunoProvider>
      <TceProvider>
        <_TCE history={history} match={match} />
      </TceProvider>
    </AlunoProvider>
  );
}
