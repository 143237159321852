import styled from "styled-components";

export const ModalOverlay = styled.div.attrs({
  className: "modal-overlay"
})`
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  padding: ${({padding}) => {
    if (padding) return padding;
    return "2rem";
  }};
  pointer-events: none;
  display: ${props => ({ open }) => {
    return open !== undefined ? "initial" : "none";
  }};
`;

export const ModalWrapper = styled.div.attrs({
  className:"modal-wrapper"
})`
  > * {
    pointer-events: all;
  }
`;
