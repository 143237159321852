import styled from "styled-components";
import * as S from "../styles";

export * from "../styles";

export const Container = styled(S.Container)`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #ccc;
`;

export const Value = styled(S.Value)`
  color: #F78764;
`;


export const Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 32px;
`;
