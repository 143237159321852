import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, MyLink } from "./styles";

export default function Empresa({ type }) {

  const { data: user } = useSelector(state => state.auth);

  return (
    <Container>
      <MyLink active={type === "vagas"}>
        <Link to="/vagas">Vagas</Link>
      </MyLink>
      <MyLink active={type === "empresa"}>
        <Link to="/perfil">Empresa</Link>
      </MyLink>
      <MyLink active={type ===  "entrevistas"}>
        <Link to="/entrevistas">Entrevistas</Link>
      </MyLink>
      { user.tipo === "funcionario" && (
        <MyLink active={type === "supervisor"}>
          <Link to="/supervisores">Supervisores</Link>
        </MyLink>
      )}
    </Container>
  );
}
