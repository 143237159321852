import styled from "styled-components";

export const AdminMenuWrapper = styled.div`
  margin-top: 50px;
  background-color: #ffffff;
`;

export const MainMenu = styled.div`
  display: flex;
  height: 80px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;



export const MenuHandler = styled.div`
  font-size: 16px;
  color: #605f6a;
  font-weight: 600;
  position: relative;
`;

export const MenuLabel = styled.div`
  padding: 0 20px;
  cursor: pointer;
`;

export const ActiveIndicator = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent #279cd9 transparent;
  position: absolute;
  bottom: -31px;
  left: 50%;
  margin-left: -6px;
`;

export const SubMenu = styled.div`
  background-color: #279cd9;
  color: #ffffff;
  font-size: 16px;
  position: absolute;
  width: 100%;
  z-index: 2;
`;

export const CloseButton = styled.button`
  color: #FFFFFF;
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px;
`;

export const SubMenuBackdrop = styled.div.attrs({
  className: "backdrop",
})`
  background-color: #000;
  opacity: 0.2;
  position: absolute;
  width: 100vw;
  height: ${({ height }) => {
    return height || "100px";
  }};
`;

export const SubMenuSections = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Section = styled.div`
  padding: 60px 20px;
`;

export const SectionTitle = styled.h4`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const SectionOption = styled.li`
  cursor: pointer;
  margin-bottom: 12px;
  &:hover {
    opacity: 0.8;
  }
`;
