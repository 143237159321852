import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";

import { Navbar, Footer } from "@/components/Blocks";
import { Page } from "@/components/Grid";
import { Button } from "@/components/Common";
import Share from "@/components/Share";
import { VagaProvider } from "@/context/vagas/vaga";
import { useVaga } from "@/hooks/vagas";
import { moeda } from "@/formatters";

import {
  Hero,
  Main,
  PageLoading,
  ContentBox,
  ContentBoxRed,
  VagaContainer,
  CadastroContainer,
  Header,
  EmpresaImagem,
  VagaTitulo,
  ShareBox,
} from "./styles";

function _Vaga({ match }) {
  const { vaga, fetchVagaAsGuest, loading: loadingVaga } = useVaga();
  const { authenticated } = useSelector(state => state.auth);

  function renderHeader() {
    const { empresa, titulo, id } = vaga;
    return (
      <Header>
        <EmpresaImagem src={empresa.imagem} />
        <VagaTitulo>
          <h2>Vaga Nº {id} - {titulo}</h2>
          <h2 className="empresa">{empresa.nome_exibicao}</h2>
          <h3 className="area">{empresa.atuacao}</h3>
        </VagaTitulo>
      </Header>
    );
  }

  function renderEmpresa() {
    return (
      <ContentBox>
        <h4>Sobre a empresa:</h4>
        <p>{vaga.empresa.apresentacao}</p>
      </ContentBox>
    );
  }

  function renderDescricao() {
    return (
      <ContentBox>
        <h4>Descrição:</h4>
        <p>{vaga.descricao}</p>
      </ContentBox>
    );
  }

  function renderExclusivoPCD() {
    const { pcd } = vaga;

    if (Number(pcd) === 1) {
      return (
        <ContentBoxRed>
          <h4>Esta vaga é exclusiva para PCD</h4>
        </ContentBoxRed>
      );
    }
  }

  function renderCargaHoraria() {
    const { carga_horaria_semanal } = vaga;
    return (
      <ContentBox>
        <h4>Carga horária semanal</h4>
        <p>{carga_horaria_semanal.horas} horas</p>
      </ContentBox>
    );
  }

  function renderRemuneracao() {
    const { remuneracao } = vaga;
    return (
      <ContentBox>
        <h4>Remuneração mensal</h4>
        <p>{moeda(Number(remuneracao))}</p>
      </ContentBox>
    );
  }

  function renderAtividades() {
    const { atividades } = vaga;
    const atividadesList = atividades.length ? (
      atividades.map((atividade, index) => {
        return <li key={index}>{atividade}</li>;
      })
    ) : (
      <li>Não definidas</li>
    );
    return (
      <ContentBox>
        <h4>Atividades</h4>
        <ul>{atividadesList}</ul>
      </ContentBox>
    );
  }

  function renderCursos() {
    const { cursos } = vaga;
    const cursosList = cursos.length ? (
      cursos.map((curso, index) => {
        return (
          <li key={index}>
            {curso.nome} ({curso.nivel.nome})
          </li>
        );
      })
    ) : (
      <li>Não definidos</li>
    );
    return (
      <ContentBox>
        <h4>Cursos</h4>
        <ul>{cursosList}</ul>
      </ContentBox>
    );
  }

  function renderBeneficios() {
    const { beneficios } = vaga;
    const beneficiosList = beneficios.length ? (
      beneficios.map((beneficio, index) => {
        const beneficioValor =
          beneficio.valor > 0 ? (
            <span className="beneficio-valor"> {moeda(beneficio.valor)}</span>
          ) : (
            <></>
          );

        return (
          <li key={index}>
            {beneficio.descricao}
            {beneficioValor}
          </li>
        );
      })
    ) : (
      <li>Não definidos</li>
    );

    return (
      <ContentBox>
        <h4>Benefícios</h4>
        <ul>{beneficiosList}</ul>
      </ContentBox>
    );
  }

  useEffect(() => {
    const { id } = match.params;
    fetchVagaAsGuest(id);
  }, [match]);

  return (
    <Page>
      <Navbar type="login" />
      <Hero>
        <h2>Vaga de estágio</h2>
      </Hero>
      {!vaga && (
        <PageLoading>
          <PulseLoader size={16} color="#b3b1b1" />
        </PageLoading>
      )}
      {vaga && (
        <Main>
          <VagaContainer>
            {renderHeader()}
            {vaga.cancelamento_data !== null ? (
              <h2>Esta vaga foi cancelada</h2>
            ) : (
              <>
                {renderEmpresa()}
                {renderDescricao()}
                {renderExclusivoPCD()}
                {renderCargaHoraria()}
                {renderRemuneracao()}
                {renderCursos()}
                {renderAtividades()}
                {renderBeneficios()}
                <ShareBox>
                  <Share url={`vaga/${match.params.id}`}>
                    <Button type="button">Compartilhar</Button>
                  </Share>
                </ShareBox>
              </>
            )}
          </VagaContainer>
          {!authenticated && vaga.cancelamento_data !== null && (
            <CadastroContainer>
              <h2>Cadastre-se para se candidatar a esta vaga</h2>
              <Button to="/cadastre-se" variant="cyan" block>
                Cadastre-se
              </Button>
            </CadastroContainer>
          )}
        </Main>
      )}
      <Footer />
    </Page>
  );
}

export default function Vaga(props) {
  return (
    <VagaProvider>
      <_Vaga {...props} />
    </VagaProvider>
  );
}
