import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AvaliacaoProvider } from "@/context/avaliacoes/avaliacao";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { useAvaliacao } from "@/hooks/avaliacoes";
import { useEmpresa } from "@/hooks/empresas";

import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";

import ResponderAvaliacao from "@/components/Empresa/Avaliacoes/ResponderAvaliacao";

import { Sidebar, SubMenu as SubmenuEscola } from "@/components/Escola";
import { SubMenu as SubmenuEmpresa } from "@/components/Empresa";

import {
  PaineisContainer,
  Content,
  PainelPrincipal,
  MenuInterno,
} from "./styles";

function _Avaliacao({ match }) {
  const { data: user } = useSelector(state => state.auth);

  const { fetchAvaliacao, avaliacao } = useAvaliacao();
  const { fetchEmpresa, empresa } = useEmpresa();

  useEffect(() => {
    const { id } = match.params;
    fetchEmpresa(user.empresa.id);
    fetchAvaliacao(id);
  }, [match]);

  return (
    <Page>
      <Navbar type="interna" />
      {!empresa && <FullPageLoader />}
      {empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                {(empresa.escola && <SubmenuEscola type="empresa" />) || (
                  <SubmenuEmpresa type="empresa" />
                )}
              </MenuInterno>
              <PainelPrincipal>
                {avaliacao && avaliacao.finalizada_em === null && (
                  <ResponderAvaliacao avaliacao={avaliacao} />
                )}
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function Avaliacao(props) {
  return (
    <EmpresaProvider>
      <AvaliacaoProvider>
        <_Avaliacao {...props} />
      </AvaliacaoProvider>
    </EmpresaProvider>
  );
}
