import styled from "styled-components";
import { Card } from "@/components/Common";

export const Container = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;
  margin-bottom: 32px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  .coluna {
    &-titulo {
      flex: 1 1 100%;
    }
    &-actions {
      margin-left: auto;
    }
  }
`;

export const Observacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  padding: 24px;
  .coluna {
    &-conteudo {
      flex: 1 1 100%;
      h4 {
        margin-bottom: 12px;
      }
    }
    &-actions {
      margin-left: auto;
      button {
        background: transparent;
      }
    }
  }
`;

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const Label = styled.h6`
  font-size: 16px;
  font-weight: 600;
  color: #8b8a95;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 4px;
  /* o form interno me bugou legal
    setando uma altura máxima pra ele
   */
  form {
    max-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
  }
`;

export const ModalBody = styled.div`
  overflow-y: scroll;
  width: 100%;
  padding: 40px;
  h2 {
    color: #271946;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
  }
`;

export const ModalFooter = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #b9b7cc;
  > * {
    margin-left: 20px;
  }
`;

export const ModalDeleteContent = styled(ModalContent)`
  max-width: 480px;
`;

export const ModalDeleteBody = styled(ModalBody)`
  overflow-y: initial;
  h1 {
    margin-bottom: 32px;
  }
`;
