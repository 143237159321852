import React, { useState } from "react";

const AvaliacaoContext = React.createContext([{}, () => {}]);

const AvaliacaoProvider = props => {
  const [state, setState] = useState({
    data: null,
    loading: false
  });
  return (
    <AvaliacaoContext.Provider value={[state, setState]}>
      {props.children}
    </AvaliacaoContext.Provider>
  )
}

export { AvaliacaoContext, AvaliacaoProvider };
