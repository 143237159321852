import styled from "styled-components";

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;
  height: 90px;
  border-bottom: 1px solid #b9b7cc;

  .coluna span {
    font-weight: 600;
  }

  .coluna {
    &-nome {
      flex: 0 0 300px;
      h5 {
        font-size: 16px;
        color: #605f6a;
        font-weight: 500;
        margin-bottom: 8px;
      }
      h4 {
        color: #423b8d;
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: 600;
      }
    }
    &-status, &-data {
      h4 {
        font-weight: 700;
        font-size: 14px;
        color: #f78764;
        margin-bottom: 8px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
      h5 {
        font-size: 16px;
        color: #271946;
        font-weight: 500;
      }
    }
    &-data {
      flex: 0 0 150px;
    }
    &-actions {
      margin-left: auto;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    padding: 20px 0;
    .coluna {
      &-nome, &-status, &-actions {
        flex: 1 0 100%;
        padding: 8px 0;
      }
      &-actions {
        margin-left: 0px;
        align-self: stretch;
        justify-content: space-between;
      }
    }
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0 8px;
  height: 48px;
  transition: 300ms ease;

  span {
    color: #279cd9;
    font-size: 14px;
    padding-left: 6px;
    text-transform: uppercase;
    font-weight: 600;
  }

  &:hover {
    transform: scale(0.9);
  }
`;

export const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0 8px;
  height: 48px;
  transition: 300ms ease;
  cursor: pointer;
  text-decoration: none;

  span {
    color: #279cd9;
    font-size: 14px;
    padding-left: 6px;
    text-transform: uppercase;
    font-weight: 600;
  }

  &:hover {
    transform: scale(0.9);
  }
`;

export const Label = styled.h6`
  font-size: 16px;
  font-weight: 600;
  color: #8b8a95;
`;
