import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 4px;

  /* o form interno me bugou legal
    setando uma altura máxima pra ele
   */
  form {
    max-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
  }
`;

export const ModalBody = styled.div`
  overflow-y: auto;
  width: 100%;
  padding: 40px;

  h2 {
    color: #271946;
    font-size: 24px;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

export const ModalFooter = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #b9b7cc;
  > * {
    margin-left: 20px;
  }
`;
