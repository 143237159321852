import { toast } from "react-toastify";

export default function Toastify(message, type = "success") {
  const position = toast.POSITION.TOP_RIGHT;

  switch (type) {
    case "error":
      toast.error(message, {
        position,
        className: "toasterror",
        bodyClassName: "bodytoast",
      });
      break;
    default:
      toast.success(message, {
        position,
        className: "toastsuccess",
        bodyClassName: "bodytoast",
      });
  }
}
