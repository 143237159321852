import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }
  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background:#F5F5F5;
    font-family: 'Source Sans Pro', sans-serif;
    color:#605F6A;
    font-weight: 400;
    overflow-x: hidden;
  }
  body.modal-open {
    overflow-y: hidden;
  }
  h1, h2 {
    color: #000;
    font-weight: 700;
  }
  button {
    font-family: 'Source Sans Pro', sans-serif;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
  }
  .toastsuccess {
    background:#279CD9 !important;
  }
  .toasterror {
    background:#EF3257 !important;
  }
  .bodytoast {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 0 16px;
  }
  input {
    font-family: 'Source Sans Pro', sans-serif;
  }
  ul {
    list-style: none;
  }

  /* React-tabs */
  .react-tabs {
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid #ccc;
    @media screen and (max-width: 799px) {
      padding-bottom: 16px;
    }
  }

  .react-tabs__tab {
    color: #8b8a95;
    font-weight: 600;
    padding: 20px 0;
    list-style: none;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;

    &--selected {
      color: #279cd9;
      transition: all 0.3s ease;
    }

    div.bubble {
      margin-left: 10px;
    }

    @media screen and (min-width: 800px){
      margin-bottom: -2px;
      border-bottom: 3px solid transparent;
      &:not(:last-child) {
        margin-right: 40px;
      }
      &--selected {
        border-bottom: 3px solid #279cd9;
      }
    }

    @media screen and (max-width: 799px) {
      display: block;
      padding: 12px 0 12px 12px;
      &--selected {
        border-left: 3px solid #279cd9;
      }
    }

  }

  .react-tabs__tab-panel {
    margin-top: 35px;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`;
