import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 264px;
  height: 100%;
  max-height: 72px;
  background: transparent;
  margin-bottom: 16px;

  &:hover {
    div.avatar {
      transform: scale(0.9);
    }
    h4 {
      color: #279cd9;
    }
    small {
      opacity: 0.5;
    }
  }
`;

export const Avatar = styled.div.attrs({
  className: "avatar",
})`
  width: 72px;
  height: 72px;
  flex-shrink: 0;
  border-radius: 36px;
  margin-right: 8px;
  transition: 300ms ease;
  background-color: #CCC;
  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Nome = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: #423b8d;
  transition: 300ms ease;
`;

export const Data = styled.small`
  font-size: 15px;
  font-weight: 400;
  color: #605f6a;
  transition: 300ms ease;

  span {
    font-weight: 600;
    color: #000;
  }
`;
