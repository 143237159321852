import styled from "styled-components";
import { Card } from "@/components/Common";
import bgImage from "@/assets/images/vagas-hero.png";

export const Hero = styled.div`
  height: 205px;
  background-color: #854c76;
  margin: 85px auto 0 auto;
  max-width: 1440px;
  width: 100%;
  background-image: url(${bgImage});
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
  }
`;

export const Main = styled.main`
  margin: 0 auto;
  padding: 55px 0;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
`;

export const PageLoading = styled.div`
  height: calc(100vh - 290px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VagaContainer = styled(Card).attrs({
  flex: "flex",
  className: "vaga-container",
})`
  flex: 1 1 100%;
  margin-right: 40px;
`;

export const Header = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 38px;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 40px;
`;

export const EmpresaImagem = styled.img`
  src: url(${props => props.src});
  width: 130px;
  height: 130px;
  border: 1px solid #d0d0d0;
  margin-right: 30px;
`;

export const VagaTitulo = styled.div`
  h2 {
    font-size: 26px;
    color: #2e2d38;
    font-weight: 400;
    &.empresa {
      color: #db4c81;
    }
  }
  h3.area {
    font-weight: 400;
  }
`;

export const ContentBox = styled.div`
  h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: #279cd9;
    letter-spacing: 2px;
    margin-bottom: 16px;
    font-weight: 700;
    &.empresa {
      color: #271946;
    }
  }
  margin-bottom: 32px;

  ul {
    font-size: 16px;
    line-height: 24px;
    list-style: disc;
    padding-left: 16px;

    span.beneficio-valor {
      font-weight: 600;
    }
  }
`;

export const ContentBoxRed = styled(ContentBox)`
  h4 {
    color: #ef3257;
  }
`;

export const ShareBox = styled.div`
  border-top: 1px solid #d6d6d6;
  margin-top: 40px;
  padding-top: 40px;
`;

export const CadastroContainer = styled(Card).attrs({
  flex: "flex",
})`
  flex: 1 0 245px;
  padding: 24px;
  h2 {
    font-size: 22px;
    margin-bottom: 12px;
  }
`;
