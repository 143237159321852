import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const VoltarContainer = styled.div`
  margin-bottom: 30px;
`;

export const TopoInfo = styled.div`
  margin-bottom: 48px;

  @media screen and (max-width: 1100px) {
    margin-bottom: 12px;
  }

  div.info {
    ul li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 35px;
      }
      svg {
        color: #279cd9;
        margin-right: 9px;
        font-size: 16px;
      }
    }
  }
`;

export const VagaControllerWrapper = styled.div`
  margin-bottom: 36px;
`;

export const VagaController = styled.div`
  display: flex;
  flex-direction: row;

  div.coluna {
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 1101px) {
      .button-container:not(:last-child) {
        margin-right: 12px;
      }
    }
    &-1 {
      padding-right: 22px;
      border-right: 1px solid #9491b4;
    }
    &-2 {
      padding-left: 22px;
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    div.coluna {
      flex-direction: column;
      &-1 {
        padding-right: 0px;
        border-right: none;
      }
      &-2 {
        padding-left: 0px;
      }
      .button-container {
        display: block;
        margin-right: 0px;
        margin-bottom: 12px;
        a,
        button {
          display: block;
          width: 100%;
        }
      }
    }
  }
`;

export const CantAddEntrevistaMessage = styled.div`
  margin: 16px 0;
`;

export const CantContratarMessage = styled.div`
  margin: 16px 0;
`;

export const SectionContainer = styled.div.attrs({
  className: "section-container",
})`
  margin-bottom: 80px;
  div.titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    border-bottom: 1px solid #b9b7cc;
    padding-bottom: 18px;
    h2 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  div.dados-pessoais-box {
    h4 {
      color: #f78764;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
    }
  }
`;

export const Avatar = styled.div`
  flex: 0 0 110px;
  width: 110px;
  height: 110px;
  border-radius: 55px;
  background-color: #ffffff;
  overflow: hidden;
  background-image: ${() => ({ src }) => `url(${src})`};
  background-size: cover;
`;

export const EscolaAtual = styled.div.attrs({
  className: "escola-atual",
})`
  display: flex;
  flex-direction: row;


  div.info {
    display: flex;
    flex-direction: column;
    padding: 16px;
    justify-content: space-between;
    position: relative;

    div.info-titulo {
      h2 {
        color: #423b8d;
        font-size: 18px;
      }
    }

    div.info-datas {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      > div {
        padding-right: 28px;
        span {
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    div.info {
      padding: 0px;
    }
  }


`;

export const FormacoesAnteriores = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const FormacaoAnterior = styled.div`
  margin-top: 32px;
  flex-basis: calc(100% / 3);

  @media screen and (max-width: 1600px) {
    flex-basis: 50%;
  }

  @media screen and (max-width: 1100px) {
    flex-basis: 100%;
  }

  div.info {
    position: relative;
    h2 {
      color: #423b8d;
      font-size: 18px;
      font-weight: 600;
    }

    div.info-datas {
      margin-top: 16px;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      > div {
        padding-right: 28px;
        span {
          font-weight: bold;
        }
      }
    }
  }
`;

export const ExperienciasProfissionais = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ExperienciaProfissional = styled.div`
  flex-basis: calc(100% / 3);
  margin-top: 32px;
  position: relative;

  @media screen and (max-width: 1600px){
    flex-basis: 50%;
  }

  @media screen and (max-width: 1100px){
    flex-basis: 100%;
  }

  h2 {
    font-size: 18px;
    color: #423b8d;
    font-weight: 600;
  }
  p.cargo {
    font-size: 18px;
    margin-bottom: 8px;
  }
  div.datas {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 36px;
    > div {
      padding-right: 28px;
      span {
        font-weight: bold;
      }
    }
  }
  h4 {
    color: #f78764;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 2px;
  }
  p.descricao {
    font-size: 16px;
  }
`;

export const CursosComplementares = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CursoComplementar = styled.div`
  flex-basis: calc(100% / 3);
  margin-top: 32px;
  position: relative;

  @media screen and (max-width: 1600px){
    flex-basis: 50%;
  }

  @media screen and (max-width: 1100px){
    flex-basis: 100%;
  }

  h2 {
    font-size: 18px;
    color: #423b8d;
    font-weight: 600;
    margin-bottom: 8px;
  }
  div.datas {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 36px;
    > div {
      padding-right: 28px;
      span {
        font-weight: bold;
      }
    }
  }
  h4 {
    color: #f78764;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 2px;
  }
  p.descricao {
    font-size: 16px;
  }
`;

export const AtividadesComplementares = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AtividadeComplementar = styled.div`
  flex-basis: calc(100% / 3);
  margin-top: 32px;
  position: relative;

  @media screen and (max-width: 1600px){
    flex-basis: 50%;
  }

  @media screen and (max-width: 1100px){
    flex-basis: 100%;
  }

  h2 {
    font-size: 18px;
    color: #423b8d;
    font-weight: 600;
    margin-bottom: 8px;
  }
  div.datas {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    margin-bottom: 36px;
    > div {
      padding-right: 28px;
      span {
        font-weight: bold;
      }
    }
  }
  h4 {
    color: #f78764;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    letter-spacing: 2px;
  }
  p.descricao {
    font-size: 16px;
  }
`;

export const Idiomas = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Idioma = styled.div.attrs({
  className: "list-item",
})`
  font-size: 18px;
  margin-bottom: 32px;
  position: relative;
  width: calc(100% / 6);

  @media screen and (max-width: 1400px) {
    flex-basis: 25%;
  }

  @media screen and (max-width: 1100px) {
    flex-basis: 50%;
  }

  @media screen and (max-width: 800px) {
    flex-basis: 100%;
  }

  h2 {
    color: #423b8d;
    font-size: 18px;
    font-weight: 600;
  }
`;
