import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import TotaisGeraisActions from "@/store/ducks/indicadores/totais-gerais";

import * as S from "./styles";

export default function TotaisGerais({}) {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(state => state.indicadoresTotaisGerais);

  function renderBoxes() {
    if (!data) return <></>;

    const {
      tempoMedioVagaAberta,
      tempoMedioAprovacaoTcePorEscolas,
      vagasFechadasSemContrato,
      vagasAbertas,
      entrevistas,
      estudantesContratados,
    } = data;

    const medias = {
      geral: Math.floor(tempoMedioVagaAberta.geral),
      last10: Math.floor(tempoMedioVagaAberta.last10),
      aprovEscola: Math.floor(tempoMedioAprovacaoTcePorEscolas),
    };

    const boxes = [
      {
        title: "Tempo médio de vaga aberta",
        value: medias.geral,
        valueSmall: medias.geral > 1 ? "dias" : "dia",
      },
      {
        title: "Tempo médio de vaga aberta (últimas 10 vagas)",
        value: medias.last10,
        valueSmall: medias.last10 > 1 ? "dias" : "dia",
      },
      {
        title: "Tempo médio para aprovação de contratos por escolas",
        value: medias.aprovEscola,
        valueSmall: medias.aprovEscola > 1 ? "dias" : "dia",
      },
      {
        title: "Vagas fechadas sem contratação",
        value: vagasFechadasSemContrato,
        valueSmall: vagasFechadasSemContrato > 1 ? "vagas" : "vaga",
      },
      {
        title: "Vagas abertas",
        value: vagasAbertas,
        valueSmall: vagasAbertas > 1 ? "vagas" : "vaga",
      },
      {
        title: "Entrevistas marcadas",
        value: entrevistas,
        valueSmall: entrevistas > 1 ? "entrevistas" : "entrevista",
      },
      {
        title: "Alunos contratados",
        value: estudantesContratados,
        valueSmall: estudantesContratados > 1 ? "alunos" : "aluno",
      },
    ];

    return (
      <S.Container>
        {boxes.map(box => {
          return (
            <S.Box key={box.title}>
              <S.BoxBody>
                <S.Value>{box.value}</S.Value>
                <S.ValueSmall>{box.valueSmall}</S.ValueSmall>
              </S.BoxBody>
              <S.BoxFooter>{box.title}</S.BoxFooter>
            </S.Box>
          );
        })}
      </S.Container>
    );
  }

  const fetchDataCB = useCallback(() => {
    dispatch(TotaisGeraisActions.request());
  }, [dispatch]);

  useEffect(() => {
    fetchDataCB();
  }, [fetchDataCB]);

  useEffect(() => {
    return () => {
      dispatch(TotaisGeraisActions.reset());
    };
  }, [dispatch]);

  return <>{renderBoxes()}</>;
}
