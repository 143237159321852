import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";
import { useAluno } from "@/hooks/alunos";
import { Sidebar, SubMenu } from "@/components/Aluno";
import { AlunoProvider } from "@/context/alunos/aluno";
import Paginator, { Loading } from "@/components/Paginator";
import { date, cep } from "@/formatters";

import {
  PaineisContainer,
  Content,
  MenuInterno,
  PainelPrincipal,
  AvatarEmpresa,
  Entrevista,
  Label,
} from "./styles";

function _AlunoEntrevistas() {
  const { data: user } = useSelector(state => state.auth);
  const { fetchAluno, aluno } = useAluno();

  const [proximasEntrevistas, setProximasEntrevistas] = useState([]);
  const [proximasEntrevistasLoading, setProximasEntrevistasLoading] = useState(
    false
  );
  const [proximasEntrevistasQuery] = useState({
    count: 9,
    filters: "naoExpiradas",
  });

  const [entrevistasPassadas, setEntrevistasPassadas] = useState([]);
  const [entrevistasPassadasLoading, setEntrevistasPassadasLoading] = useState(
    false
  );
  const [entrevistasPassadasQuery] = useState({
    count: 9,
    filters: "expiradas",
  });

  useEffect(() => {
    fetchAluno(user.perfil_id);
  }, []);

  function renderProximasEntrevistas() {
    function renderLista() {
      if (proximasEntrevistas.length > 0) {
        return proximasEntrevistas.map(entrevista => {
          const { id, vaga, data, hora, endereco } = entrevista;

          return (
            <Entrevista key={id}>
              <div className="coluna coluna-avatar">
                <AvatarEmpresa src={vaga.empresa.imagem} />
              </div>
              <div className="coluna coluna-empresa">
                <h2>Entrevista em {vaga.empresa.nome_exibicao}</h2>
                <h4>{vaga.titulo}</h4>
                <div className="data-hora">
                  <div className="data">
                    <span>Data:</span> {date(data)}
                  </div>
                  <div className="data">
                    <span>Horário:</span> {hora.substring(0, 5)}
                  </div>
                </div>
                <div className="endereco">
                  <h4>LOCAL DA ENTREVISTA</h4>
                  {endereco.logradouro}, {endereco.numero}, {endereco.bairro}.{" "}
                  {endereco.cidade.nome}, {endereco.cidade.estado.sigla}, CEP:{" "}
                  {cep(endereco.cep)}
                </div>
              </div>
            </Entrevista>
          );
        });
      } else {
        return <Label>Você não possui nenhuma entrevista</Label>;
      }
    }

    return (
      <>
        <Loading loading={proximasEntrevistasLoading}>
          {!proximasEntrevistasLoading && renderLista()}
        </Loading>
        <Paginator
          endpoint="entrevistas"
          onFetchComplete={data => setProximasEntrevistas(data.data)}
          onLoading={loading => setProximasEntrevistasLoading(loading)}
          query={proximasEntrevistasQuery}
        />
      </>
    );
  }

  function renderEntrevistasPassadas() {
    function renderLista() {
      if (entrevistasPassadas.length > 0) {
        return entrevistasPassadas.map(entrevista => {
          const { id, vaga, data, hora, endereco } = entrevista;

          return (
            <Entrevista key={id}>
              <div className="coluna coluna-avatar">
                <AvatarEmpresa src={vaga.empresa.imagem} />
              </div>
              <div className="coluna coluna-empresa">
                <h2>Entrevista em {vaga.empresa.nome_exibicao}</h2>
                <h4>{vaga.titulo}</h4>
                <div className="data-hora">
                  <div className="data">
                    <span>Data:</span> {date(data)}
                  </div>
                  <div className="data">
                    <span>Horário:</span> {hora.substring(0, 5)}
                  </div>
                </div>
                <div className="endereco">
                  <h4>LOCAL DA ENTREVISTA</h4>
                  {endereco.logradouro}, {endereco.numero}, {endereco.bairro}.{" "}
                  {endereco.cidade.nome}, {endereco.cidade.estado.sigla}, CEP:{" "}
                  {cep(endereco.cep)}
                </div>
              </div>
            </Entrevista>
          );
        });
      } else {
        return <Label>Você não possui nenhuma entrevista</Label>;
      }
    }

    return (
      <>
        <Loading loading={entrevistasPassadasLoading}>
          {!entrevistasPassadasLoading && renderLista()}
        </Loading>
        <Paginator
          endpoint="entrevistas"
          onFetchComplete={data => setEntrevistasPassadas(data.data)}
          onLoading={loading => setEntrevistasPassadasLoading(loading)}
          query={entrevistasPassadasQuery}
        />
      </>
    );
  }

  return (
    <Page>
      <Navbar type="interna" />
      {!aluno && <FullPageLoader />}
      {aluno && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                <SubMenu type="entrevistas" />
              </MenuInterno>
              <PainelPrincipal>
                <h1>Entrevistas</h1>
                <Tabs forceRenderTabPanel={true}>
                  <TabList>
                    <Tab>Próximas entrevistas</Tab>
                    <Tab>Entrevistas anteriores</Tab>
                  </TabList>
                  <TabPanel>{renderProximasEntrevistas()}</TabPanel>
                  <TabPanel>{renderEntrevistasPassadas()}</TabPanel>
                </Tabs>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function AlunoEntrevistas({ history }) {
  return (
    <AlunoProvider>
      <_AlunoEntrevistas history={history} />;
    </AlunoProvider>
  );
}
