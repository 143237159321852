import React from "react";
import {
  Page as StyledPage,
  Container as StyledContainer,
  DashboardContainer as StyledDashboardContainer,
  Row as StyledRow,
  Col as StyledCol,
} from "./styles";

function Page({ children, ...rest }) {
  return <StyledPage {...rest}>{children}</StyledPage>;
}

function Container({ children, fluid, ...rest }) {

  const _fluid = fluid !== undefined ? true : undefined;

  return (
    <StyledContainer fluid={_fluid} {...rest}>
      {children}
    </StyledContainer>
  );
}

function DashboardContainer({ children, ...rest }) {
  return <StyledDashboardContainer>{children}</StyledDashboardContainer>
}

function Row({ hascols, gaps, children, ...rest }) {
  const _hasCols = hascols !== undefined ? true : undefined;
  const _gaps = _hasCols && gaps !== undefined ? gaps : undefined;

  return (
    <StyledRow hascols={_hasCols} gaps={_gaps}>
      {children}
    </StyledRow>
  );
}

function Col({ children,...rest }) {

  return <StyledCol {...rest}>{children}</StyledCol>;
}

export { Page, Container, DashboardContainer, Row, Col };
