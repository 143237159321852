import React from "react";
import { Container, Logo, Text } from "./styles";

import logoHome from "@/assets/images/logos/navbar-home.png";
import logoLogin from "@/assets/images/logos/navbar-login.png";
import logoInterna from "@/assets/images/logos/navbar-interna.png";

export default function NavbarLogo({ type }) {

  let imgLogo;
  switch (type) {
    case "home":
      imgLogo = logoHome;
      break;
    case "login":
      imgLogo = logoLogin;
      break;
    default:
      imgLogo = logoInterna;
  }

  return (
    <Container>
      <Logo src={imgLogo} type={type} />
      {/* <Text type={type}>
        <h5 className="main">integrador</h5>
        <span className="sub">estágios</span>
      </Text> */}
    </Container>
  );
}
