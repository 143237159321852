import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators  } = createActions({
  listPaisesRequest: [""],
  listPaisesSuccess: ["data"],
  listPaisesFailure: ["error"],
});

export const listPaisesTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null
};

export const listPaisesRequest = state => {
  return update(state, {
    data: { $set: [] },
    loading: { $set: true },
    error: { $set: null },
  });
};

export const listPaisesSuccess = (state, action) => {
  const { data } = action;
  return update(state, {
    data: { $set: data },
    loading: { $set: false },
    error: { $set: null },
  });
};

export const listPaisesFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LIST_PAISES_REQUEST]: listPaisesRequest,
  [Types.LIST_PAISES_SUCCESS]: listPaisesSuccess,
  [Types.LIST_PAISES_FAILURE]: listPaisesFailure,
});
