import React, { useState } from "react";
import * as Yup from "yup";

import { useEmpresa } from "@/hooks/empresas";
import { Button } from "@/components/Common";
import { Form, Input } from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import { ModalContent, ModalBody, ModalFooter } from "./styles";

export default function ModalEditValor({ Id, onClose, franqueado = false }) {
  const { loading, updateEmpresa } = useEmpresa();

  const minValue = franqueado === false ? 0 : 100;

  function sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  const schema = Yup.object().shape({
    valor: Yup.number()
      .required("Obrigatório!")
      .min(minValue)
      .typeError("Não foi possível atualizar o valor!"),
  });

  const [valor, setValor] = useState(null);

  async function handleSubmit() {
    const data = { valor_contrato: valor };
    await updateEmpresa(Id, data);
    sleep(1000).then(() => {
      window.location.reload(true);
    });
  }

  return (
    <ModalContent>
      <ModalBody>
        <h2>Valor contrato do usuário</h2>
        <Form schema={schema} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Input
                name="valor"
                label="Valor:"
                onChange={e => setValor(e.target.value)}
              />
            </Col>
          </Row>
          <ModalFooter>
            <Button
              type="submit"
              block
              variant="tangerine"
              loading={loading || undefined}
            >
              Aceitar
            </Button>
            <Button
              type="button"
              block
              variant="cancel"
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </ModalContent>
  );
}
