import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import ViewEmpresaActions from "@/store/ducks/empresas/view";

const getAuthData = state => state.auth.data;

export function* view(action) {
  try {
    const { id } = action;
    const authData = yield select(getAuthData);
    const { data: retorno } = yield call(api.get,
      `empresas/${id}`,
      makeOptions(authData)
    );

    if (retorno.success) {
      yield put(ViewEmpresaActions.viewEmpresaSuccess(retorno.data));
    } else {
      yield put(ViewEmpresaActions.viewEmpresaFailure(retorno.errors));
    }
  } catch (e) {
    yield put(
      ViewEmpresaActions.viewEmpresaFailure({
        api_failure: "Não foi possível se comunicar com a API",
      })
    );
  }
}
