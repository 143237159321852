import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { PulseLoader } from "react-spinners";

import EmpresaCursosActions from "@/store/ducks/empresas/cursos";
import { ListItem, AtivoButton } from "./styles";

export default function CursoItem({ curso, empresaId }) {
  const dispatch = useDispatch();
  const { cursoId, loading } = useSelector(state => state.cursoEmpresa);
  const [ativo, setAtivo] = useState(curso.ativo == 1);

  async function handleToggleAtivo() {
    if (ativo) {
      dispatch(
        EmpresaCursosActions.empresaCursoRemoveRequest(
          empresaId,
          curso.id,
          function() {
            setAtivo(state => {
              return !state;
            });
          }
        )
      );
    } else {
      dispatch(
        EmpresaCursosActions.empresaCursoAddRequest(
          empresaId,
          curso.id,
          function() {
            setAtivo(state => {
              return !state;
            });
          }
        )
      );
    }
  }

  function renderAtivoIcon() {
    if (cursoId == curso.id) {
      return <PulseLoader size={8} color="#CE5F5F" />;
    }

    return (
      <AtivoButton onClick={handleToggleAtivo} disabled={loading}>
        {ativo ? (
          <MdRadioButtonChecked color="#279cd9" size={24} />
        ) : (
          <MdRadioButtonUnchecked color="#8B8A95" size={24} />
        )}
      </AtivoButton>
    );
  }

  return (
    <ListItem>
      <div className="coluna coluna-toggle">{renderAtivoIcon()}</div>
      <div className="coluna coluna-nome">
        <h4>{curso.nome}</h4>
      </div>
      <div className="coluna coluna-nivel">
        <h4>{curso.nivel.nome}</h4>
      </div>
    </ListItem>
  );
}
