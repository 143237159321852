import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaListUl, FaPen } from "react-icons/fa";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { VagaProvider } from "@/context/vagas/vaga";
import { VagasProvider } from "@/context/vagas/vagas";
import { useEmpresa } from "@/hooks/empresas";
import { useVaga } from "@/hooks/vagas";
import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader, Modal } from "@/components/Common";
import { Sidebar, SubMenu as SubmenuEscola } from "@/components/Escola";
import { SubMenu as SubmenuEmpresa } from "@/components/Empresa";
import TabBubble from "@/components/TabBubble";
import { moeda } from "@/formatters";

import {
  CardCandidato,
  CardEntrevista,
  ModalEditVaga,
  FecharReabrirVaga,
} from "@/components/Empresa/Vagas";

import Candidatos from "./Candidatos";
import CandidatosFavoritos from "./CandidatosFavoritos";

import {
  PaineisContainer,
  PainelPrincipal,
  Content,
  MenuInterno,
  PainelPrincipalTopo,
  PainelPrincipalTopoTitulo,
  Breadcrumbs,
  TituloVaga,
  CidadeVaga,
  DescricaoVaga,
  ResumoCards,
  GroupCards,
  ButtonVerLista,
  Icon,
  Label,
  Controles,
  CircleButton,
} from "./styles";

function _Vaga({ match, history }) {
  const { data: user } = useSelector(state => state.auth);
  const { fetchEmpresa, empresa } = useEmpresa();
  const { fetchVaga, vaga } = useVaga();
  const [modalEditVaga, setModalEditVaga] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabCandidatosTotal, setTabCandidatosTotal] = useState(null);
  const [tabFavoritosTotal, setTabFavoritosTotal] = useState(null);

  function renderPainelPrincipalTopo() {
    const { titulo, cidade, id } = vaga;
    return (
      <PainelPrincipalTopo>
        <PainelPrincipalTopoTitulo>
          <h1>Detalhes da vaga</h1>
          <div>
            <CircleButton onClick={() => setModalEditVaga(true)}>
              <FaPen />
            </CircleButton>
          </div>
        </PainelPrincipalTopoTitulo>
        <Breadcrumbs>
          <ul>
            <li>Vagas Cadastradas</li>
            <li className="ativo">Detalhes</li>
          </ul>
        </Breadcrumbs>
        <TituloVaga>
          Vaga Nº {id} - {titulo}
        </TituloVaga>
        <CidadeVaga>
          {cidade.nome}/{cidade.estado.sigla}
        </CidadeVaga>
      </PainelPrincipalTopo>
    );
  }

  function renderPanelDescricao() {
    const {
      id,
      atividades,
      candidatos_count,
      alunos,
      carga_horaria_semanal,
      cursos,
      beneficios,
      descricao,
      pcd,
    } = vaga;

    function renderDescricao() {
      return (
        <DescricaoVaga>
          <h3 className="resumo">Descrição:</h3>
          <p>{descricao}</p>
        </DescricaoVaga>
      );
    }

    function renderExclusivoPCD() {
      return (
        <DescricaoVaga>
          <h3 className="resumo">Exclusivo para PCD:</h3>
          <p>{Number(pcd) === 1 ? "SIM" : "NÃO"}</p>
        </DescricaoVaga>
      );
    }

    function renderCargaHoraria() {
      return (
        <DescricaoVaga>
          <h3 className="resumo">Carga horária semanal:</h3>
          <p>{carga_horaria_semanal.horas} horas</p>
        </DescricaoVaga>
      );
    }

    function renderRemuneracao() {
      const { remuneracao } = vaga;
      return (
        <DescricaoVaga>
          <h3 className="resumo">Remuneração mensal</h3>
          <p>{moeda(Number(remuneracao))}</p>
        </DescricaoVaga>
      );
    }

    function renderAtividades() {
      const atividadesList = atividades.length ? (
        atividades.map((atividade, index) => {
          return <li key={index}>{atividade}</li>;
        })
      ) : (
        <li>Não definidas</li>
      );
      return (
        <DescricaoVaga>
          <h3 className="resumo">Atividades:</h3>
          <ul>{atividadesList}</ul>
        </DescricaoVaga>
      );
    }

    function renderBeneficios() {
      const beneficiosList = beneficios.length ? (
        beneficios.map((beneficio, index) => {
          const beneficioValor =
            beneficio.valor > 0 ? (
              <span className="beneficio-valor"> {moeda(beneficio.valor)}</span>
            ) : (
              <></>
            );

          return (
            <li key={index}>
              {beneficio.descricao}
              {beneficioValor}
            </li>
          );
        })
      ) : (
        <li>Não definidos</li>
      );

      return (
        <DescricaoVaga>
          <h3 className="resumo">Benefícios</h3>
          <ul>{beneficiosList}</ul>
        </DescricaoVaga>
      );
    }

    function renderCursos() {
      const cursosList = cursos.length ? (
        cursos.map((curso, index) => {
          return (
            <li key={index}>
              {curso.nome} ({curso.nivel.nome})
            </li>
          );
        })
      ) : (
        <li>Não definidos</li>
      );
      return (
        <DescricaoVaga>
          <h3 className="resumo">Cursos:</h3>
          <ul>{cursosList}</ul>
        </DescricaoVaga>
      );
    }

    function renderCandidatos() {
      function renderLista() {
        return alunos.map(aluno => {
          return (
            <CardCandidato
              key={aluno.id}
              data={aluno}
              onClick={() => {
                history.push(`/vaga-estudante/${vaga.id}/${aluno.id}`);
              }}
            />
          );
        });
      }

      return (
        <ResumoCards>
          <h2>Candidatos ({candidatos_count})</h2>
          <GroupCards>
            {renderLista()}
            {candidatos_count > alunos.length && (
              <ButtonVerLista onClick={() => setTabIndex(2)}>
                <Icon>
                  <FaListUl color="#fff" size={16} />
                </Icon>
                <Label>Ver lista completa</Label>
              </ButtonVerLista>
            )}
          </GroupCards>
        </ResumoCards>
      );
    }

    return (
      <>
        {renderDescricao()}
        {renderExclusivoPCD()}
        {renderCargaHoraria()}
        {renderRemuneracao()}
        {renderCursos()}
        {renderAtividades()}
        {renderBeneficios()}
        {renderCandidatos()}
        {/* Exibir a listagem de entrevistas aqui */}
        <Controles>
          <FecharReabrirVaga  />
        </Controles>
      </>
    );
  }

  function renderPanelCandidatos() {
    const { id: vagaId } = match.params;
    return (
      <Candidatos
        vagaId={vagaId}
        onTotal={total => {
          setTabCandidatosTotal(total);
        }}
      />
    );
  }

  function renderPanelCandidatosFavoritos() {
    const { id: vagaId } = match.params;
    return (
      <CandidatosFavoritos
        vagaId={vagaId}
        onTotal={total => {
          setTabFavoritosTotal(total);
        }}
      />
    );
  }

  function renderPanelEntrevistas() {
    const { entrevistas, entrevistas_count } = vaga;
    const { id: vagaId } = match.params;

    function renderLista() {
      return entrevistas.map(entrevista => {
        return (
          <CardEntrevista
            data={entrevista}
            key={entrevista.id}
            onClick={() => {
              history.push(`/vaga-estudante/${vagaId}/${entrevista.aluno.id}`);
            }}
          />
        );
      });
    }

    return (
      <ResumoCards>
        <GroupCards>
          {renderLista()}
          <ButtonVerLista
            onClick={() => {
              history.push(`/vaga-entrevistas/${vagaId}`);
            }}
          >
            <Icon>
              <FaListUl color="#fff" size={16} />
            </Icon>
            <Label>Ver lista completa</Label>
          </ButtonVerLista>
        </GroupCards>
      </ResumoCards>
    );
  }

  useEffect(() => {
    fetchEmpresa(user.empresa.id);
    const { id: vagaId } = match.params;
    fetchVaga(vagaId);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      {(!empresa || !vaga) && <FullPageLoader />}
      {empresa && vaga && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                {(empresa.escola && <SubmenuEscola type="vagas" />) || (
                  <SubmenuEmpresa type="vagas" />
                )}
              </MenuInterno>
              <PainelPrincipal>
                {renderPainelPrincipalTopo()}
                <Tabs
                  forceRenderTabPanel={true}
                  selectedIndex={tabIndex}
                  onSelect={index => setTabIndex(index)}
                >
                  <TabList>
                    <Tab>Descrição da vaga</Tab>
                    <Tab>
                      Candidatos{" "}
                      <TabBubble count={tabCandidatosTotal || undefined} />
                    </Tab>
                    <Tab>
                      Favoritos
                      <TabBubble count={tabFavoritosTotal || undefined} />
                    </Tab>
                    <Tab>
                      Entrevistas
                      <TabBubble count={vaga.entrevistas_count || undefined} />
                    </Tab>
                  </TabList>
                  <TabPanel>{renderPanelDescricao()}</TabPanel>
                  <TabPanel>{renderPanelCandidatos()}</TabPanel>
                  <TabPanel>{renderPanelCandidatosFavoritos()}</TabPanel>
                  <TabPanel>{renderPanelEntrevistas()}</TabPanel>
                </Tabs>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}

      <Modal open={modalEditVaga} onClose={() => setModalEditVaga(false)}>
        <ModalEditVaga onClose={() => setModalEditVaga(false)} />
      </Modal>
    </Page>
  );
}

export default function Vaga({ match, history }) {
  return (
    <EmpresaProvider>
      <VagasProvider>
        <VagaProvider>
          <_Vaga match={match} history={history} />
        </VagaProvider>
      </VagasProvider>
    </EmpresaProvider>
  );
}
