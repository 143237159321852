import React, { useState } from "react";
import { Input } from "@/components/Form";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import { AdminMenu } from "@/components/Admin";
import { Navbar } from "@/components/Blocks";
import Paginator, { Loading } from "@/components/Paginator";
import PlanilhaSugestoesEscolas from "@/components/Planilhas/SugestoesEscolas";

import {
  Header,
  FiltroBasico,
  FilterButtonContainer,
  Totais,
  EmpresaListItem,
  ActionButton,
} from "./styles";

export default function SugestoesEscolas() {
  const [empresas, setEmpresas] = useState([]);
  const [query, setQuery] = useState({});
  const [paginator, setPaginator] = useState(null);
  const [loadingEmpresas, setLoadingEmpresas] = useState(false);

  function handleOnFetchComplete(data) {
    setEmpresas(data.data);
    setPaginator(data.paginator);
  }

  function handleOnChange(event) {
    const { name, value } = event.target;
    setQuery(state => {
      return { ...state, [name]: value };
    });
  }

  function renderHeader() {
    let titulo = "Todas as Sujestões de escolas";

    return (
      <Header>
        <h4>Sugestões de Escolas</h4>
        <h2>{titulo}</h2>
        <div className="breadcrumbs">
          sugestões de Escolas {">"} <span>{titulo}</span>
        </div>
      </Header>
    );
  }

  function renderFiltroBasico() {
    const colSearch = {
      lg: 0.7,
      md: 0.5,
      sm: 1,
    };

    const colButton = {
      lg: 0.3,
      md: 0.5,
      sm: 1,
    };

    return (
      <FiltroBasico>
        <Row hascols>
          <Col {...colSearch}>
            <Input
              label="Nome da escola ou curso"
              name="search"
              placeholder="Busque pelo nome da escola ou curso"
              onChange={handleOnChange}
            />
          </Col>
          <Col {...colButton}>
            <FilterButtonContainer>
              <PlanilhaSugestoesEscolas block params={query} />
            </FilterButtonContainer>
          </Col>
        </Row>
      </FiltroBasico>
    );
  }

  function renderTotais() {
    if (loadingEmpresas) return <></>;
    if (paginator !== null) {
      const { total, pages } = paginator;
      if (total === 0) {
        return (
          <Totais>
            Sem sugestões de escolas para exibir dentro dos critérios de busca
          </Totais>
        );
      }
      return (
        <Totais>
          Exibindo {empresas.length} de {total} sugestões de escolas
        </Totais>
      );
    }
  }

  function renderEmpresas() {
    return empresas.map(empresa => {
      return (
        <EmpresaListItem key={empresa.id}>
          <div className="coluna coluna-nome">
            <h4>{empresa.escola_nome}</h4>
            <p>{empresa.curso_nome}</p>
          </div>
          <div className="coluna coluna-email">
            <h4>E-mail do cadastrante</h4>
            {empresa.email}
          </div>
          <div className="coluna coluna-local">
            <h4>Local da escola</h4>
            {empresa.cidade.nome}
          </div>
        </EmpresaListItem>
      );
    });
  }

  return (
    <Page>
      <Navbar type="interna" />
      <AdminMenu />
      <DashboardContainer>
        {renderHeader()}
        {renderFiltroBasico()}
        {renderTotais()}
        <Loading loading={loadingEmpresas}>
          {renderEmpresas()}
          <Paginator
            endpoint="sugestoes-escolas"
            query={query}
            onFetchComplete={handleOnFetchComplete}
            onLoading={l => setLoadingEmpresas(l)}
          />
        </Loading>
      </DashboardContainer>
    </Page>
  );
}
