import styled from "styled-components";
import {
  Menu as _MenuIcon,
  Close as _CloseIcon,
} from "@styled-icons/ionicons-outline";
import { Link } from "react-router-dom";

export const OuterContainer = styled.div`
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const MenuIconContainer = styled.div`
  padding: 16px;
  cursor: pointer;
`;

export const MenuIcon = styled(_MenuIcon).attrs({
  size: 24,
})`
  color: ${(props) => props.type === "home" ? "#271946" : "#fff" };
`;

export const HiddenContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.state === "close" ? "-400px" : "0px ")};
  z-index: 100;
  height: 100vh;
  width: 300px;
  background-color: #fafafa;
  border-right: 1px solid #ccc;
  transition: all 0.3s linear;
`;

export const HiddenContent = styled.div`
  position: relative;
  padding-top: 48px;
`;

export const CloseIconContainer = styled.div`
  padding: 16px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const CloseIcon = styled(_CloseIcon).attrs({
  size: 24,
})`
  color: #000;
  &:hover {
    color: #f78764;
  }
`;


export const OptionLink = styled(Link)`
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  transition: 300ms ease;
  &:hover {
    color: #f78764;
  }
`;

export const Menu = styled.div``;

export const MenuHeader = styled.div`
  padding: 16px;
`;

export const MenuHeaderTitle = styled.p`
  color: #271946;
`;

export const MenuBody = styled.div`
  display: block;
`;

export const MenuOptionLink = styled(OptionLink)`
  padding: 8px 24px;
`;
