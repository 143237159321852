import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  direction: row;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const PainelPrincipalTopo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const Breadcrumbs = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px #d5d4e0 solid;
  margin-bottom: 48px;

  ul {
    display: flex;

    li {
      font-size: 14px;
      font-weight: 600;
      color: #a09faa;

      &.ativo {
        color: #271946;
      }
    }
    li + li {
      &::before {
        content: ">";
        padding: 0 8px;
      }
    }
  }
`;

export const TituloVaga = styled.h1`
  color: #2e2d38 !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  margin-bottom: 8px !important;
`;

export const CidadeVaga = styled.h5`
  color: #9491b4;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px #d5d4e0 solid;
`;

export const Filtros = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    color: #605f6a;
  }
`;

export const ContentCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #605f6a;
    margin-bottom: 32px;
  }
`;
