import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import ConsultaCepActions from "@/store/ducks/utils/consulta-cep";
import { Button } from "@/components/Common";
import { Form, Input, InputMask, Select, Scope } from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import { ModalContent, ModalBody, ModalFooter } from "./styles";
import { estados } from "@/data";
import { useAluno } from "@/hooks/alunos";
import api from "@/services/api";

export default function ModalEditPerfil({ onClose }) {
  const dispatch = useDispatch();
  const { aluno, loading, updateAluno } = useAluno();
  const consultaCep = useSelector(state => state.consultaCep);
  const [formData, setFormData] = useState(null);
  const [optionsCidades, setOptionsCidades] = useState([]);
  const [cidadesLoading, setCidadesLoading] = useState(false);
  const optionsEstados = estados.map(e => {
    return { id: e.id, title: e.uf };
  });

  const schema = Yup.object().shape({
    endereco: Yup.object().shape({
      cep: Yup.string().required("Obrigatório"),
      logradouro: Yup.string().required("Obrigatório"),
      numero: Yup.string(),
      complemento: Yup.string(),
      bairro: Yup.string().required("Obrigatório"),
      cidade_id: Yup.string().required("Obrigatório"),
    }),
  });

  function updateForm(data) {
    setFormData(state => {
      return { ...state, ...data };
    });
  }

  // setando valores iniciais para os selects
  function setInitialData(aluno) {
    const { endereco } = aluno;
    fetchCidades(endereco.cidade.estado_id);
    endereco.estado = endereco.cidade.estado_id;
    updateForm({ ...aluno, ...{ endereco } });
  }

  async function fetchCidades(estadoId) {
    if (estadoId === null) return;
    setCidadesLoading(true);
    const { data: retorno } = await api.get(`/cidades/estado/${estadoId}`);
    if (retorno.success) {
      const cidades = retorno.data.map(c => {
        return { id: c.id, title: c.nome };
      });
      setOptionsCidades(cidades);
      setCidadesLoading(false);
    }
  }

  function handleCepChange(event) {
    const { value } = event.target;
    if (value.length === 9) {
      dispatch(ConsultaCepActions.consultaCepRequest(value));
    }
  }

  function handleEstadoChange(event) {
    event.persist();
    const value = event.target.value;
    setFormData(state => {
      const { endereco } = state;
      endereco.estado = value;
      return { ...state, ...{ endereco } };
    });
    fetchCidades(value);
  }

  function handleCidadeChange(event) {
    const value = event.target.value;
    const newState = formData;
    newState.endereco.cidade_id = value;
    setFormData(state => {
      return { ...state, ...newState };
    });
  }

  function handleEnderecoOnChange(event) {
    const { name, value } = event.target;
    const param = name.split(".")[1];

    setFormData(state => {
      const endereco = { ...state.endereco, ...{ [param]: value } };
      return { ...state, ...{ endereco } };
    });
  }

  function handleSubmit(data) {
    updateAluno(aluno.id, data);
  }

  useEffect(() => {
    if (!loading) onClose();
  }, [loading]);

  useEffect(() => {
    setInitialData(aluno);
  }, [aluno]);

  useEffect(() => {
    if (consultaCep.error === null && consultaCep.data !== null) {
      const {
        bairro,
        cep,
        cidade_id,
        complemento,
        estado_id,
        logradouro,
        numero,
      } = consultaCep.data;

      const enderecoFragment = {
        ...(estado_id && { estado: estado_id }),
        ...(cidade_id && { cidade_id }),
        ...(logradouro && { logradouro }),
        ...(numero && { numero }),
        ...(complemento && { complemento }),
        ...(bairro && { bairro }),
        ...(cep && { cep }),
      };

      const dataToUpdate = {
        endereco: enderecoFragment,
      };
      fetchCidades(estado_id);
      updateForm(dataToUpdate);
    }
  }, [consultaCep]);

  return (
    <ModalContent>
      {formData && (
        <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
          <ModalBody>
            <Scope path="endereco">
              <Row hascols>
                <Col md="2/6">
                  <InputMask
                    type="cep"
                    label="CEP"
                    name="cep"
                    onChange={handleCepChange}
                    value={formData.endereco.cep || ""}
                  />
                </Col>
                <Col md="1/6">
                  <Select
                    label="UF"
                    name="estado"
                    onChange={handleEstadoChange}
                    options={optionsEstados}
                    value={formData.endereco.estado || ""}
                  />
                </Col>
                <Col>
                  <Select
                    label="Cidade"
                    name="cidade_id"
                    options={optionsCidades}
                    loading={cidadesLoading}
                    value={formData.endereco.cidade_id || ""}
                    onChange={handleCidadeChange}
                  />
                </Col>
              </Row>
              <Row hascols>
                <Col>
                  <Input
                    label="Logradouro"
                    name="logradouro"
                    value={formData.endereco.logradouro || ""}
                    onChange={handleEnderecoOnChange}
                  />
                </Col>
                <Col>
                  <Input
                    label="Número"
                    name="numero"
                    value={formData.endereco.numero || ""}
                    onChange={handleEnderecoOnChange}
                  />
                </Col>
              </Row>
              <Row hascols>
                <Col>
                  <Input
                    label="Complemento"
                    name="complemento"
                    value={formData.endereco.complemento || ""}
                    onChange={handleEnderecoOnChange}
                  />
                </Col>
                <Col>
                  <Input
                    label="Bairro"
                    name="bairro"
                    value={formData.endereco.bairro || ""}
                    onChange={handleEnderecoOnChange}
                  />
                </Col>
              </Row>
            </Scope>
          </ModalBody>
          <ModalFooter>
            <Button variant="cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              variant="tangerine"
              loading={loading || undefined}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </ModalFooter>
        </Form>
      )}
    </ModalContent>
  );
}
