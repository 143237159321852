import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaCrown } from "react-icons/fa";

import AuthActions from "@/store/ducks/auth";
import { Dropdown } from "@/components/Common";
import {
  UserContainer,
  AdminBadge,
  Avatar,
  Nome,
  MenuHandler,
  MenuContainer,
} from "./styles";

function User(props) {
  const { data: user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { history } = props;

  function renderAdminBadge() {
    if (user.tipo === "administrador") {
      return (
        <AdminBadge title="Usuário administrativo">
          <FaCrown size={12} />
        </AdminBadge>
      );
    }

    if (user.hasOwnProperty("rootProfile")) {
      return (
        <AdminBadge title="Controlado por usuário administrativo">
          <FaCrown size={12} />
        </AdminBadge>
      );
    }
  }

  function handleLogout() {
    dispatch(AuthActions.authLogout(history));
  }

  function handleAbandonarControle() {
    dispatch(AuthActions.authAbandonarControle(history));
  }

  const handler = <MenuHandler />;
  const content = (
    <MenuContainer>
      <ul>
        {user.tipo !== "administrador" && user.tipo !== "franqueado" && (
          <li>
            <Link className="menu-option" to="/perfil">
              Visualizar perfil
            </Link>
          </li>
        )}
        {user.hasOwnProperty("rootProfile") && (
          <li>
            <span onClick={handleAbandonarControle} className="menu-option">
              Abandonar controle
            </span>
          </li>
        )}
        <li>
          <Link className="menu-option" to="/editar-credenciais">
            Conta
          </Link>
        </li>
        <li>
          <span onClick={handleLogout} className="menu-option">
            Sair
          </span>
        </li>
      </ul>
    </MenuContainer>
  );

  return (
    <>
      <UserContainer>
        {renderAdminBadge()}
        <Nome>{user.nome}</Nome>
        <Avatar src={user.imagem} />
        <Dropdown handler={handler} content={content} />
      </UserContainer>
    </>
  );
}
// usando with router para ter acesso ao objeto history presente no props
export default withRouter(User);
