import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears } from "date-fns";

import AuthActions from "@/store/ducks/auth";
import ConsultaCepActions from "@/store/ducks/utils/consulta-cep";
import ListPaisesActions from "@/store/ducks/paises/list";
import SelectCidadesActions from "@/store/ducks/cidades/select-cidades";

import { Navbar, Footer } from "@/components/Blocks";
import { Button, Modal } from "@/components/Common";
import { Page, Row, Col } from "@/components/Grid";
import {
  Form,
  Scope,
  Input,
  InputMask,
  Select,
  SelectSearch,
  DatePicker,
  Stepper,
  Step,
} from "@/components/Form";
import { SectionForm, SubHeader } from "@/components/PageComponents/Cadastro";
import AceiteTermos from "@/components/AceiteTermos";
import Autorizo from "@/components/Autorizo";
import { ModalCadastroAvatar } from "@/components/Estudante";
import {
  estados,
  disponibilidade as optionsDisponibilidade,
  grau_parentesco as optionsParentesco,
} from "@/data";
import api from "@/services/api";
import toastify from "@/services/toastify";

import estudanteAvatar from "@/assets/images/estudante-avatar.png";
import { schemaStep1, schemaSugerirEscola, schemaStep2 } from "./schemas";
import { AvatarContainer, Avatar, AvisoMensagem } from "./styles";

export default function CadastroEstudante({ history }) {
  const dispatch = useDispatch();

  const [showResponsavelArea, setShowResponsavelArea] = useState(false);
  const [showAviso16Anos, setShowAviso16Anos] = useState(false);
  const [showPcdCampo, setShowPcdCampo] = useState(false);
  const [formData, setFormData] = useState({
    endereco: {},
    possui_necessidade: "n",
    pais_id: 33,
    nacionalidade: "Brasileiro(a)",
  });

  const [formEscola, setFormEscola] = useState({});
  const [formStep, setFormStep] = useState(1);

  const [modalCadastroAvatarOpen, setModalCadastroAvatarOpen] = useState(false);
  const [imagem, setImagem] = useState(estudanteAvatar);
  const [optionsNiveis, setOptionsNiveis] = useState([]);
  const [queryCursos, setQueryCursos] = useState({ count: 20 });
  const [queryEscolas] = useState({ filters: "escola" });
  const [formSending, setFormSending] = useState(false);
  const [aceite, setAceite] = useState(false);
  const [autorizo, setAutorizo] = useState(false);
  const [estadoId, setEstadoId] = useState(null);

  const consultaCep = useSelector(state => state.consultaCep);
  const { data: optionsPaises } = useSelector(state => state.listPaises);
  const { data: cidades, loading: cidadesLoading } = useSelector(
    state => state.selectCidades
  );

  const optionsEstados = estados.map(e => {
    return { id: e.id, title: e.uf };
  });
  const optionsSexo = [
    { id: "f", title: "Feminino" },
    { id: "m", title: "Masculino" },
  ];

  async function createSugestaoEscola(data) {
    const endpoint = `sugestoes-escolas`;
    setFormSending(true);
    const { data: response } = await api.post(endpoint, data);
    setFormSending(false);
    if (response.success) {
      toastify("Obrigado pela sugestão");
      history.push("/");
    }
  }

  async function createAluno(data) {
    const endpoint = `alunos`;
    setFormSending(true);
    const { data: response } = await api.post(endpoint, data);
    setFormSending(false);
    if (response.success) {
      toastify(
        "Seu cadastro foi realizado com sucesso. Aguarde enquanto realizamos seu primeiro login"
      );
      const authData = {
        email: data.usuario.email,
        senha: data.usuario.senha,
      };
      dispatch(AuthActions.authRequest(authData, history));
    } else {
      [...Object.keys(response.errors)].forEach(e => {
        toastify(response.errors[e][0], "error");
      });
    }
  }

  async function fetchNiveis() {
    const { data: response } = await api.get("utils/niveis");
    if (response.success) {
      setOptionsNiveis(state => {
        return response.data.map(nivel => {
          return { id: nivel.id, title: nivel.nome };
        });
      });
    }
  }

  function updateForm(data, step = null) {
    setFormData(formData => {
      return { ...formData, ...data };
    });
    if (step !== null) {
      setFormStep(step);
    }
  }

  function formStep1() {
    function handleSubmit(data) {
      updateForm(data, 2);
    }

    function handleNivelChange(event) {
      event.persist();
      const { value } = event.target;
      setFormData(state => {
        return { ...state, ...{ curso: [{ id: null, title: "" }] } };
      });
      setQueryCursos(state => {
        return { ...state, ...{ nivel: value } };
      });
    }

    function handleCursoChange(curso) {
      setFormData(state => {
        return { ...state, ...{ curso } };
      });
    }

    function mapOptionsCursos(data) {
      return data.map(d => {
        return { id: d.id, title: d.nome };
      });
    }

    function handleEscolaChange(escola) {
      setFormData(state => {
        return { ...state, ...{ escola } };
      });
    }

    function mapOptionsEscolas(data) {
      return data.map(d => {
        return { id: d.id, title: d.nome_exibicao };
      });
    }

    return (
      <Form schema={schemaStep1} onSubmit={handleSubmit} initialData={formData}>
        <Row>
          <Col>
            <Select
              label="Grau de ensino"
              name="nivel_id"
              options={optionsNiveis}
              onChange={handleNivelChange}
            />
          </Col>
        </Row>
        <Row hascols>
          <Col>
            <SelectSearch
              label="Escola"
              endpoint="empresas"
              searchParam="nome"
              query={queryEscolas}
              mapOptions={mapOptionsEscolas}
              onChange={handleEscolaChange}
              name="empresa_id"
              value={formData.escola}
            />
          </Col>
          <Col>
            <SelectSearch
              label="Curso"
              endpoint="cursos"
              searchParam="nome"
              query={queryCursos}
              mapOptions={mapOptionsCursos}
              onChange={handleCursoChange}
              name="curso_id"
              value={formData.curso}
            />
          </Col>
        </Row>
        <Row hascols>
          <Col>
            <DatePicker name="data_inicio" label="Início" type="mes" />
          </Col>
          <Col>
            <DatePicker
              name="data_conclusao"
              label="Previsão de conclusão"
              type="mes"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="tangerine" onClick={() => setFormStep("sugerir")}>
              Não encontrei minha escola
            </Button>
            <Button type="submit" variant="cyan">
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  function formSugerirEscola() {
    function handleSubmit(data, { resetForm }) {
      setFormEscola(state => {
        return { ...state, ...data };
      });
      setFormStep("enviar-sugestao");
    }

    function handleEstadoChange(event) {
      event.persist();
      setEstadoId(event.target.value);
    }

    return (
      <Form onSubmit={handleSubmit} schema={schemaSugerirEscola}>
        <Row hascols>
          <Col>
            <Input label="Seu nome completo" name="nome" />
          </Col>
          <Col>
            <Input label="Nome da escola" name="escola_nome" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input name="curso_nome" label="Nome do curso (opcional)" />
          </Col>
        </Row>
        <Row hascols>
          <Col>
            <Input type="mail" label="E-mail" name="email" />
          </Col>
          <Col>
            <InputMask type="telefone" label="Telefone" name="telefone" />
          </Col>
        </Row>
        <Row hascols>
          <Col>
            <Select
              label="UF"
              name="estado"
              onChange={handleEstadoChange}
              options={optionsEstados}
              value={formSugerirEscola.estado || undefined}
            />
          </Col>
          <Col>
            <Select
              label="Cidade"
              name="cidade_id"
              options={cidades}
              loading={cidadesLoading}
              value={formSugerirEscola.cidade_id || undefined}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              variant="tangerine"
              icon="arrow-left"
              onClick={() => setFormStep(1)}
            >
              Voltar
            </Button>
            <Button
              variant="cyan"
              type="submit"
              loading={formSending || undefined}
            >
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  function formStep2() {
    const handleSubmit = data => updateForm(data, "last");

    function avatarChange(imagem) {
      setImagem(imagem);
      updateForm({ imagem });
    }

    function handleCepChange(event) {
      const { value } = event.target;
      if (value.length === 9) {
        dispatch(ConsultaCepActions.consultaCepRequest(value));
      }
    }

    function handleEstadoChange(event) {
      event.persist();
      setEstadoId(event.target.value);
    }

    function handleEnderecoOnChange(event) {
      const { name, value } = event.target;
      const param = name.split(".")[1];

      setFormData(state => {
        const endereco = { ...state.endereco, ...{ [param]: value } };
        return { ...state, ...{ endereco } };
      });
    }

    function handleDataNascimentoChange(event) {
      event.persist();
      const { value } = event.target;
      const dataNascimento = new Date(value);
      setShowAviso16Anos(state => {
        return differenceInYears(new Date(), dataNascimento) < 16;
      });
      setShowResponsavelArea(state => {
        return differenceInYears(new Date(), dataNascimento) < 18;
      });
    }

    function handlePossuiNecessidadeChange(event) {
      const { value, name } = event.target;
      setFormData(state => {
        return { ...state, ...{ [name]: value } };
      });
      setShowPcdCampo(() => {
        return value === "s";
      });
    }

    function handleChange(event) {
      const { name, value } = event.target;
      setFormData(state => {
        return { ...state, [name]: value };
      });
    }

    return (
      <>
        <Form
          onSubmit={handleSubmit}
          initialData={formData}
          schema={schemaStep2}
        >
          <AvatarContainer>
            <Avatar
              src={imagem}
              onClick={() => setModalCadastroAvatarOpen(true)}
            />
            <Button
              variant="cyan"
              onClick={() => setModalCadastroAvatarOpen(true)}
            >
              Alterar imagem
            </Button>
          </AvatarContainer>

          <Modal
            open={modalCadastroAvatarOpen}
            onClose={() => setModalCadastroAvatarOpen(false)}
          >
            <ModalCadastroAvatar
              onClose={() => setModalCadastroAvatarOpen(false)}
              onChange={avatarChange}
            />
          </Modal>

          <Row hascols>
            <Col>
              <Input label="Nome completo" name="nome" />
            </Col>
            <Col>
              <Select label="Sexo" options={optionsSexo} name="sexo" />
            </Col>
          </Row>
          <Row hascols>
            <Col>
              <InputMask type="telefone" label="Telefone" name="telefone" />
            </Col>
            <Col>
              <InputMask type="cpf" label="CPF" name="cpf" />
            </Col>
          </Row>
          <Row hascols>
            <Col>
              <Select
                label="Pais de origem"
                name="pais_id"
                options={optionsPaises}
                value={formData.pais_id || undefined}
                onChange={handleChange}
              />
            </Col>
            <Col>
              <Input
                type="date"
                max="2099-01-01"
                label="Data de nascimento"
                name="data_nascimento"
                onChange={handleDataNascimentoChange}
              />
            </Col>
          </Row>

          {showAviso16Anos && (
            <AvisoMensagem>
              <h4>
                De acordo com a lei 11.788/08 é necessário ter no mínimo 16 anos
                para ser estagiário.
              </h4>
            </AvisoMensagem>
          )}

          {showResponsavelArea && (
            <>
              <Row>
                <Col>
                  <Input label="Nome do responsável" name="responsavel_nome" />
                  <small>Obrigatório caso seja menor</small>
                </Col>
              </Row>

              <Row hascols>
                <Col>
                  <Select
                    label="Grau de parentesco do responsável"
                    name="responsavel_grau_parentesco"
                    options={optionsParentesco}
                  />
                  <small>Obrigatório caso seja menor</small>
                </Col>
                <Col>
                  <InputMask
                    type="cpf"
                    label="CPF do responsável"
                    name="responsavel_cpf"
                  />
                  <small>Obrigatório caso seja menor</small>
                </Col>
              </Row>

              <Row hascols>
                <Col>
                  <InputMask
                    type="telefone"
                    label="Telefone do responsável"
                    name="responsavel_telefone"
                  />
                  <small>Obrigatório caso seja menor</small>
                </Col>
                <Col>
                  <Input
                    label="E-mail do responsável"
                    name="responsavel_email"
                    type="mail"
                  />
                  <small>Obrigatório caso seja menor</small>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col>
              <Select
                label="Disponibilidade para o estágio"
                options={optionsDisponibilidade}
                name="disponibilidade"
              />
            </Col>
          </Row>
          <Row hascols>
            <Col>
              <Select
                label="Possui alguma necessidade especial?"
                options={[{ id: "n", title: "Não" }, { id: "s", title: "Sim" }]}
                name="possui_necessidade"
                value={formData.possui_necessidade}
                onChange={handlePossuiNecessidadeChange}
              />
            </Col>
            {showPcdCampo && (
              <Col>
                <Input label="Código PCD" name="pcd" />
                <small>Apenas para pessoas com deficiência</small>
              </Col>
            )}
          </Row>
          <SubHeader title="Localidade" />
          <Scope path="endereco">
            <Row hascols>
              <Col md="2/6">
                <InputMask
                  type="cep"
                  label="CEP"
                  name="cep"
                  value={formData.endereco.cep || undefined}
                  onChange={e => {
                    handleCepChange(e);
                    handleEnderecoOnChange(e);
                  }}
                />
              </Col>
              <Col md="1/6">
                <Select
                  label="UF"
                  name="estado"
                  options={optionsEstados}
                  value={formData.endereco.estado || undefined}
                  onChange={e => {
                    handleEstadoChange(e);
                    handleEnderecoOnChange(e);
                  }}
                />
              </Col>
              <Col>
                <Select
                  label="Cidade"
                  name="cidade_id"
                  options={cidades}
                  loading={cidadesLoading}
                  value={formData.endereco.cidade_id || undefined}
                  onChange={handleEnderecoOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  label="Logradouro"
                  name="logradouro"
                  value={formData.endereco.logradouro || ""}
                  onChange={handleEnderecoOnChange}
                />
              </Col>
              <Col>
                <Input
                  label="Número"
                  name="numero"
                  value={formData.endereco.numero || ""}
                  onChange={handleEnderecoOnChange}
                />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input
                  label="Complemento"
                  name="complemento"
                  value={formData.endereco.complemento || ""}
                  onChange={handleEnderecoOnChange}
                />
              </Col>
              <Col>
                <Input
                  label="Bairro"
                  name="bairro"
                  value={formData.endereco.bairro || ""}
                  onChange={handleEnderecoOnChange}
                />
              </Col>
            </Row>
          </Scope>
          <SubHeader title="Redes sociais (opcional)" />
          <Row hascols>
            <Col>
              <Input type="url" label="Linkedin" name="linkedin" />
              <small>Insira o link completo para o seu perfil</small>
            </Col>
            <Col>
              <Input type="url" label="Facebook" name="facebook" />
              <small>Insira o link completo para o seu perfil</small>
            </Col>
          </Row>
          <Row hascols>
            <Col>
              <Input type="url" label="Instagram" name="instagram" />
              <small>Insira o link completo para o seu perfil</small>
            </Col>
            <Col>
              <Input type="url" label="Twitter" name="twitter" />
              <small>Insira o link completo para o seu perfil</small>
            </Col>
          </Row>
          <SubHeader title="Dados de acesso" />
          <Scope path="usuario">
            <Row hascols>
              <Col>
                <Input type="mail" label="E-mail" name="email" />
              </Col>
              <Col>
                <Input type="mail" label="Confirmar e-mail" name="email_c" />
              </Col>
            </Row>
            <Row hascols>
              <Col>
                <Input label="Senha" type="password" name="senha" />
              </Col>
              <Col>
                <Input label="Confirmar senha" type="password" name="senha_c" />
              </Col>
            </Row>
          </Scope>
          <Row>
            <Col>
              <AceiteTermos onChange={checked => setAceite(checked)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Autorizo onChange={checked => setAutorizo(checked)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="tangerine"
                icon="arrow-left"
                onClick={() => setFormStep(1)}
              >
                Voltar
              </Button>
              <Button
                variant="cyan"
                type="submit"
                disabled={!aceite || !autorizo || undefined}
                loading={formSending || undefined}
              >
                Concluir
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  function formSubtitle() {
    switch (formStep) {
      case "sugerir":
        return "Recomende o cadastro da sua universidade ou escola de nível técnico e médio";
      case 1:
        return "Insira os dados sobre seu curso";
      case 2:
        return "Insira seus dados pessoais";
      case 3:
        return "Insira os dados sobre a localidade";
      case 4:
        return "Insira os dados sobre experiência profissional e formações";
      case 5:
        return "Insira os idiomas, cursos e atividades que você exerce (se não houver clique em Concluir)";
    }
  }

  const consultaCepResultCB = useCallback(() => {
    if (consultaCep.error === null && consultaCep.data !== null) {
      const {
        bairro,
        cep,
        cidade_id,
        complemento,
        estado_id,
        logradouro,
        numero,
      } = consultaCep.data;

      const enderecoFragment = {
        ...(estado_id && { estado: estado_id }),
        ...(cidade_id && { cidade_id }),
        ...(logradouro && { logradouro }),
        ...(numero && { numero }),
        ...(complemento && { complemento }),
        ...(bairro && { bairro }),
        ...(cep && { cep }),
      };

      const dataToUpdate = {
        endereco: enderecoFragment,
      };
      setEstadoId(estado_id);
      setFormData(state => ({ ...state, ...dataToUpdate }));
    }
  }, [consultaCep, setFormData, setEstadoId]);

  const fetchCidadesCB = useCallback(() => {
    if (estadoId) {
      dispatch(SelectCidadesActions.request(estadoId));
    }
  }, [estadoId, dispatch]);

  useEffect(() => {
    if (formStep === "last") {
      createAluno(formData);
    }
    if (formStep === "enviar-sugestao") {
      createSugestaoEscola(formEscola);
    }
  }, [formData, formEscola, formStep]);

  useEffect(() => {
    fetchNiveis();
    dispatch(ListPaisesActions.listPaisesRequest());
  }, []);

  useEffect(() => {
    consultaCepResultCB();
  }, [consultaCepResultCB]);

  useEffect(() => {
    fetchCidadesCB();
  }, [fetchCidadesCB]);

  useEffect(() => {
    return () => {
      dispatch(ConsultaCepActions.consultaCepReset());
    };
  }, []);

  return (
    <Page>
      <Navbar type="login" />
      <SectionForm
        formTitle="Cadastro de estudantes"
        formSubtitle="Estudantes do ensino médio, técnico ou superior"
        formSubtitle={formSubtitle()}
      >
        <Stepper>
          <Step active={formStep === 1} step="1/2">
            {formStep1()}
          </Step>
          <Step
            active={["sugerir", "enviar-sugestao"].includes(formStep)}
            step="2/2"
          >
            {formSugerirEscola()}
          </Step>
          <Step active={[2, "last"].includes(formStep)} noBorder step="2/2">
            {formStep2()}
          </Step>
        </Stepper>
      </SectionForm>
      <Footer />
    </Page>
  );
}
