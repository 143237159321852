import * as Yup from "yup";
import { differenceInYears, isAfter, isValid } from "date-fns";

const schemaStep1 = Yup.object().shape({
  nivel_id: Yup.number().typeError("Obrigatório"),
  curso_id: Yup.number().typeError("Obrigatório"),
  empresa_id: Yup.number().typeError("Obrigatório").required("Teste"),
  data_inicio: Yup.date()
    .typeError("Data inválida")
    .required("Obrigatório"),
  data_conclusao: Yup.date()
    .typeError("Data inválida")
    .test("data-conclusao-maior", "Data inválida", function(value) {
      return isValid(value) && isAfter(value, this.parent.data_inicio);
    })
    .required("Obrigatório")
});

const schemaSugerirEscola = Yup.object().shape({
  nome: Yup.string().required("Obrigatório"),
  escola_nome: Yup.string().required("Obrigatório"),
  curso_nome: Yup.string().notRequired(),
  email: Yup.string()
    .email("E-mail inválido")
    .required("Obrigatório"),
  telefone: Yup.string().required("Obrigatório"),
  cidade_id: Yup.string().required("Obrigatório"),
});

const schemaStep2 = Yup.object().shape({
  nome: Yup.string().required("Obrigatório"),
  sexo: Yup.string().required("Obrigatório"),
  telefone: Yup.string().required("Obrigatório"),
  cpf: Yup.string().required("Obrigatório"),
  pais_id: Yup.mixed().required("Obrigatório"),
  data_nascimento: Yup.date()
    .test("teste-idade-minima", "Idade mínima é de 16 anos", function (value) {
      return differenceInYears(new Date(), new Date(value)) >= 16;
    })
    .typeError("Obrigatório"),
  pcd: Yup.string(),
  imagem: Yup.string(),
  facebook: Yup.string().url("Url Inválida"),
  instagram: Yup.string().url("Url Inválida"),
  linkedin: Yup.string().url("Url Inválida"),
  twitter: Yup.string().url("Url Inválida"),
  responsavel_nome: Yup.string().when(
    "data_nascimento",
    (data_nascimento, schema) => {
      const dataNascimento = new Date(data_nascimento);
      const idade = differenceInYears(new Date(), dataNascimento);
      if (idade < 18) {
        return schema.required("Obrigatório");
      }
    }
  ),
  responsavel_grau_parentesco: Yup.string().when(
    "data_nascimento",
    (data_nascimento, schema) => {
      const dataNascimento = new Date(data_nascimento);
      const idade = differenceInYears(new Date(), dataNascimento);
      if (idade < 18) {
        return schema.required("Obrigatório");
      }
    }
  ),
  responsavel_cpf: Yup.string().when(
    "data_nascimento",
    (data_nascimento, schema) => {
      const dataNascimento = new Date(data_nascimento);
      const idade = differenceInYears(new Date(), dataNascimento);
      if (idade < 18) {
        return schema.required("Obrigatório");
      }
    }
  ),
  responsavel_telefone: Yup.string().when(
    "data_nascimento",
    (data_nascimento, schema) => {
      const dataNascimento = new Date(data_nascimento);
      const idade = differenceInYears(new Date(), dataNascimento);
      if (idade < 18) {
        return schema.required("Obrigatório");
      }
    }
  ),
  responsavel_email: Yup.string().when(
    "data_nascimento",
    (data_nascimento, schema) => {
      const dataNascimento = new Date(data_nascimento);
      const idade = differenceInYears(new Date(), dataNascimento);
      if (idade < 18) {
        return schema.required("Obrigatório");
      }
    }
  ),
  disponibilidade: Yup.string().required("Obrigatório"),
  endereco: Yup.object().shape({
    cep: Yup.string().required("Obrigatório"),
    logradouro: Yup.string().required("Obrigatório"),
    numero: Yup.string(),
    complemento: Yup.string(),
    bairro: Yup.string().required("Obrigatório"),
    cidade_id: Yup.string().required("Obrigatório"),
  }),
  usuario: Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("Obrigatório"),
    email_c: Yup.string()
      .email("E-mail inválido")
      .test("email-match", "Confirme seu email", function(value) {
        return this.parent.email === value;
      }),
    senha: Yup.string().required("Obrigatório"),
    senha_c: Yup.string()
      .required("Obrigatório")
      .test("senha-match", "Confirme sua senha", function(value) {
        return this.parent.senha === value;
      }),
  }),
});

export { schemaStep1, schemaSugerirEscola, schemaStep2 };
