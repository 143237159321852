import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Button } from "@/components/Common";
import { Form, InputMask } from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import { ModalContent, ModalBody, ModalFooter, ContaLink } from "./styles";
import { useAluno, usePrevious } from "@/hooks";

export default function ModalEditContato({ onClose }) {
  const { aluno, loading, updateAluno } = useAluno();
  const [formData, setFormData] = useState(null);
  const loadingPrev = usePrevious(loading);

  const schema = Yup.object().shape({
    telefone: Yup.string().required("Obrigatório"),
  });

  function handleOnChange(event) {
    const { name, value } = event.target;
    setFormData(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  function handleSubmit(data) {
    updateAluno(aluno.id, data);
  }

  useEffect(() => {
    if (loadingPrev && !loading) onClose();
  }, [loadingPrev, loading]);

  useEffect(() => {
    setFormData(() => {
      const { telefone } = aluno;
      return { telefone };
    });
  }, [aluno, setFormData]);

  return (
    <ModalContent>
      {formData && (
        <Form schema={schema} onSubmit={handleSubmit} initialData={formData}>
          <ModalBody>
            <Row>
              <Col>
                <InputMask
                  type="telefone"
                  label="Telefone"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                Seu e-mail para contato/login pode ser alterado na página de{" "}
                <ContaLink to="/editar-credenciais">
                  edição de credenciais
                </ContaLink>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button variant="cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              variant="tangerine"
              loading={loading || undefined}
            >
              SALVAR ALTERAÇÕES
            </Button>
          </ModalFooter>
        </Form>
      )}
    </ModalContent>
  );
}
