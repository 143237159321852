import styled from "styled-components";

export const Label = styled.h6`
  font-size: 16px;
  font-weight: 600;
  color: #8b8a95;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 25px;

  .button-container:not(:last-child) {
    margin-right: 12px;
  }
`;
