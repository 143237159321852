import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useAluno } from "@/hooks/alunos";
import { Form, Select } from "@/components/Form";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";
import { ModalContent, ModalBody, ModalFooter } from "./styles";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

export default function ModalEditIdioma({ onClose, data }) {
  const { fetchAluno } = useAluno();
  const [formData, setFormData] = useState(data);
  const [optionsIdiomas, setOptionsIdiomas] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data: user } = useSelector(state => state.auth);

  const schema = Yup.object().shape({
    aluno_id: Yup.number(),
    idioma_id: Yup.number().required("Obrigatório"),
    proficiencia: Yup.string().required("Obrigatório"),
  });

  const optionsProficiencia = [
    { id: "básico", title: "Básico" },
    { id: "intermediário", title: "Intermediário" },
    { id: "avançado", title: "Avançado" },
    { id: "fluente", title: "Fluente" },
  ];

  useEffect(() => {
    fetchIdiomas();
  }, []);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  async function fetchIdiomas() {
    const endpoint = `idiomas`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    if (response.success) {
      setOptionsIdiomas(() => {
        return response.data.map(idioma => {
          return { id: idioma.id, title: idioma.nome };
        });
      });
    }
  }

  async function updateIdioma(data) {
    setLoading(true);
    const endpoint = `alunos/curriculos/${user.perfil_id}/idiomas/${data.idioma_id}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    setLoading(false);
    if (response.success) {
      toastify("Idioma salvo com sucesso");
      fetchAluno(user.perfil_id);
      onClose();
    }
  }

  function handleOnchange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setFormData(state => {
      return { ...state, ...{ [name]: value } };
    });
  }

  function handleSubmit(data) {
    updateIdioma(data);
  }

  return (
    <ModalContent>
      <Form onSubmit={handleSubmit} schema={schema} initialData={formData}>
        <ModalBody>
          <Row hascols>
            <Col>
              <Select
                label="Idioma"
                name="idioma_id"
                options={optionsIdiomas}
                value={formData.idioma_id || undefined}
                onChange={handleOnchange}
              />
            </Col>
            <Col>
              <Select
                label="Nível"
                name="proficiencia"
                options={optionsProficiencia}
                value={formData.proficiencia || undefined}
                onChange={handleOnchange}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button variant="cancel" onClick={onClose}>
            CANCELAR
          </Button>
          <Button type="submit" variant="tangerine" loading={loading || undefined}>
            SALVAR ALTERAÇÕES
          </Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
