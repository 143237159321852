import React, { useState } from "react";
import * as Yup from "yup";
import { Row, Col } from "@/components/Grid";
import { Button } from "@/components/Common";
import { Form, Input, InputMask } from "@/components/Form";
import toastify from "@/services/toastify";
import api from "@/services/api";
import { ModalContent, ModalFooter } from "./styles";

export default function ModalAceiteAssinante({ tceId, onClose, codigo }) {
  const schema = Yup.object().shape({
    nome: Yup.string().required("Obrigatório"),
    cpf: Yup.string().required("Obrigatório"),
  });

  const [Nome, setNome] = useState("");
  const [Cpf, setCpf] = useState("");

  async function handleSubmit() {
    const endpoint = `assinante-tce/${tceId}/${codigo}`;
    const { data: response } = await api.put(endpoint, {
      aceite: 1,
      nome: Nome,
      cpf: Cpf,
    });
    if (response.success) {
      toastify("Contrato aceito com sucesso");
      window.location.reload(true);
    }
  }

  return (
    <ModalContent>
      <h2>Dados do assinante</h2>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Input
              id="nome"
              name="nome"
              label="Nome:"
              onChange={e => setNome(e.target.value)}
            />
          </Col>
        </Row>
        <Row hascols>
          <Col>
            <InputMask
              id="cpf"
              name="cpf"
              type="cpf"
              label="CPF:"
              onChange={e => setCpf(e.target.value)}
            />
          </Col>
        </Row>
        <ModalFooter>
          <Button onClick={() => handleSubmit()} block variant="tangerine">
            Aceitar
          </Button>
          <Button
            type="button"
            block
            variant="cancel"
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Form>
    </ModalContent>
  );
}
