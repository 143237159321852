import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import toastify from "@/services/toastify";
import AuthEditarCredenciaisActions from "@/store/ducks/auth/editar-credenciais";
import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { Button } from "@/components/Common";
import { Form, Input } from "@/components/Form";
import { Row, Col } from "@/components/Grid";

import {
  Content,
  PaineisContainer,
  PainelPrincipal,
  FormSubHeader,
  Divider,
  Aviso,
} from "./styles";

export default function EditarCredenciais({ history }) {
  const dispatch = useDispatch();
  const { data: user } = useSelector(state => state.auth);
  const { loading } = useSelector(state => state.authEditarCredenciais);

  const [formData, setFormData] = useState({});

  // funcoes de validacao

  function validateEmailNovoRequired(value) {
    const { email_novo_c = "" } = formData;
    if (email_novo_c !== "" && value === "") {
      return false;
    }
    return true;
  }

  function validateEmailNovoConfirmEquals(value) {
    const { email_novo = "" } = formData;
    if (email_novo !== value) {
      return false;
    }
    return true;
  }

  function validateSenhaNovaRequired(value) {
    const { senha_nova_c = "" } = formData;
    if (senha_nova_c !== "" && value === "") {
      return false;
    }
    return true;
  }

  function validateSenhaNovaConfirmEquals(value) {
    const { senha_nova = "" } = formData;
    if (senha_nova !== value) {
      return false;
    }
    return true;
  }

  const schema = Yup.object().shape({
    email_novo: Yup.string()
      .email("E-mail inválido")
      .test(
        "email_novo_required",
        "Informe o novo e-mail",
        validateEmailNovoRequired
      ),
    email_novo_c: Yup.string()
      .email("E-mail inválido")
      .test(
        "email_novo_c_equals",
        "Repita o conteúdo informado no campo novo e-mail",
        validateEmailNovoConfirmEquals
      ),
    senha_nova: Yup.string().test(
      "senha_nova_required",
      "Informe a nova senha",
      validateSenhaNovaRequired
    ),
    senha_nova_c: Yup.string().test(
      "senha_nova_c_equals",
      "Repita o conteúdo informado no campo nova senha",
      validateSenhaNovaConfirmEquals
    ),
    senha_atual: Yup.string().required("Informe sua senha atual"),
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSubmit(data) {
    const formToSend = {};

    Object.keys(data).forEach(key => {
      const value = data[key];
      const keysToKeep = ["email_novo", "senha_atual", "senha_nova"];
      if (value !== "" && keysToKeep.includes(key)) {
        formToSend[key] = value;
      }
    });

    if (Object.keys(formToSend).length <= 1) {
      return toastify(
        "Nenhuma ação será tomada pois ambos os campos novo e-mail e nova senha estão vazios.",
        "error"
      );
    }

    dispatch(AuthEditarCredenciaisActions.updateRequest(formToSend, history));
  }

  //

  useEffect(() => {
    return () => {
      dispatch(AuthEditarCredenciaisActions.reset());
    };
  }, [dispatch]);

  return (
    <Page>
      <Navbar type="interna" />
      <DashboardContainer>
        <PaineisContainer>
          <Content>
            <PainelPrincipal>
              <Form schema={schema} onSubmit={handleSubmit}>
                <FormSubHeader>
                  {user.tipo === "aluno" && (
                    <h4>E-mail para login e contato</h4>
                  ) || (
                    <h4>E-mail para login</h4>
                  )}
                </FormSubHeader>
                <Row>
                  <Col>
                    <Input
                      label="Novo e-mail:"
                      name="email_novo"
                      type="mail"
                      value={formData.email_novo || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      label="Repita o novo e-mail:"
                      name="email_novo_c"
                      type="mail"
                      value={formData.email_novo_c || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <FormSubHeader>
                  <h4>Senha para login</h4>
                </FormSubHeader>

                <Row>
                  <Col>
                    <Input
                      label="Nova senha:"
                      name="senha_nova"
                      type="password"
                      value={formData.senha_nova || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      label="Repita a nova senha:"
                      name="senha_nova_c"
                      type="password"
                      value={formData.senha_nova_c || ""}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Divider />

                <Row>
                  <Col>
                    <Input
                      label="Sua senha atual*:"
                      name="senha_atual"
                      type="password"
                      value={formData.senha_atual || ""}
                      onChange={handleChange}
                    />
                    <small>
                      Para sua segurança, é necessário informar sua senha atual.
                    </small>
                  </Col>
                </Row>

                <Aviso>
                  <p>
                    Alterar seu email e/ou senha de acesso implicará na
                    desconexão de sua sessão atual, sendo necessário efetuar um
                    novo login para continuar utilizando o Integrador.App
                  </p>
                </Aviso>
                <Row>
                  <Col>
                    <Button type="submit" variant="tangerine" loading={loading}>
                      SALVAR ALTERAÇÕES
                    </Button>
                  </Col>
                </Row>
              </Form>
            </PainelPrincipal>
          </Content>
        </PaineisContainer>
      </DashboardContainer>
    </Page>
  );
}
