import { format } from "date-fns";
import "intl";
import "intl/locale-data/jsonp/pt-BR";

export const cnpj = (value) => {
  if (!value) return '';
  return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

export const cpf = (value) => {
  if (!value) return '';
  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export const telefone = (value) => {
  if (!value) return '';
  const cleanValue = value.replace(/[^\d]+/g, "");
  if (cleanValue.length <= 10) {
    return cleanValue.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  } else if (cleanValue.length >= 11) { // para o formato (21) 98989-9898
    return cleanValue.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }
}

export const placa = (value) => {
  if (!value) return '';
  return value.replace(/^(\S{3})(\d{4})/, "$1-$2").toUpperCase();
}

export const moeda = (value, currency = 'BRL') => {
  if (!value) value = 0;
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: currency
  });
}

export const decimal = (value) => {
  if (!value) value = 0;
  return value.toLocaleString('pt-BR');
}

export const date = (value) => {
  if (!value || value == "") return "";
  const [ano, mes, dia] = value.substring(0, 10).split("-");
  const data = new Date(ano, mes - 1, dia);
  return Intl.DateTimeFormat("pt-BR").format(data);
}

export const fulldate = (value, showTime = false) => {
  if (!value) return '';
  const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
    'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
  ];

  const date = new Date(value);
  const mes = meses[date.getMonth()];
  const dia = date.getDate();
  const ano = date.getFullYear();
  const hora = format(date, "HH:mm");

  return showTime ? `${dia} de ${mes} às ${hora}` :`${dia} de ${mes}`;
}

export const time = (value) => {
  if (!value) return '';
  const date = new Date(value);
  return date.toLocaleTimeString('pt-BR', {
    timeZone: 'America/Sao_Paulo'
  });
}

export const cep = (value) => {
  if (!value) return '';
  return value.replace(/^(\d{5})(\d{3})/, "$1-$2");
}
