import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  deleteSupervisorRequest: ["id"],
  deleteSupervisorSuccess: [],
  deleteSupervisorFailure: ["error"],
  deleteSupervisorReset: [],
});

export const deleteSupervisorTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  loading: false,
  error: null,
};

// Reducers
export const deleteSupervisorRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const deleteSupervisorSuccess = state => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const deleteSupervisorFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const deleteSupervisorReset = state => {
  return INITIAL_STATE;
}

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_SUPERVISOR_REQUEST]: deleteSupervisorRequest,
  [Types.DELETE_SUPERVISOR_SUCCESS]: deleteSupervisorSuccess,
  [Types.DELETE_SUPERVISOR_FAILURE]: deleteSupervisorFailure,
  [Types.DELETE_SUPERVISOR_RESET]: deleteSupervisorReset,
});
