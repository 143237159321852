import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AuthActions from "@/store/ducks/auth";

import { Navbar, Footer } from "@/components/Blocks";
import { Card, Button } from "@/components/Common";
import { Form, Input } from "@/components/Form";
import { Page, Row, Col } from "@/components/Grid";
import { EntrarContainer, CardWrapper } from "./styles";
import { authSchema } from "./schemas";

export default function Login({ history }) {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);

  const _handleSubmit = data => {
    dispatch(AuthActions.authRequest(data, history));
  };

  useEffect(() => {
    return () => {
      dispatch(AuthActions.authReset());
    }
  }, []);

  return (
    <Page>
      <Navbar type="login" />
      <EntrarContainer>
        <CardWrapper>
          <h1>Entrar</h1>
          <p>Digite seus dados para acessar sua conta</p>
          <Card shadow>
            <Form schema={authSchema} onSubmit={_handleSubmit}>
              <Row>
                <Col>
                  <Input type="mail" label="E-mail:" name="email" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input label="Senha:" type="password" name="senha" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link className="esqueci-senha" to="/recuperar-senha">Esqueci minha senha</Link>
                </Col>
              </Row>
              <Row hascols gaps="16">
                <Col>
                  <Button
                    type="submit"
                    variant="magenta"
                    loading={auth.loading}
                    block
                  >
                    Continuar
                  </Button>
                </Col>
                <Col>
                  <Button to="/cadastre-se" block>
                    Cadastre-se
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </CardWrapper>
      </EntrarContainer>
      <Footer />
    </Page>
  );
}
