import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const Entrevista = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 0px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #b9b7cc;
  }
  .coluna {
    &-avatar {
      margin-right: 30px;
    }
    &-empresa {
      h2 {
        font-size: 20px;
        font-weight: 500;
      }
      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #605f6a;
      }
      .data-hora {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        .data {
          margin-right: 32px;
        }
        span {
          font-weight: 600;
        }
      }
      .endereco {
        margin-top: 12px;
        h4 {
          color: #605f6a;
        }
      }
    }
  }
`;

export const AvatarEmpresa = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 55px;
  background-color: #ffffff;
  overflow: hidden;
  background-image: ${() => ({ src }) => `url(${src})`};
  background-size: cover;

  @media screen and (max-width: 1100px) {
    margin-bottom: 12px;
  }
`;

export const Label = styled.h6`
  font-size: 16px;
  font-weight: 600;
  color: #8b8a95;
`;
