import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 48px;
`;

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #888888;
  border-radius: 2px;
  margin-right: 12px;
`;


export const AddItem = styled.button`
  color: ${({ disabled }) => {
    return disabled ? "#CCCCCC" : "#2B0C75";
  }};
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
  &:hover{
    opacity: .8;
  }
`;

export const HiddenButton = styled.button`
  display: none;
`;

export const ListItem = styled.div`
  display: ${({ hidden }) => {
    return hidden !== undefined ? "none" : "flex";
  }};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 16px 20px;
  margin-bottom: 8px;
  border-radius: 8px;

  div.coluna {
    &-atividade {
      flex-basis: 250px;
      color: #2B0C75;
      font-weight: 600;
    }
    &-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        background-color: transparent;
        padding: 0 5px;
        margin-left: 10px;
      }
    }
  }

`;
