import styled from "styled-components";

export const SupervisorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 16px 20px;
  margin-bottom: 8px;
  border-radius: 8px;

  div.col {
    display: flex;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 4px;
    &-nome {
      flex: 1 0;
      font-weight: 600;
      color: #423b8d;
      flex-direction: column;
      .cargo {
        color: #000;
      }
    }
    &-email {
      flex: 0 0 350px;
    }
    &-telefone {
      flex: 0 0 120px;
    }
    &-actions {
      flex: 0 0 200px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      button {
        border: none;
        background-color: transparent;
        margin-left: 10px;
        font-weight: 600;
        &.action-delete {
          color: #f78764;
        }
        &.action-cancel {
          color: #423b8d;
        }
        &.trash-can {
          font-size: 16px;
          transition: 300ms ease;
          color: #f78764;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    position: relative;
    div.col {
      flex: 1 0 100%;
      order: 2;
      margin-bottom: 8px;
      &-actions {
        order: 1;
        line-height: 16px;
        position: absolute;
        top: 16px;
        right: 20px;
        div.action {
          background-color: #FFFFFF;
          padding: 16px;
          border-radius: 4px;
        }
      }
    }
  }
`;
