import React from "react";

import { Container, Avatar, Infos, Nome, Curso } from "./styles";

export default function CardCandidato({ data, onClick }) {

  function handleOnClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <Container onClick={handleOnClick}>
      <Avatar url={data.imagem} />
      <Infos>
        <Nome>{data.nome}</Nome>
        <Curso>{data.curso.nome}</Curso>
      </Infos>
    </Container>
  );
}
