import React from "react";
import { format, parse } from "date-fns";
import { cep } from "@/formatters";

import {
  Container,
  Line1,
  Aluno,
  Avatar,
  Infos,
  Nome,
  Curso,
  Data,
  Endereco,
  Controles,
  ButtonReagenda,
  ButtonContratar,
} from "./styles";

export default function ListEntrevista({
  data,
  onReagendarClick,
  onQueroContratarClick,
}) {
  function handleOnReagendarClick() {
    if (onReagendarClick) onReagendarClick();
  }

  function handleOnQueroContratarClick() {
    if (onQueroContratarClick) onQueroContratarClick();
  }

  function renderEndereco() {
    const { endereco } = data;
    return (
      <Endereco>
        <h4>LOCAL DA ENTREVISTA</h4>
        {endereco.logradouro}, {endereco.numero}, {endereco.bairro}.{" "}
        {endereco.cidade.nome}, {endereco.cidade.estado.sigla}, CEP:{" "}
        {cep(endereco.cep)}
      </Endereco>
    );
  }

  function renderDataEHora() {
    if (!data.data || !data.hora) return <></>;

    const dataHora = parse(
      `${data.data} ${data.hora}`,
      "yyyy-MM-dd HH:mm:ss",
      new Date()
    );
    const dataHoraF = format(dataHora, "dd/MM/yyyy 'às' HH:mm");

    return (
      <Data>
        <span>Data:</span> {dataHoraF}
      </Data>
    );
  }

  return (
    <Container>
      <Line1>
        <Aluno>
          <Avatar url={data.aluno.imagem} />
          <Infos>
            <Nome>{data.aluno.nome}</Nome>
            <Curso>{data.aluno.curso.nome}</Curso>
          </Infos>
        </Aluno>
        {renderDataEHora()}
        <Controles>
          <ButtonReagenda onClick={handleOnReagendarClick}>
            Reagendar entrevista
          </ButtonReagenda>
          <ButtonContratar onClick={handleOnQueroContratarClick}>
            Quero contratar
          </ButtonContratar>
        </Controles>
      </Line1>
      {renderEndereco()}
    </Container>
  );
}
