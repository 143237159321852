import React from "react";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { Container, theme } from "./styles";

export default function Button({
  to,
  externalLink,
  children,
  variant,
  block,
  onClick,
  type,
  icon,
  disabled,
  loading,
  ...rest
}) {
  /**
   * variant
   * ['cyan','cyan-outline']
   * ['magenta','magenta-outline']
   * ['tangerine','tangerine-outline']
   * ['deepblue','deepblue-outline']
   * ['dark','dark-outline']
   * ['light','light-outline']
   * ['cancel']
   * default dark-outline
   */

  /**
   * type
   * ['button','submit']
   * default button
   */

  /**
   * icon
   * ['forward','arrow-left']
   */

  // Estilos
  const _variant = variant !== undefined ? variant : "dark-outline";
  const _block = block !== undefined ? "block" : undefined;
  const _icon = icon !== undefined ? icon : undefined;
  const _loading = ![undefined, false].includes(loading);
  const _disabled =
    ![undefined, false].includes(disabled) || _loading ? "disabled" : undefined;

  // Comportamentos
  const _type = type !== undefined ? type : "button";

  // Metodos
  const _onClick = onClick;

  function _getLoading() {
    if (_loading) {
      let loadingColor;
      const [_color, _outline] = _variant.split("-");
      if (_outline === undefined || _color === "light") {
        loadingColor = "#FFFFFF";
      } else {
        loadingColor = theme.colors[_color];
      }
      return <PulseLoader color={loadingColor} size={12} />;
    }
    return <></>;
  }

  function renderButton() {
    if (to && !externalLink) {
      return (
        <Link {...rest} to={to}>
          {children}
        </Link>
      );
    }

    if (to && externalLink) {
      return (
        <a {...rest} href={to}>
          {children}
        </a>
      );
    }

    return (
      <button {...rest} type={_type} disabled={_disabled} onClick={_onClick}>
        {_loading ? _getLoading() : children}
      </button>
    );
  }

  return (
    <Container
      variant={_variant}
      block={_block}
      icon={_icon}
      disabled={_disabled}
    >
      {renderButton()}
    </Container>
  );
}
