import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 8px;
`;

export const Avatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: #ccc;
  margin-right: 16px;
  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Tcolumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &.menor {
    max-width: 220px;
  }
`;

export const IconColumn = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;
`;

export const Nome = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: #423b8d;
  text-decoration: underline;
  cursor: pointer;
`;

export const Data = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #605f6a;
`;

export const Label = styled.h4`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #271946;
  letter-spacing: 1.5px;
`;

export const Info = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #2e2d38;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0 8px;
  width: 120px;
  height: 48px;
  transition: 300ms ease;
  color: #271946;
  text-transform: uppercase;

  &:hover {
    transform: scale(0.9);
  }

  svg {
    margin-left: 10px;
  }
`;

export const Favorito = styled(Button)``;
