import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minMax(320px, 2fr));
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const BoxBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 16px;
  background-color: #fff;
  color: #7294a5;
`;

export const Value = styled.div`
  font-size: 48px;
  font-weight: 600;
  color: #3fbcdb;
`;

export const ValueSmall = styled.small`
  font-size: 16px;
`;

export const BoxFooter = styled.div`
  width: 100%;
  background-color: #fafafa;
  padding: 16px;
  color: #7294a5;
  font-weight: 600;
  font-size: 14px;
  border-top: 1px solid #f1f1f1;
  text-transform: uppercase;
  text-align: center;
`;
