import styled from "styled-components";

export const Container = styled.div`
  margin-top: 56px;
  position: relative;

  @media screen and (max-width: 1100px) {
    margin-top: 0px;
  }

  div.actions {
    position: absolute;
    top: -2px;
    left: 120px;
  }
`;

export const SocialButton = styled.a`
  background-color: transparent;
  font-size: 20px;
  margin: 0 4px;
`;

export const FacebookButton = styled(SocialButton)`
  color: ${props => (props.href ? "#3c5a99" : "#CCCCCC")};
`;
export const LinkedinButton = styled(SocialButton)`
  color: ${props => (props.href ? "#0077b5" : "#CCCCCC")};
`;
export const InstagramButton = styled(SocialButton)`
  color: ${props => (props.href ? "#833ab4" : "#CCCCCC")};
`;
export const TwitterButton = styled(SocialButton)`
  color: ${props => (props.href ? "#1da1f2" : "#CCCCCC")};
`;


export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;
