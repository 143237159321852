import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AvaliacaoProvider } from "@/context/avaliacoes/avaliacao";
import { AlunoProvider } from "@/context/alunos/aluno";
import { useAvaliacao } from "@/hooks/avaliacoes";
import { useAluno } from "@/hooks/alunos";

import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";

import ResponderAvaliacao from "@/components/Aluno/Avaliacoes/ResponderAvaliacao";

import { Sidebar, SubMenu } from "@/components/Aluno";

import {
  PaineisContainer,
  Content,
  PainelPrincipal,
  MenuInterno,
} from "./styles";

function _Avaliacao({ match }) {
  const { data: user } = useSelector(state => state.auth);

  const {
    fetchAvaliacao,
    avaliacao,
  } = useAvaliacao();
  const { fetchAluno, aluno } = useAluno();

  useEffect(() => {
    const { id } = match.params;
    fetchAluno(user.perfil_id);
    fetchAvaliacao(id);
  }, [match]);

  return (
    <Page>
      <Navbar type="interna" />
      {!aluno && <FullPageLoader />}s
      {aluno && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                <SubMenu type="perfil" />
              </MenuInterno>
              <PainelPrincipal>
                {avaliacao && avaliacao.finalizada_em === null && (
                  <ResponderAvaliacao avaliacao={avaliacao} />
                )}
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function Avaliacao(props) {
  return (
    <AlunoProvider>
      <AvaliacaoProvider>
        <_Avaliacao {...props} />
      </AvaliacaoProvider>
    </AlunoProvider>
  );
}


