import React from "react";
import { Button } from "@/components/Common";
import { useTce } from "@/hooks/tces";
import { Container, Assinante } from "./styles";

export default function AssinantesEscola({ tce }) {
  const { assinanteReenviarEmail, loading } = useTce();

  function renderAssinantes() {
    const { assinantes_escola } = tce;

    if (assinantes_escola.length === 0) {
      return <p>Adicione assinantes clicando em "Adicionar outro assinante".</p>;
    }

    return assinantes_escola.map(assinante_escola => {
      
      let status = "Aguardando";
      if (assinante_escola.data_assinatura != null) status = "Aprovado";
      if (assinante_escola.deleted_at != null) status = "Reprovado";

      const { assinante } = assinante_escola

      return (
        <Assinante status={status}>
          <div className="coluna coluna-nome">
            <h4>{assinante.nome} {assinante.sobrenome}</h4>
            <p>{assinante.email}</p>
          </div>
          <div className="coluna coluna-status">
            <h4>Status</h4>
            <p>{status}</p>
          </div>
          <div className="coluna coluna-actions">
            <Button
              variant="cyan"
              disabled={status !== "Aguardando"}
              loading={loading || undefined}
              onClick={() => {
                assinanteReenviarEmail(tce.id, assinante_escola);
              }}
            >
              Reenviar
            </Button>
          </div>
        </Assinante>
      );
    });
  }

  return (
    <Container>
      <h2>Aprovação dos assinantes da escola</h2>
      {renderAssinantes()}
    </Container>
  );
}
