import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  viewEmpresaRequest: ["id"],
  viewEmpresaSuccess: ["data"],
  viewEmpresaFailure: ["error"],
  viewEmpresaReset: [],
});

export const viewEmpresaTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

// Reducers
export const viewEmpresaRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const viewEmpresaSuccess = (state, action) => {
  const { data } = action;
  return update(state, {
    data: { $set: data },
    loading: { $set: false },
    error: { $set: null },
  });
};

export const viewEmpresaFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const viewEmpresaReset = state => {
  return INITIAL_STATE;
}

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.VIEW_EMPRESA_REQUEST]: viewEmpresaRequest,
  [Types.VIEW_EMPRESA_SUCCESS]: viewEmpresaSuccess,
  [Types.VIEW_EMPRESA_FAILURE]: viewEmpresaFailure,
  [Types.VIEW_EMPRESA_RESET]: viewEmpresaReset,
});
