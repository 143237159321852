import { call, put, all, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";

import AuthEditarCredenciaisActions from "@/store/ducks/auth/editar-credenciais";
import AuthActions from "@/store/ducks/auth";

const getAuthData = state => state.auth.data;

export function* updateRequest(action) {
  try {
    const { data: post, history } = action;
    const authData = yield select(getAuthData);

    const { data: retorno } = yield call(
      api.put,
      "auth/update-credentials",
      post,
      makeOptions(authData)
    );
    if (retorno.success) {
      toastify("Credenciais de acesso atualizadas com sucesso");
      yield all([
        put(AuthEditarCredenciaisActions.updateSuccess()),
        put(AuthActions.authLogout(history)),
      ]);
    } else {
      toastify(retorno.message, "error");
      yield put(AuthEditarCredenciaisActions.updateFailure(retorno.message));
    }
  } catch (error) {
    const message = "Erro ao se comunicar com a api";
    toastify(message, "error");
    yield put(AuthEditarCredenciaisActions.updateFailure(message));
  }
}
