import { useContext } from "react";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { EmpresaContext } from "@/context/empresas/empresa";
import api, { makeOptions } from "@/services/api";

import toastify from "@/services/toastify";

const useEmpresa = () => {
  const { data: user } = useSelector(state => state.auth);
  const [state, setState] = useContext(EmpresaContext);

  function _setLoading(loading) {
    setState(state => {
      return update(state, {
        loading: { $set: loading },
      });
    });
  }

  function _setData(data) {
    setState(state => {
      return update(state, {
        data: { $set: data },
      });
    });
  }

  async function fetchEmpresa(empresaId) {
    _setLoading(true);
    const endpoint = `empresas/${empresaId}`;
    const { data: response } = await api.get(endpoint, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
    }
  }

  async function updateEmpresa(empresaId, data) {
    _setLoading(true);
    const endpoint = `empresas/${empresaId}`;
    const { data: response } = await api.put(endpoint, data, makeOptions(user));
    _setLoading(false);
    if (response.success) {
      _setData(response.data);
      toastify("Editado com sucesso!");
    }
  }

  return {
    fetchEmpresa,
    updateEmpresa,
    empresa: state.data,
    loading: state.loading,
  };
};

export { useEmpresa };
