import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { FaCheck } from "react-icons/fa";
import { Row, Col } from "@/components/Grid";
import { Label, Checkbox, IconContainer, DiaContainer, Error } from "./styles";

export default function DiasSemana({ value, onChange, error }) {
  const dias = ["SEG", "TER", "QUA", "QUI", "SEX", "SAB", "DOM"];

  const [diasSemana, setDiasSemana] = useState(value || []);

  useEffect(() => {
    onChange(diasSemana);
  }, [diasSemana]);

  function toggle(dia) {
    const index = diasSemana.findIndex(d => d === dia);
    if (index === -1) {
      setDiasSemana(state => {
        return update(state, {
          $push: [dia],
        });
      });
    } else {
      setDiasSemana(state => {
        return update(state, {
          $splice: [[index, 1]],
        });
      });
    }
  }

  function renderDias() {
    return dias.map((dia, index) => {
      const checked = diasSemana.includes(dia);
      return (
        <Col key={index} xl="1/4">
          <Checkbox onClick={() => toggle(dia)}>
            <IconContainer>
              {checked && <FaCheck color="#888888" size={12} />}
            </IconContainer>
            <DiaContainer>{dia}</DiaContainer>
          </Checkbox>
        </Col>
      );
    });
  }

  return (
    <>
      <Label>Dias da semana {error && <Error>{error}</Error>}</Label>
      <Row hascols>{renderDias()}</Row>
    </>
  );
}
