import styled from "styled-components";

// painel esquerdo
export const SidebarContainer = styled.div`
  width: 195px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;


  @media screen and (max-width: 1100px) {
    width: 100%;
    padding: 0 2rem;
    margin-right: auto;
    align-items: center;
    text-align: center;
  }

  h2 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }

  div.actions {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #b9b7cc;
    padding: 16px 0 24px 0;

    button.editar {
      width: 72px;
      height: 34px;
      border-radius: 17px;
      background: #279cd9;
      color: #fff;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      margin-right: 8px;
      transition: 300ms ease;

      &:hover {
        opacity: 0.7;
      }
    }
    span {
      width: 34px;
      height: 34px;
      border-radius: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px #b9b7cc solid;
      color: #2b0c75;
    }
  }

  div.info {
    border-bottom: 1px solid #b9b7cc;
    padding: 24px 0;
    h5 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 15px;
      font-weight: 600;
      color: #605f6a;
      margin-bottom: 16px;
      svg {
        color: #279cd9;
        margin-right: 8px;
      }
      @media screen and (max-width: 1100px) {
        justify-content: center;
      }
    }
  }

  div.apresentacao {
    padding: 24px 0;
    p {
      font-size: 16px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      line-clamp: 5;
    }
  }
`;

export const Avatar = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 55px;
  background-color: #ffffff;
  overflow: hidden;
  background-image: ${() => ({ src }) => `url(${src})`};
  background-size: cover;
  margin-bottom: 16px;
  cursor: ${() => ({ editable }) => {
      return editable ? "pointer" : "default";
  }}
`;
