import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 64px 64px 8px 64px;
  background: #000000;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  flex-wrap: wrap;

  p {
    font-size: 16px;
    color: #a0a6b1;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  margin-right: 32px;

  img {
    max-width: 200px;
    height: auto;
    margin-bottom: 24px;
  }
`;

export const WrapLinks = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 160px;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none;
    transition: 300ms;
    margin-bottom: 8px;
    color: #fff;
    transition: 300ms ease;

    &:hover {
      color: #fbca01;
    }
  }
`;

export const Title = styled.h5`
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 700;
  color: #fbca01;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Social = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin: 0 8px;
  }
`;

export const TitleSocial = styled(Title)`
  color: #4d65b3;
  margin: 0;
`;
