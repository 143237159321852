import React, { useState } from "react";

const EmpresaContext = React.createContext([{}, () => {}]);

const EmpresaProvider = props => {
  const [state, setState] = useState({
    data: null,
    loading: false
  });
  return (
    <EmpresaContext.Provider value={[state, setState]}>
      {props.children}
    </EmpresaContext.Provider>
  );
};

export { EmpresaContext, EmpresaProvider };
