import React from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { useAvaliacao } from "@/hooks/avaliacoes";
import { Button } from "@/components/Common";
import { Form, Input, Select } from "@/components/Form";
import toastify from "@/services/toastify";

import {
  Header,
  Titulo,
  MultiplaEscolha,
  Textual,
  Pergunta,
} from "./styles";

function ResponderAvaliacao({ avaliacao, history }) {
  const { info, questoes } = avaliacao;
  const { updateAvaliacao, loading } = useAvaliacao();

  const shape = {};
  for (let i = 0; i < questoes.length; i++) {
    const name = `questao_${questoes[i].id}`;
    shape[name] = Yup.string().required("Obrigatório");
  }

  const schema = Yup.object().shape(shape);

  function renderHeader() {
    return (
      <Header>
        <h2>Avaliar estágio</h2>
      </Header>
    );
  }

  function renderTitulo() {
    return (
      <Titulo>
        <h3>{info.vaga.titulo}</h3>
        <h4>Responda às perguntas abaixo e conte-nos sua experiência.</h4>
      </Titulo>
    );
  }

  function renderQuestoes() {
    return questoes.map(q => {
      const { id, questao, opcoes } = q;
      const name = `questao_${id}`;

      if (opcoes !== null) {
        return (
          <MultiplaEscolha>
            <Pergunta>{questao}</Pergunta>
            <div className="opcoes">
              <Select
                name={name}
                options={opcoes.map(o => ({ id: o, title: o }))}
              />
            </div>
          </MultiplaEscolha>
        );
      } else {
        return (
          <Textual>
            <Pergunta>{questao}</Pergunta>
            <Input type="text" multiline name={name} />
          </Textual>
        );
      }
    });
  }

  function handleSubmit(data) {
    updateAvaliacao(avaliacao.id, data, function() {
      toastify("Obrigado por responder à avaliação");
      history.go(-1);
    });
  }

  return (
    <>
      {renderHeader()}
      {renderTitulo()}
      <Form schema={schema} onSubmit={handleSubmit}>
        <div>{renderQuestoes()}</div>
        <Button type="submit" variant="tangerine" loading={loading}>
          Enviar
        </Button>
      </Form>
    </>
  );
}

export default withRouter(ResponderAvaliacao);
