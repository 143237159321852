import { call, put } from "redux-saga/effects";
import api from "@/services/api";
import ListPaisesActions from "@/store/ducks/paises/list";

export function* list() {
  try {
    const { data: response } = yield call(api.get, "utils/paises");
    if (response.success) {
      yield put(ListPaisesActions.listPaisesSuccess(response.data));
    } else {
      yield put(ListPaisesActions.listPaisesFailure(response.message));
    }
  } catch (error) {
    yield put(ListPaisesActions.listPaisesFailure("Erro ao listar paises via api"));
  }
}
