import React from "react";
import { Route, Redirect } from "react-router-dom";

import AlunoPerfil from "@/pages/Aluno/Perfil";
import EmpresaPerfil from "@/pages/Empresa/Perfil";
import EscolaPerfil from "@/pages/Escola/Perfil";

export default function PerfilRoute({ auth, ...rest }) {
  // idenficar se o usuario está logado
  const { authenticated, data } = auth;

  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: "/login" }} />}
      />
    );
  }

  const { tipo, empresa } = data;
  let Component;

  switch(true) {
    case tipo === "aluno":
      Component = AlunoPerfil;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && !empresa.is_escola:
      Component = EmpresaPerfil;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && empresa.is_escola:
      Component = EscolaPerfil;
      break;
    case ["administrador"].includes(tipo):
      Component = <div>Substituir pelo Perfil de root</div>
      break;
  }

  return <Route { ...rest } render={ props=> <Component { ...props } /> } />

}
