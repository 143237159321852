import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { FiMapPin, FiBriefcase } from "react-icons/fi";
import { FaDownload, FaPrint } from "react-icons/fa";
import { osName } from "react-device-detect";

import { useAluno } from "@/hooks/alunos";
import { AlunoProvider } from "@/context/alunos/aluno";
import { Page, DashboardContainer, Row, Col } from "@/components/Grid";
import { FullPageLoader, Button, Modal } from "@/components/Common";
import { AdminMenu, AdminPainel } from "@/components/Admin";
import { Navbar } from "@/components/Blocks";
import { Sidebar } from "@/components/Aluno";

import { telefone, cep, date } from "@/formatters";

import RedesSociais from "./RedesSociais";

import {
  PaineisContainer,
  Content,
  PainelPrincipal,
  TopoInfo,
  ButtonsWrapper,
  Buttons,
  SectionContainer,
  Avatar,
  EscolaAtual,
  FormacoesAnteriores,
  FormacaoAnterior,
  ExperienciasProfissionais,
  ExperienciaProfissional,
  CursosComplementares,
  CursoComplementar,
  AtividadesComplementares,
  AtividadeComplementar,
  Idiomas,
  Idioma,
} from "./styles";

import { BlobProvider } from "@react-pdf/renderer";
import { Document, Viewer } from "@/components/Curriculo";

function _Aluno({ match }) {
  const { fetchAluno, aluno } = useAluno();
  const [modalCurriculo, setModalCurriculo] = useState(false);

  function renderTopo() {
    const local = `${aluno.endereco.cidade.nome}, ${aluno.endereco.cidade.estado.sigla}`;
    return (
      <>
        <h1>{aluno.nome}</h1>
        <Row hascols>
          <Col md="1/2" sm="1">
            <TopoInfo>
              <div className="info">
                <ul>
                  <li>
                    <FiMapPin />
                    {local}
                  </li>
                  <li>
                    <FiBriefcase />
                    {aluno.curso.titulo}
                  </li>
                </ul>
              </div>
            </TopoInfo>
          </Col>
          <Col md="1/2" sm="1">
            <RedesSociais
              redes={{
                facebook: aluno.facebook,
                linkedin: aluno.linkedin,
                instagram: aluno.instagram,
                twitter: aluno.twitter,
              }}
            />
          </Col>
        </Row>
      </>
    );
  }

  function renderVagaController() {
    return (
      <ButtonsWrapper>
        <Buttons>
          <div className="coluna coluna-1">
            <BlobProvider document={<Document aluno={aluno} />}>
              {({ url }) => (
                <Button download="curriculo.pdf" to={url} externalLink>
                  <FaDownload size={13} /> Baixar Currículo
                </Button>
              )}
            </BlobProvider>
            {!["android"].includes(osName.toLowerCase()) && (
              <Button type="button" onClick={() => setModalCurriculo(true)}>
                <FaPrint size={13} /> Imprimir
              </Button>
            )}
          </div>
        </Buttons>
      </ButtonsWrapper>
    );
  }

  function renderDadosPessoais() {
    const { endereco } = aluno;

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Dados pessoais:</h2>
        </div>
        <Row hascols>
          <Col lg="1/3" sm="1">
            <div className="dados-pessoais-box">
              <h4>Sobre</h4>
              <p>
                Data de nascimento: {date(aluno.data_nascimento)}
                <br />
                País de origem: {aluno.pais.nome}
                <br />
                Sexo: {aluno.sexo === "F" ? "Feminino" : "Masculino"}
                <br />
                Portador de deficiência? <br />
                {aluno.pcd !== null ? `Código PCD: ${aluno.pcd}` : "NÃO"}
              </p>
            </div>
          </Col>
          <Col lg="1/3" sm="1">
            <div className="dados-pessoais-box">
              <h4>Endereço</h4>
              {endereco && (
                <p>
                  {endereco.logradouro}, {endereco.numero}, {endereco.bairro}
                  <br />
                  {endereco.cidade.nome}, {endereco.cidade.estado.sigla}
                  <br />
                  CEP: {cep(endereco.cep)}
                </p>
              )}
            </div>
          </Col>
          <Col lg="1/3" sm="1">
            <div className="dados-pessoais-box">
              <h4>Contato</h4>
              <p>
                {telefone(aluno.telefone)}
                <br />
                {aluno.usuario.email}
              </p>
            </div>
          </Col>
        </Row>
      </SectionContainer>
    );
  }

  function renderFormacaoAcademica() {
    function renderFormacaoAtual() {
      const dataInicio = format(new Date(aluno.data_inicio), "MM/yyyy");
      const dataTermino = format(new Date(aluno.data_conclusao), "MM/yyyy");

      return (
        <EscolaAtual>
          <Avatar src={aluno.empresa.imagem} />
          <div className="info">
            <div className="info-titulo">
              <h2>{aluno.curso.titulo}</h2>
              {aluno.empresa.nome_exibicao}
            </div>

            <div className="info-datas">
              <div>
                <span>Início</span> {dataInicio}
              </div>
              <div>
                <span>Previsão de término</span> {dataTermino}
              </div>
            </div>
          </div>
        </EscolaAtual>
      );
    }

    function renderFormacoesAnteriores() {
      return (
        <FormacoesAnteriores>
          {aluno.formacoes_anteriores.map(f => {
            const dataInicio = format(new Date(f.data_inicio), "MM/yyyy");
            const dataTermino = format(new Date(f.data_termino), "MM/yyyy");

            return (
              <FormacaoAnterior key={f.id}>
                <div className="info">
                  <h2>{f.curso_nome}</h2>
                  {f.empresa_nome}
                  <div className="info-datas">
                    <div>
                      <span>Início</span> {dataInicio}
                    </div>
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  </div>
                </div>
              </FormacaoAnterior>
            );
          })}
        </FormacoesAnteriores>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Formação acadêmica:</h2>
        </div>
        {renderFormacaoAtual()}
        {renderFormacoesAnteriores()}
      </SectionContainer>
    );
  }

  function renderExperienciasProfissionais() {
    function renderLista() {
      return (
        <ExperienciasProfissionais>
          {aluno.experiencias_profissionais.map(e => {
            const dataInicio = format(new Date(e.data_inicio), "MM/yyyy");
            const dataTermino = e.data_termino
              ? format(new Date(e.data_termino), "MM/yyyy")
              : null;
            return (
              <ExperienciaProfissional key={e.id}>
                <h2>{e.empresa_nome}</h2>
                <p className="cargo">{e.cargo}</p>
                <div className="datas">
                  <div>
                    <span>Início</span> {dataInicio}
                  </div>
                  {dataTermino && (
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  )}
                </div>
                {e.atividades && (
                  <>
                    <h4>Descrição</h4>
                    <p className="descricao">{e.atividades}</p>
                  </>
                )}
              </ExperienciaProfissional>
            );
          })}
        </ExperienciasProfissionais>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Experiências profissionais:</h2>
        </div>
        {renderLista()}
      </SectionContainer>
    );
  }

  function renderCursosComplementares() {
    function renderLista() {
      return (
        <CursosComplementares>
          {aluno.cursos_complementares.map(c => {
            const dataInicio = format(new Date(c.data_inicio), "MM/yyyy");
            const dataTermino = c.data_termino
              ? format(new Date(c.data_termino), "MM/yyyy")
              : null;

            return (
              <CursoComplementar key={c.id}>
                <h2>{c.curso_nome}</h2>
                <div className="datas">
                  <div>
                    <span>Início</span> {dataInicio}
                  </div>
                  {dataTermino && (
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  )}
                </div>
                {c.curso_descricao && (
                  <>
                    <h4>Descrição</h4>
                    <p className="descricao">{c.curso_descricao}</p>
                  </>
                )}
              </CursoComplementar>
            );
          })}
        </CursosComplementares>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Cursos complementares:</h2>
        </div>
        {renderLista()}
      </SectionContainer>
    );
  }

  function renderAtividadesComplementares() {
    function renderLista() {
      return (
        <AtividadesComplementares>
          {aluno.atividades_complementares.map(a => {
            const dataInicio = format(new Date(a.data_inicio), "MM/yyyy");
            const dataTermino = a.data_termino
              ? format(new Date(a.data_termino), "MM/yyyy")
              : null;

            return (
              <AtividadeComplementar key={a.id}>
                <h2>{a.nome}</h2>
                <div className="datas">
                  <div>
                    <span>Início</span> {dataInicio}
                  </div>
                  {dataTermino && (
                    <div>
                      <span>Término</span> {dataTermino}
                    </div>
                  )}
                </div>
                {a.descricao && (
                  <>
                    <h4>Descrição</h4>
                    <p className="descricao">{a.descricao}</p>
                  </>
                )}
              </AtividadeComplementar>
            );
          })}
        </AtividadesComplementares>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Atividades complementares:</h2>
        </div>
        {renderLista()}
      </SectionContainer>
    );
  }

  function renderIdiomas() {
    function renderLista() {
      return (
        <Idiomas>
          {aluno.idiomas.map(idioma => {
            const { id, nome, pivot } = idioma;
            return (
              <Idioma key={id}>
                <h2>{nome}</h2>
                <p>{pivot.proficiencia}</p>
              </Idioma>
            );
          })}
        </Idiomas>
      );
    }

    return (
      <SectionContainer>
        <div className="titulo">
          <h2>Idiomas:</h2>
        </div>
        {renderLista()}
      </SectionContainer>
    );
  }

  useEffect(() => {
    const { alunoId } = match.params;
    fetchAluno(alunoId);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      <AdminMenu />
      {!aluno && <FullPageLoader />}
      {aluno && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <PainelPrincipal>
                <>
                  {renderTopo()}
                  {renderVagaController()}
                  {renderDadosPessoais()}
                  {renderFormacaoAcademica()}
                  {renderExperienciasProfissionais()}
                  {renderCursosComplementares()}
                  {renderAtividadesComplementares()}
                  {renderIdiomas()}
                </>
              </PainelPrincipal>
              <AdminPainel modo="aluno" id={aluno.id} />
            </Content>
          </PaineisContainer>
          <Modal
            open={modalCurriculo}
            onClose={() => setModalCurriculo(false)}
            padding={"0"}
          >
            <Viewer aluno={aluno} onClose={() => setModalCurriculo(false)} />
          </Modal>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function Aluno({ match, history }) {
  return (
    <AlunoProvider>
      <_Aluno match={match} history={history} />
    </AlunoProvider>
  );
}
