import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import {
  AdminMenuWrapper,
  MainMenu,
  CloseButton,
  MenuHandler,
  MenuLabel,
  ActiveIndicator,
  SubMenu,
  SubMenuBackdrop,
  SubMenuSections,
  Section,
  SectionTitle,
  SectionOption,
} from "./styles";

function AdminMenu({ history }) {
  const [activeMainMenu, setActiveMainMenu] = useState(null);
  const subMenuRef = useRef();

  function handleClickOutside(event) {
    const { target } = event;
    if (subMenuRef.current && !subMenuRef.current.contains(target)) {
      setActiveMainMenu(null);
    }
  }

  const menus = [
    {
      label: "Gerenciar Empresas",
      sections: [
        {
          title: "Empresas",
          options: [
            {
              label: "Todas as empresas",
              onClick: () => handleNavigate("/franqueado/empresas"),
            },{
              label: "Cadastrar nova empresa",
              onClick: () => handleNavigate("/franqueado/cadastro-empresa"),
            },
            
          ],
        },
      ],
    },
  ];

  function handleNavigate(pathname) {
    setActiveMainMenu(null);
    history.push(pathname);
  }

  function renderMainMenu() {
    return (
      <MainMenu>

        <MenuHandler>
          <MenuLabel
            onClick={() => handleNavigate("/franqueado/vagas")}
          >
            Vagas
          </MenuLabel>
        </MenuHandler>

        {menus.map((menu, i) => {
          return (
            <MenuHandler key={i}>
              <MenuLabel onClick={() => setActiveMainMenu(menu.label)}>
                {menu.label}
              </MenuLabel>
              {activeMainMenu == menu.label && <ActiveIndicator />}
            </MenuHandler>
          );
        })}
      </MainMenu>
    );
  }

  function renderSubMenu() {
    if (!activeMainMenu) return <></>;

    const sections = menus.filter(menu => menu.label === activeMainMenu)[0]
      .sections;

    return (
      <SubMenu ref={subMenuRef}>
        <CloseButton onClick={() => setActiveMainMenu(null)}>
          &times;
        </CloseButton>
        <SubMenuSections>
          {sections.map((section, i) => {
            const { options, title } = section;
            const sectionOptions = options.map((option, j) => {
              return (
                <SectionOption
                  key={j}
                  onClick={() => {
                    option.onClick();
                  }}
                >
                  {option.label}
                </SectionOption>
              );
            });

            return (
              <Section key={i}>
                {title && <SectionTitle>{title}</SectionTitle>}
                <ul>{sectionOptions}</ul>
              </Section>
            );
          })}
        </SubMenuSections>
      </SubMenu>
    );
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <AdminMenuWrapper>
      {renderMainMenu()}
      {renderSubMenu()}
    </AdminMenuWrapper>
  );
}

export default withRouter(AdminMenu);
