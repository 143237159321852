import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

export const EntrarContainer = styled.div`
  max-width: 450px;
  width: 100%;
  min-height: 100vh;
  padding-top: 92px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CardWrapper = styled.div`
  width: 100%;
  text-align: center;
  h1 {
    font-size: 32px;
    color: #000000;
  }
  p {
    font-size: 18px;
    margin-bottom: 30px;
  }
`;
