import styled from "styled-components";
import { Card } from "@/components/Common";

export const PaineisContainer = styled.div`
  /* margin-top: 50px; */
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1100px){
    flex-direction: column;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;
  margin-bottom: 32px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }

  h2.nome-exibicao{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 28px;
    font-weight: 600;
    color: #271946;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      border: 2px #b9b7cc solid;
      color: #279cd9;
      margin-left: 8px;
      transition: 300ms ease;
      font-size: 16px;

      &:hover {
        color: #fff;
        background: #279cd9;
        border-color: #279cd9;
      }
    }
  }

  div.info {
    display: flex;
    margin: 24px 0 32px 0;
    font-size: 18px;
    p {
      &:not(:last-child) {
        margin-right: 35px;
      }
      svg {
        color: #279cd9;
        margin-right: 9px;
        font-size: 16px;
      }
    }
  }

  div.apresentacao {
    margin-bottom: 32px;
    h5 {
      font-size: 16px;
      font-weight: 700;
      color: #000;
      margin-bottom: 16px;
    }
  }

  div.contato {
    display: flex;
    flex-direction: row;
    > div {
      > div {
        margin-right: 72px;
      }
      h4 {
        color: #f78764;
        text-transform: uppercase;
        margin-bottom: 14px;
        letter-spacing: 2px;
      }
    }
  }
`;
