import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { format, parse } from "date-fns";

import { useVaga } from "@/hooks/vagas";
import { Modal, Button } from "@/components/Common";
import { Row, Col } from "@/components/Grid";
import { Form, Input } from "@/components/Form";

import {
  GroupButtons,
  ButtonFinaliza,
  ButtonCancelar,
  ModalContent,
  ModalBody,
  ModalFooter,
} from "./styles";

function FecharReabrirVaga({ history }) {
  const { updateVaga, cancelarVaga, vaga, loading } = useVaga();
  const [modalFinalizar, setModalFinalizar] = useState(false);
  const [modalReabrir, setModalReabrir] = useState(false);
  const [modalCancelar, setModalCancelar] = useState(false);

  function handleFinalizar() {
    updateVaga(vaga.id, { quantidade: 0 });
  }

  function handleReabrir(data) {
    updateVaga(vaga.id, { quantidade: data.quantidade });
  }

  function handleCancelar() {
    cancelarVaga(vaga.id, function() {
      history.push("/vagas");
    });
  }

  const schema = Yup.object().shape({
    quantidade: Yup.number()
      .typeError("Qauntidade inválida")
      .min(1, "Valor mínimo é 1"),
  });

  useEffect(() => {
    if (!loading) {
      setModalFinalizar(false);
      setModalReabrir(false);
      setModalCancelar(false);
    }
  }, [loading, setModalReabrir, setModalFinalizar]);

  return (
    <>
      {vaga.cancelamento_data === null ? (
        <>
          <GroupButtons>
            {vaga.quantidade > 0 ? (
              <ButtonFinaliza onClick={() => setModalFinalizar(true)}>
                Finalizar Vaga
              </ButtonFinaliza>
            ) : (
              <ButtonFinaliza onClick={() => setModalReabrir(true)}>
                Reativar Vaga
              </ButtonFinaliza>
            )}
            <ButtonCancelar onClick={() => setModalCancelar(true)}>
              Cancelar vaga
            </ButtonCancelar>
          </GroupButtons>
          <p>
            Ao Finalizar, a vaga estará indisponível para novas candidaturas e
            consultas. No entanto, você poderá publicá-la posteriormente.
          </p>
        </>
      ) : (
        <p>
          Esta vaga foi cancelada em{" "}
          {format(
            parse(vaga.cancelamento_data, "yyyy-MM-dd HH:mm:ss", new Date()),
            "dd/MM/yyyy"
          )}
        </p>
      )}

      <Modal open={modalFinalizar} onClose={() => setModalFinalizar(false)}>
        <ModalContent>
          <ModalBody>
            <h1>Esta ação impedirá que estudantes encontrem esta vaga</h1>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="magenta"
              onClick={handleFinalizar}
              loading={loading}
            >
              FINALIZAR VAGA
            </Button>
            <Button variant="cancel" onClick={() => setModalFinalizar(false)}>
              CANCELAR
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal open={modalReabrir} onClose={() => setModalReabrir(false)}>
        <ModalContent>
          <Form onSubmit={handleReabrir} schema={schema}>
            <ModalBody>
              <h1>Esta ação permitirá que estudantes encontrem esta vaga</h1>
              <Row>
                <Col>
                  <Input
                    type="number"
                    label="Deseja reativar esta vaga com quantos postos?"
                    name="quantidade"
                    min="1"
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" variant="magenta" loading={loading}>
                REABRIR VAGA
              </Button>
              <Button variant="cancel" onClick={() => setModalReabrir(false)}>
                CANCELAR
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>

      <Modal open={modalCancelar} onClose={() => setModalCancelar(false)}>
        <ModalContent>
          <ModalBody>
            <h1>Tem certeza de que deseja cancelar esta vaga?</h1>
            <p>O cancelamento da vaga acarretará no seguinte:</p>
            <ul>
              <li>Cancelamento por parte do concedente de qualquer contrato relacionado a esta vaga e que ainda não tenha sido concretizado.</li>
              <li>Remoção das candidaturas à esta vaga (apenas candidaturas que não se converteram em um contrato concretizado)</li>
              <li>A vaga não será listada no sistema de busca de vagas. A url para a mesma continuará funcionando, porém apenas uma visão parcial da vaga será exibida.</li>
            </ul>
            <p>Caso pretenda apenas impedir novas candidaturas, utilize a opção <span>Finalizar vaga</span></p>
          </ModalBody>
          <ModalFooter>
            <Button variant="magenta" loading={loading} onClick={handleCancelar}>
              CANCELAR VAGA
            </Button>
            <Button variant="cancel" onClick={() => setModalCancelar(false)}>
              CANCELAR
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default withRouter(FecharReabrirVaga);
