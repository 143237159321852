import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { SupervisoresProvider } from "@/context/supervisores/supervisores";
import { useEmpresa } from "@/hooks/empresas";
import { useSupervisores } from "@/hooks/supervisores";

import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";
import { Sidebar, SubMenu } from "@/components/Escola";

import {
  SupervisoresList,
  SupervisorCreateForm,
} from "@/components/Empresa/Supervisores";

import {
  PaineisContainer,
  PainelPrincipal,
  Content,
  MenuInterno,
} from "./styles";

function _Supervisores() {
  const { data: user } = useSelector(state => state.auth);

  const { fetchEmpresa, empresa } = useEmpresa();
  const { fetchSupervisores } = useSupervisores();

  useEffect(() => {
    fetchEmpresa(user.empresa.id);
    fetchSupervisores(user.empresa.id);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      {!empresa && <FullPageLoader />}
      {empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                <SubMenu type="supervisor" />
              </MenuInterno>
              <PainelPrincipal>
                <h1>Supervisores</h1>
                <Tabs forceRenderTabPanel={true}>
                  <TabList>
                    <Tab>Todos os supervisores</Tab>
                    <Tab>Cadastrar supervisor</Tab>
                  </TabList>
                  <TabPanel>
                    <SupervisoresList />
                  </TabPanel>
                  <TabPanel>
                    <SupervisorCreateForm />
                  </TabPanel>
                </Tabs>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function Supervisores() {
  return (
    <EmpresaProvider>
      <SupervisoresProvider>
        <_Supervisores />
      </SupervisoresProvider>
    </EmpresaProvider>
  );
}
