import * as Yup from "yup";

const schemaStep1 = Yup.object().shape({
  cpf: Yup.string().required("Obrigatório"),
  nome: Yup.string().required("Obrigatório"),
  comissao: Yup.number().required("Obrigatório"),
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  telefone: Yup.string().required("Obrigatório"),
  cidades: Yup.string().required("Obrigatório"),
});

const schemaStep2 = Yup.object().shape({
  usuario: Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("Obrigatório"),
    email_c: Yup.string()
      .email("E-mail inválido")
      .test("email-match", "Confirme seu email", function(value) {
        return this.parent.email === value;
      }),
    senha: Yup.string().required("Obrigatório"),
    senha_c: Yup.string()
      .required("Obrigatório")
      .test("senha-match", "Confirme sua senha", function(value) {
        return this.parent.senha === value;
      }),
  }),
});

export { schemaStep1, schemaStep2 };
