import React, { useEffect, useState, useRef } from "react";
import { ModalOverlay, ModalWrapper } from "./styles";

export default function Modal({ open, onClose, children, ...rest }) {

  const [modalOpen, setModalOpen] = useState(false);
  const container = useRef();

  const _open = ![false, undefined].includes(open);
  const _body = [...document.getElementsByTagName("body")][0];

  useEffect(() => {
    document.addEventListener("mousedown", _handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
      _body.classList.remove("modal-open");
    };
  },[]);

  useEffect(() => {
    if (modalOpen) {
      _body.classList.add("modal-open");
    } else {
      _body.classList.remove("modal-open");
    }
  }, [modalOpen]);

  useEffect(() => {
    setModalOpen(_open);
  }, [_open]);


  function _handleClickOutside(event) {
    if (container.current && !container.current.contains(event.target)) {
      // _closeModal();
    }
  }

  // function _closeModal() {
  //   setModalOpen(false);
  //   onClose();
  // }

  return (
    <ModalOverlay ref={container} open={modalOpen || undefined} { ...rest }>
      <ModalWrapper>{children}</ModalWrapper>
    </ModalOverlay>
  );
}
