import React, { useState, useEffect } from "react";
import { Input as RocketInput } from "@rocketseat/unform";
import { InputContainer, LengthInfo } from "../styles";

export default function Input({
  type = "text",
  label,
  placeholder,
  name,
  multiline,
  rows,
  rounded,
  maxLength,
  showLength,
  onChange,
  value,
  ...rest
}) {

  const [currentLength, setCurrentLength] = useState(state => {
    if (value) return value.toString().length;
    return 0;
  });


  function handleOnChange(event) {
    const { value } = event.target;
    setCurrentLength(state => {
      return value.toString().length;
    });
    if (onChange) onChange(event);
  }


  return (
    <>{type !== "hidden" && (
      <InputContainer label={label} rounded={rounded}>
        {label && <label>{label}</label>}
        {maxLength && showLength && (
          <LengthInfo>{currentLength}/{maxLength}</LengthInfo>
        )}
        <RocketInput
          type={type}
          name={name}
          placeholder={placeholder}
          multiline={multiline}
          rows={rows}
          onChange={handleOnChange}
          value={value}
          maxLength={maxLength}
          {...rest}
        />
      </InputContainer>
    ) || (
      <RocketInput
        type="hidden"
        name={name}
        {...rest}
      />
    )}
    </>
  );
}
