import React, { useEffect, useState, useRef } from "react";
import { Container, Handler, Content } from "./styles";

export default function Dropdown({ handler, content, dismissOnClick }) {

  /**
   * IMPORTANTE!!!
   * Use a propriedade pointer-events: all
   * no elemento passado pelo parametro content
   */

  const [open, setOpen] = useState(false);
  const container = useRef();



  function handleClick(event) {
    setOpen(!open);
  }

  function handleClickOutside(event) {
    if (container.current && !container.current.contains(event.target)) {
      setOpen(false);
    }
  }

  function checkIfNeedsDismiss() {
    if (dismissOnClick !== undefined) {
      setTimeout(function() {
        setOpen(false);
      }, 200);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, []);

  return (
    <Container ref={container}>
      <Handler onClick={() => handleClick()}>{handler}</Handler>
      <Content open={open} onClick={checkIfNeedsDismiss}>
        {content}
      </Content>
    </Container>
  )
}
