import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "@/components/Common";
import api, { makeOptions } from "@/services/api";

import { ModalContent, ModalFooter } from "./styles";

export default function ModalContratar({
  alunoId,
  vagaId,
  valor_contrato,
  onClose,
  onComplete,
}) {
  const { data: user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);

  async function createTCE() {
    const data = {
      aluno_id: alunoId,
      vaga_id: vagaId,
    };

    setLoading(true);
    const { data: response } = await api.post("tces", data, makeOptions(user));
    setLoading(false);
    if (response.success) {
      onComplete();
      onClose();
    }
  }

  return (
    <ModalContent>
      <h2>Contratar este candidato para esta vaga?</h2>

      <p>
        Atenção: o uso da plataforma é livre para empresas somente até a
        contratação. Após, o serviço tem o custo mensal de R${valor_contrato}{" "}
        por mês por estudante contratado, enquanto durar a contratação. O seguro
        obrigatório previsto na Lei 11.788/08 já está incluso neste valor. Para
        escolas não concedentes e estudantes o uso é gratuito. Mais informações
        podem ser vistas{" "}
        <Link to="/termos" target="_blank">
          aqui
        </Link>{" "}
        ou em nossos canais de atendimento.
      </p>
      <ModalFooter>
        <Button
          type="button"
          block
          variant="tangerine"
          loading={loading || undefined}
          onClick={createTCE}
        >
          Contratar candidato
        </Button>
        <Button type="button" block variant="cancel" onClick={() => onClose()}>
          Cancelar
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
