import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  position: relative;
  width: calc(100% / 3);
  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 32px;
  }
  h4 {
    color: #F78764;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
  }

  div.actions {
    position: absolute;
    top: 0px;
    right: 16px;
    div.menu {
      opacity: 0;
      color: #707070;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    div.actions div.menu {
      opacity: 1;
    }
  }
`;

export const CircleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px #b9b7cc solid;
  color: #279cd9;
  margin-left: 8px;
  transition: 300ms ease;
  font-size: 16px;
  background-color: transparent;
  &:hover {
    color: #fff;
    background: #279cd9;
    border-color: #279cd9;
  }
`;

export const MenuContainer = styled.div.attrs({
  className: "menu-container",
})`
  position: absolute;
  top: 0;
  right: 46px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 8px 0px;
  border-radius: 4px;
  min-width: 150px;
  text-align: right;

  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 9;
  .option {
    color: #423b8d;
    /* font-weight: bold; */
    text-decoration: none;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 16px;
    &-danger {
      color: #f78764;
    }
  }
`;
