import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { EmpresaProvider } from "@/context/empresas/empresa";
import { useEmpresa } from "@/hooks/empresas";

import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";
import { Sidebar, SubMenu } from "@/components/Escola";

import ListarAlunos from "./ListarAlunos";
import ListarAlunosTceAtivo from "./ListarAlunosTceAtivo";
import ListarAlunosTcePendente from "./ListarAlunosTcePendente";

import {
  PaineisContainer,
  PainelPrincipal,
  Content,
  MenuInterno,
} from "./styles";

function _Alunos() {
  const { data: user } = useSelector(state => state.auth);

  const { fetchEmpresa, empresa } = useEmpresa();

  useEffect(() => {
    fetchEmpresa(user.empresa.id);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      {!empresa && <FullPageLoader />}
      {empresa && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                <SubMenu type="alunos" />
              </MenuInterno>
              <PainelPrincipal>
                <h1>Estudantes</h1>
                <Tabs forceRenderTabPanel={true}>
                  <TabList>
                    <Tab>Todos os estudantes</Tab>
                    <Tab>Estudantes com estágio ativo</Tab>
                    <Tab>Estudantes em processo de contratação</Tab>
                  </TabList>
                  <TabPanel>
                    <ListarAlunos />
                  </TabPanel>
                  <TabPanel>
                    <ListarAlunosTceAtivo />
                  </TabPanel>
                  <TabPanel>
                    <ListarAlunosTcePendente />
                  </TabPanel>
                </Tabs>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function Alunos() {
  return (
    <EmpresaProvider>
      <_Alunos />
    </EmpresaProvider>
  );
}
