import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Page, DashboardContainer } from "@/components/Grid";
import { Navbar } from "@/components/Blocks";
import { FullPageLoader } from "@/components/Common";
import { useAluno } from "@/hooks/alunos";
import {
  Sidebar,
  SubMenu,
  ListarVagas,
  ListarMinhasCandidaturas,
} from "@/components/Aluno";
import { AlunoProvider } from "@/context/alunos/aluno";

import {
  PaineisContainer,
  Content,
  MenuInterno,
  PainelPrincipal,
} from "./styles";

function _AlunoVagas() {
  const { data: user } = useSelector(state => state.auth);
  const { fetchAluno, aluno } = useAluno();

  useEffect(() => {
    fetchAluno(user.perfil_id);
  }, []);

  return (
    <Page>
      <Navbar type="interna" />
      {!aluno && <FullPageLoader />}
      {aluno && (
        <DashboardContainer>
          <PaineisContainer>
            <Sidebar />
            <Content>
              <MenuInterno>
                <SubMenu type="vagas" />
              </MenuInterno>
              <PainelPrincipal>
                <h1>Vagas de estágio</h1>
                <Tabs forceRenderTabPanel={true}>
                  <TabList>
                    <Tab>Vagas abertas</Tab>
                    <Tab>Minhas candidaturas</Tab>
                  </TabList>
                  <TabPanel>
                    <ListarVagas />
                  </TabPanel>
                  <TabPanel>
                    <ListarMinhasCandidaturas />
                  </TabPanel>
                </Tabs>
              </PainelPrincipal>
            </Content>
          </PaineisContainer>
        </DashboardContainer>
      )}
    </Page>
  );
}

export default function AlunoVagas({ history }) {
  return (
    <AlunoProvider>
      <_AlunoVagas history={history} />
    </AlunoProvider>
  );
}
