import { call, put, select } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import AuthActions from "@/store/ducks/auth";

const getAuthData = state => state.auth.data;

/** Logar o usuario */
export function* login(action) {
  try {
    const { data, history } = action;
    const { data: retorno } = yield call(api.post, "auth", data);
    if (retorno.success) {
      const { tipo } = retorno.data;

      yield put(AuthActions.authSuccess(retorno.data));
      if (tipo === "administrador") {
        history.push("/admin/indicadores/totais");
      } else if (tipo === "franqueado") {
        history.push("/franqueado/vagas");
      } else {
        history.push("/perfil");
      }
      toastify("Bem vindo ao Integrador.app");
    } else {
      toastify(retorno.message, "error");
      yield put(AuthActions.authFailure(retorno.errors));
    }
  } catch (error) {
    yield put(
      AuthActions.authFailure({
        api_failure: "Não foi possível se comunicar com a API",
      })
    );
  }
}

/** Obter informações atualizadas sobre o usuário logado */
export function* fetchMe() {
  try {
    const authData = yield select(getAuthData);

    const isRootControlled = authData.hasOwnProperty("rootProfile");

    const { data: response } = yield call(
      api.get,
      "auth",
      makeOptions(authData)
    );

    if (response.success) {
      if (isRootControlled) {
        yield put(
          AuthActions.authSuccess(
            Object.assign(response.data, { rootProfile: authData.rootProfile })
          )
        );
      } else {
        yield put(AuthActions.authSuccess(response.data));
      }
    } else {
      yield put(AuthActions.authFailure(response.errors));
    }
  } catch (e) {
    yield put(
      AuthActions.authFailure({
        api_failure: "Não foi possível se comunicar com a API",
      })
    );
  }
}

export function* gerarRecover(action) {
  try {
    const { data, onSuccess } = action;
    const { data: response } = yield call(
      api.post,
      "auth/gerar-recuperacao-senha",
      data
    );
    if (response.success) {
      yield put(AuthActions.authGerarRecoverSuccess());
      toastify(
        "E-mail de recuperação de senha enviado. Cheque sua caixa de entrada."
      );
      if (onSuccess) onSuccess();
    } else {
      toastify(response.message, "error");
      yield put(AuthActions.authGerarRecoverFailure(response.message));
    }
  } catch (error) {
    yield put(
      AuthActions.authGerarRecoverFailure(
        "Erro ao solicitar recuperação de senha"
      )
    );
    toastify("Erro ao solicitar recuperação de senha", "error");
  }
}

/** Recuperar senha de usuário */
export function* recover(action) {
  try {
    const { data, history } = action;
    const { data: retorno } = yield call(
      api.post,
      "auth/recuperar-senha",
      data
    );
    if (retorno.success) {
      yield put(AuthActions.authSuccess(retorno.data));
      history.push("/perfil");
      toastify("Bem vindo ao Integrador.app");
    } else {
      yield put(AuthActions.authFailure(retorno.errors));
      toastify(
        "Erro ao completar seu cadastro. Verifique seu link de recuperação de senha",
        "error"
      );
    }
  } catch (error) {
    yield put(
      AuthActions.authFailure({
        api_failure: "Não foi possível se comunicar com a API",
      })
    );
  }
}

/** Deslogar o usuário */
export function* logout(action) {
  const { history } = action;
  history.push("/");
}

/** Assumir controle de outro usuário */
export function* assumirControle(action) {
  try {
    const { data, history } = action;
    const authData = yield select(getAuthData);
    const { data: response } = yield call(
      api.post,
      "auth-assumir-controle",
      data,
      makeOptions(authData)
    );
    if (response.success) {
      const rootProfile = authData;
      const user = Object.assign(response.data, { rootProfile });
      yield put(AuthActions.authAssumirControleSuccess(user));

      try {
        if (user.tipo === "administrador") {
          history.push("/admin/indicadores/totais");
        } else if (user.tipo === "franqueado") {
          history.push("/franqueado/empresas");
        } else {
          if(data.vaga_id > 0) {
            // history.push("/perfil");
            history.push(`/vaga/${data.vaga_id}`);
          } else {
            history.push("/perfil");
          }
        }
      } catch (error) {
        console.log(error);
      }
      // setTimeout(function() {
      // }, 500);
    } else {
      yield put(AuthActions.authAssumirControleFailure(response.errors));
    }
  } catch (error) {
    yield put(AuthActions.authAssumirControleFailure(error));
  }
}

export function* abandonarControle(action) {
  const { history } = action;
  try {
    const authData = yield select(getAuthData);
    const { rootProfile } = authData;
    yield put(AuthActions.authSuccess(rootProfile));

    try {
      if (rootProfile.tipo === "administrador") {
        history.push("/admin/indicadores/totais");
      } else if (rootProfile.tipo === "franqueado") {
        history.push("/franqueado/empresas");
      } else {
        history.push("/perfil");
      }
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* checkToken(action) {
  try {
    const { history } = action;
    const authData = yield select(getAuthData);

    const isRootControlled = authData.hasOwnProperty("rootProfile");
    const options = isRootControlled ? authData.rootProfile : authData;

    const { data: response } = yield call(
      api.get,
      "auth/check-token",
      makeOptions(options)
    );

    if (!response.success) {
      yield put(AuthActions.authLogout(history));
      toastify("Encerrando sua sessão", "error");
    }
  } catch (error) {
    console.log(error);
  }
}
