import React from "react";
import { Route } from "react-router-dom";

import EmpresaVagas from "@/pages/Empresa/Vagas/Vagas";
import AlunoVagas from "@/pages/Aluno/Vagas/Vagas";
import GuestVagas from "@/pages/Guest/Vagas/Vagas";

export default function VagasRoute({ auth, ...rest }) {
  // idenficar se o usuario está logado
  const { authenticated, data } = auth;

  let Component;
  if (!authenticated) {
    Component = GuestVagas;
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  const { tipo, empresa } = data;
  switch (true) {
    case tipo === "aluno":
      Component = AlunoVagas;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && !empresa.is_escola:
      Component = EmpresaVagas;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && empresa.is_escola:
      // Component = EmpresaVagas;
      break;
    case ["administrador"].includes(tipo):
      Component = <div>Root: Vagas</div>;
      break;
    case ["franqueado"].includes(tipo):
      Component = <div>Root: Vagas</div>;
      break;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
