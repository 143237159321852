import styled from "styled-components";
import { Card } from "@/components/Common";
import noCandidatos from "@/assets/images/icons/no-candidatos.png";

export const PaineisContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const MenuInterno = styled.nav`
  display: flex;
  flex-direction: column;
`;

// painel direito
export const PainelPrincipal = styled(Card).attrs({
  flex: "flex",
  shadow: "shadow",
})`
  flex: 1;
  font-size: 16px;

  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
`;

export const PainelPrincipalTopo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  h2 {
    font-size: 20px;
    font-weight: 400;
    color: #000;
    padding-bottom: 16px;
    border-bottom: 1px #e7e6ed solid;
    margin-bottom: 32px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #605f6a;
    margin-bottom: 32px;
  }
`;

export const GroupCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ButtonVerLista = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 100%;
  max-width: 264px;
  margin-bottom: 16px;

  &:hover {
    span {
      transform: scale(0.9);
    }
    p {
      opacity: 0.5;
    }
  }
`;

export const Icon = styled.span`
  display: flex;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
  background: #f78764;
  margin-right: 8px;
  transition: 300ms ease;
`;

export const Label = styled.small`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  transition: 300ms ease;
`;

export const Filtros = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    color: #605f6a;
  }
`;

export const GroupList = styled.div`
  margin: 32px 0;
`;

export const NotFound = styled.div`
  background-color: #f5f5f5;
  padding: 24px 24px 24px 116px;
  width: 100%;
  height: 64px;
  border-radius: 32px;
  color: #605f6a;
  font-size: 16px;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 54px;
    height: 60px;
    position: absolute;
    background: url(${noCandidatos}) no-repeat center center;
    left: 32px;
    top: -12px;
  }
`;
