import React from "react";
import { Route, Redirect } from "react-router-dom";

import AdminTCE from "@/pages/Admin/Documentos/TCE";
import EmpresaTCE from "@/pages/Empresa/Documentos/TCE";
import AlunoTCE from "@/pages/Aluno/Documentos/TCE";

export default function TCEsRoute({ auth, ...rest }) {
  // idenficar se o usuario está logado
  const { authenticated, data } = auth;

  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: "/login" }} />}
      />
    );
  }

  const { tipo } = data;
  let Component;

  switch (true) {
    case ["administrador"].includes(tipo):
      Component = AdminTCE;
      break;
    case ["funcionario"].includes(tipo):
      Component = EmpresaTCE;
      break;
    case ["aluno"].includes(tipo):
      Component = AlunoTCE;
      break;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
