import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators } = createActions({
  updateRequest: ["data", "history"],
  updateSuccess: [],
  updateFailure: ["error"],
  reset: [],
}, {
  prefix: "AUTH_EDITAR_CREDENCIAIS_"
});

export const AuthEditarCredenciaisTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  loading: false,
  error: null
};

export const updateRequest = state =>
  update(state, {
    loading: { $set: true },
    error: { $set: null }
  });

export const updateSuccess = state =>
  update(state, {
    loading: { $set: false },
  });

export const updateFailure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error }
  });

export const reset = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_REQUEST]: updateRequest,
  [Types.UPDATE_SUCCESS]: updateSuccess,
  [Types.UPDATE_FAILURE]: updateFailure,
  [Types.RESET]: reset,
});
