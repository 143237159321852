import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  authRequest: ["data", "history"],
  authSuccess: ["data"],
  authFailure: ["error"],
  authCheckToken: ["history"],
  authLogout: ["history"],
  authReset: [],
  // Me
  authFetchMe: [], // obtendo minhas informações
  // Recuperar senha
  authGerarRecoverRequest: ["data", "onSuccess"],
  authGerarRecoverSuccess: [],
  authGerarRecoverFailure: ["error"],
  authRecover: ["data", "history"],
  // exclusivo para o admin
  authAssumirControleRequest: ["data", "history"],
  authAssumirControleSuccess: ["data"],
  authAssumirControleFailure: ["error"],
  authAbandonarControle: ["history"], // abandonar controle da entidade
});

export const authTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  data: null,
  authenticated: false,
  loading: false,
  error: null,
};

// Reducers
export const authRequest = state => {
  return update(state, {
    authenticated: { $set: false },
    loading: { $set: true },
    error: { $set: null },
  });
};

export const authSuccess = (state, action) => {
  const { data } = action;
  return update(state, {
    data: { $set: data },
    authenticated: { $set: true },
    loading: { $set: false },
    error: { $set: null },
  });
};

export const authFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const authCheckToken = state => {
  return state;
};

// desloga
export const authLogout = state => {
  return INITIAL_STATE;
};

// apenas reseta sem deslogar
export const authReset = state => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

// Buscando dados atualizados sobre o usuário logado
export const authFetchMe = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

// Recuperando senha
export const authGerarRecoverRequest = state => {
  return update(state, {
    data: { $set: null },
    authenticated: { $set: false },
    loading: { $set: true },
    error: { $set: null },
  });
};
export const authGerarRecoverSuccess = state => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};
export const authGerarRecoverFailure = (state, action) => {
  return update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });
};

export const authRecover = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};
//

export const authAssumirControleRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const authAssumirControleSuccess = (state, action) => {
  const { data } = action;
  return update(state, {
    data: { $set: data },
    authenticated: { $set: true },
    loading: { $set: false },
    error: { $set: null },
  });
};

export const authAssumirControleFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

export const authAbandonarControle = state => {
  return update(state, {
    loading: { $set: true },
  });
};

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.AUTH_REQUEST]: authRequest,
  [Types.AUTH_SUCCESS]: authSuccess,
  [Types.AUTH_FAILURE]: authFailure,
  [Types.AUTH_CHECK_TOKEN]: authCheckToken,
  [Types.AUTH_LOGOUT]: authLogout,
  [Types.AUTH_RESET]: authReset,
  // Me
  [Types.AUTH_FETCH_ME]: authFetchMe,
  // Recuperação de senha
  [Types.AUTH_GERAR_RECOVER_REQUEST]: authGerarRecoverRequest,
  [Types.AUTH_GERAR_RECOVER_SUCCESS]: authGerarRecoverSuccess,
  [Types.AUTH_GERAR_RECOVER_FAILURE]: authGerarRecoverFailure,
  [Types.AUTH_RECOVER]: authRecover,
  // exclusivo para o admin
  [Types.AUTH_ASSUMIR_CONTROLE_REQUEST]: authAssumirControleRequest,
  [Types.AUTH_ASSUMIR_CONTROLE_SUCCESS]: authAssumirControleSuccess,
  [Types.AUTH_ASSUMIR_CONTROLE_FAILURE]: authAssumirControleFailure,
  [Types.AUTH_ABANDONAR_CONTROLE]: authAbandonarControle,
});
