import React from "react";
import { Route, Redirect } from "react-router-dom";

import EmpresaVagaPerfilAluno from "@/pages/Empresa/Vagas/VagaPerfilAluno";

export default function VagaPerfilAlunoRoute({ auth, ...rest }) {
  const { authenticated, data } = auth;

  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: "/login" }} />}
      />
    );
  }

  const { tipo, empresa } = data;
  let Component;

  switch (true) {
    case tipo === "aluno":
      Component = <div>Erro 404</div>;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && !empresa.is_escola:
      Component = EmpresaVagaPerfilAluno;
      break;
    case ["funcionario", "supervisor"].includes(tipo) && empresa.is_escola:
      Component = EmpresaVagaPerfilAluno;
      break;
    case ["administrador"].includes(tipo):
      Component = <div>Root: Perfil do aluno para a vaga</div>;
      break;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
