import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

// Types e Actions creators
const { Types, Creators } = createActions({
  createObservacaoRequest: ["data", "onSuccess"],
  createObservacaoSuccess: [""],
  createObservacaoFailure: ["error"],
});

export const createObservacaoTypes = Types;
export default Creators;

// Initial state
export const INITIAL_STATE = {
  loading: false,
  error: null,
};

// Reducers
export const createObservacaoRequest = state => {
  return update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
};

export const createObservacaoSuccess = state => {
  return update(state, {
    loading: { $set: false },
    error: { $set: null },
  });
};

export const createObservacaoFailure = (state, action) => {
  const { error } = action;
  return update(state, {
    loading: { $set: false },
    error: { $set: error },
  });
};

// Reducers to Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_OBSERVACAO_REQUEST]: createObservacaoRequest,
  [Types.CREATE_OBSERVACAO_SUCCESS]: createObservacaoSuccess,
  [Types.CREATE_OBSERVACAO_FAILURE]: createObservacaoFailure,
});
