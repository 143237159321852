import React, { useState } from "react";
import { FaChevronCircleRight, FaDownload } from "react-icons/fa";
import { withRouter } from "react-router-dom";

import Paginator, { Loading } from "@/components/Paginator";
import { date } from "@/formatters";

import { ListItem, ActionButton, DownloadButton, Label } from "./styles";

function ListarAvaliacoes({ history }) {
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [query] = useState({});
  const [paginator, setPaginator] = useState(null);
  const [loadingAvaliacoes, setLoadingAvaliacoes] = useState(false);

  function handleOnFetchComplete(data) {
    setAvaliacoes(data.data);
    setPaginator(data.paginator);
  }

  function renderAvaliacoes() {
    if (avaliacoes.length > 0) {
      return avaliacoes.map(a => {
        const { id, info, finalizada_em } = a;

        function renderActionButton() {
          if (finalizada_em === null) {
            return (
              <ActionButton
                onClick={() => {
                  history.push(`/avaliacao/${id}`);
                }}
              >
                <FaChevronCircleRight color="#271946" size={14} />{" "}
                <span>Responder</span>
              </ActionButton>
            );
          }

          const downloadUrl = `https://www.integrador.app/rest/files/avaliacao-pdf/${btoa(id)}`;

          return (
            <DownloadButton
              href={downloadUrl}
              target="_blank"
              download="avaliacao-estagio"
            >
              <FaDownload color="#271946" size={14} />{" "}
              <span>Fazer download</span>
            </DownloadButton>
          );
        }

        return (
          <ListItem key={id}>
            <div className="coluna coluna-nome">
              <h5>{info.vaga.titulo}</h5>
              <h4>{info.contratante.nome_exibicao}</h4>
            </div>
            <div className="coluna coluna-status">
              <h4>Status</h4>
              <h5>
                {finalizada_em !== null
                  ? `Respondida em ${date(finalizada_em)}`
                  : "Pendente"}
              </h5>
            </div>
            <div className="coluna coluna-actions">{renderActionButton()}</div>
          </ListItem>
        );
      });
    } else {
      return <Label>Ainda não existem avaliações disponíveis</Label>;
    }
  }

  return (
    <Loading loading={loadingAvaliacoes}>
      {paginator && renderAvaliacoes()}
      <Paginator
        endpoint="avaliacoes"
        query={query}
        onFetchComplete={handleOnFetchComplete}
        onLoading={l => setLoadingAvaliacoes(l)}
      />
    </Loading>
  );
}

export default withRouter(ListarAvaliacoes);
