import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { Modal } from "@/components/Common";
import { AceiteContainer, IconContainer, Message } from "./styles";
import { ModalContent } from "../Empresa/ModalEditAvatar/styles";

export default function AceiteTermos({ onChange }) {
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function toggle() {
    setChecked(!checked);
  }

  useEffect(() => {
    onChange(checked);
  }, [checked]);

  return (
    <>
      <AceiteContainer>
        <IconContainer onClick={toggle}>
          {checked && <FaCheck color="#888888" size={12} />}
        </IconContainer>
        <Message>
          Li, concordo e aceito os{" "}
          <span onClick={() => setShowModal(true)}>termos e políticas</span> do
          Integrador.app
        </Message>
      </AceiteContainer>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ModalContent>
          <h2>Termos e políticas</h2>
        </ModalContent>
      </Modal>
    </>
  );
}
