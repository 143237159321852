import styled from "styled-components";

export const SectionFormContainer = styled.div`
  padding-top: 85px;
  padding-bottom: 100px;
  width: 100%;
  max-width: 820px;
  margin: 0 auto;

  *.button-container:not(:last-of-type) {
    margin-right: 1rem;
  }
`;

export const Header = styled.div`
  margin: 65px 0;
  text-align: center;
  h3 {
    font-size: 32px;
    color: #000000;
  }
  p {
    font-size: 18px;
  }
`;

export const CardHeader = styled.div`
  margin-bottom: 28px;
  h4 {
    font-size: 26px;
    color: #DB4C81;
  }
  p {
    font-size: 18px;
  }
`;

export const SubheaderContainer = styled.div`
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 50px;
  h3 {
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: 400;
  }
`;
