import { call, put, select, all } from "redux-saga/effects";
import api, { makeOptions } from "@/services/api";
import toastify from "@/services/toastify";
import DeleteSupervisoresAction from "@/store/ducks/supervisores/delete";
import ListSupervisoresActions from "@/store/ducks/supervisores/list";

const getAuthData = state => state.auth.data;

export function* remove(action) {
  try {
    const { id } = action;
    const authData = yield select(getAuthData);
    const { data: retorno } = yield call(api.delete,
      `supervisores/${id}`, makeOptions(authData)
    );

    if (retorno.success) {
      yield all([
        put(DeleteSupervisoresAction.deleteSupervisorSuccess()),
        put(ListSupervisoresActions.listSupervisorRequest(authData.empresa.id)),
      ])
      // yield put(ListSupervisoresActions.listSupervisorRequest(empresa_id));
      toastify("Supervisor removido com sucesso");
    } else {
      yield put(DeleteSupervisoresAction.deleteSupervisorFailure(retorno.errors));
      // exibindo um toast para cada erro
      [...Object.keys(retorno.errors)].forEach(e => {
        toastify(retorno.errors[e][0], "error");
      });
    }
  } catch (e) {
    yield put(DeleteSupervisoresAction.deleteSupervisorFailure({
      api_failure: "Não foi possível se comunicar com a API"
    }));
  }
}
