import * as Yup from "yup";

const schemaStep1 = Yup.object().shape({
  documento: Yup.string().required("Obrigatório"),
  nome_exibicao: Yup.string().required("Obrigatório"),
  razao_social: Yup.string().required("Obrigatório"),
  nome_fantasia: Yup.string().required("Obrigatório"),
  cnae: Yup.string().required("Obrigatório"),
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  telefone: Yup.string().required("Obrigatório"),
  inscricao_estadual: Yup.string(),
  inscricao_municipal: Yup.string(),
  atuacao: Yup.string(),
  informacao_tce: Yup.string().required("Obrigatório"),
  apresentacao: Yup.string(),
});

const schemaStep2 = Yup.object().shape({
  endereco: Yup.object().shape({
    cep: Yup.string().required("Obrigatório"),
    logradouro: Yup.string().required("Obrigatório"),
    numero: Yup.string(),
    complemento: Yup.string(),
    bairro: Yup.string().required("Obrigatório"),
    cidade_id: Yup.string().required("Obrigatório"),
  }),
});

const schemaStep3 = Yup.object().shape({
  responsavel_legal_nome: Yup.string().required("Obrigatório"),
  responsavel_legal_cargo: Yup.string().required("Obrigatório"),
  responsavel_legal_telefone: Yup.string().required("Obrigatório"),
  responsavel_legal_email: Yup.string()
    .email("E-mail inválido")
    .required("Obrigatório"),
  usuario: Yup.object().shape({
    email: Yup.string()
      .email("E-mail inválido")
      .required("Obrigatório"),
    email_c: Yup.string()
      .email("E-mail inválido")
      .test("email-match", "Confirme seu email", function(value) {
        return this.parent.email === value;
      }),
    senha: Yup.string().required("Obrigatório"),
    senha_c: Yup.string()
      .required("Obrigatório")
      .test("senha-match", "Confirme sua senha", function(value) {
        return this.parent.senha === value;
      }),
  }),
});

export { schemaStep1, schemaStep2, schemaStep3 };
